/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import userPermissionContext from "./userPermissionContext";

const UserPagePermission = (props) => {

	const [loading, setLoading] = useState(false);
	const [pageList, setPageList] = useState([]);
	const [pagesLink, setPagesLink] = useState([]);

	useEffect(() => {
		getUserPages();
		getUserPagesLink();
		vtsToken();
	}, []);

	const getCookie = (name) => {
		const value = `; ${document.cookie}`;
		const parts = value.split(`; ${name}=`);
		if (parts.length === 2) return parts.pop().split(';').shift();
	}

	const getUserPages = () => {
		setLoading(true);
		const jwt = { token: getCookie("MyToken") };

		const requestOptions = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt.token },

		};
		fetch(process.env.REACT_APP_API_URL + "getUserPages", requestOptions)
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {
				setLoading(false);
				const menu = resp.data.map(function (row) {
					return { ...row, totalSubMenu: row.subMenu.length }
				});
				setPageList(menu);
			})
			.catch((error) => {
				console.log(error, "catch the hoop")
			});

	}

	const getUserPagesLink = () => {
		const jwt = { token: getCookie("MyToken") };
		const requestOptions = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt.token },

		};
		fetch(process.env.REACT_APP_API_URL + "getUserPagesLink", requestOptions)
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {
				setPagesLink(resp.data);
			})
			.catch((error) => {
				console.log(error, "catch the hoop")
			});

	}

	const vtsToken = () => {
		var body = {
			client_email: "rajib.khan@maxgroup-bd.com",
			client_password: "changeme"
		}
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt.token },
			body: JSON.stringify(body)
		};
		var apiEnd = "vtsToken";
		fetch(process.env.REACT_APP_API_URL + apiEnd, requestOptions)
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {
				if (resp.success === true) {
					localStorage.setItem('TrackerToken', resp.data);
				}
				else {
					localStorage.setItem('TrackerToken', "");
				}
			})
			.catch((error) => {
				console.log(error, "catch the hoop")
			});
	}

	const jwt = { token: getCookie("MyToken") };
	const trackerToken = { token: localStorage.getItem('TrackerToken') };

	return (

		<userPermissionContext.Provider value={{ pageList, loading, pagesLink, jwt, trackerToken }}>
			{props.children}
		</userPermissionContext.Provider>
	)
}
export default UserPagePermission