import { useState, useContext, useEffect } from "react";
import userPermissionContext from '../../context/userPermissionContext';

function GetPagePermission(value) {
    const { pagesLink } = useContext(userPermissionContext);
    const [hasPermission, setHasPermission] = useState(0);
    useEffect(() => {
        const permission = pagesLink.filter(function (el) {
            return (el.pagesLink === value);
        });

        if (permission.length > 0) {
            setHasPermission(1)
        }
        else {
            setHasPermission(0)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagesLink]);
    return hasPermission;
}

export { GetPagePermission };