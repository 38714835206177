/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react';
import { GoogleMap, DirectionsRenderer, LoadScript } from '@react-google-maps/api';
import userPermissionContext from '../../context/userPermissionContext';

function VehicleMap() {

	const { jwt } = useContext(userPermissionContext);
	const [directions, setDirections] = useState(null);
	const [waypoints, setWayPoints] = useState([]);
	const center = { lat: 22.23649, lng: 91.80185 };

	useEffect(() => {
		getWayPoints();
	}, [])

	const getWayPoints = () => {
		var body = {
			device_name: "DM_THA_14-2701",
			from_date: "2023-05-16",
			to_date: "2023-05-16",
			from_time: "13:00",
			to_time: "22:00",
			tracker_token: localStorage.getItem('TrackerToken')
		}
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt.token },
			body: JSON.stringify(body)
		};
		var apiEnd = "wayPoints";
		fetch(process.env.REACT_APP_API_URL + apiEnd, requestOptions)
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {
				if (resp.success === true) {
					setWayPoints(resp.data);
				}
				else {
					console.log(resp.errorMessage);
				}
			})
			.catch((error) => {
				console.log(error, "catch the hoop")
			});
	}

	const splitWaypoints = (waypointsArray, chunkSize) => {
		const chunks = [];
		for (let i = 0; i < waypointsArray.length; i += chunkSize) {
			chunks.push(waypointsArray.slice(i, i + chunkSize));
		}
		return chunks;
	};

	const fetchDirections = async (origin, destination, waypoints) => {
		const directionsService = new window.google.maps.DirectionsService();
		const waypointsArray = waypoints.map(waypoint => ({
			location: new window.google.maps.LatLng(waypoint.lat, waypoint.lng),
		}));
		const request = {
			origin: origin,
			destination: destination,
			waypoints: waypointsArray,
			optimizeWaypoints: true,
			travelMode: 'DRIVING',
		};
		return new Promise((resolve, reject) => {
			directionsService.route(request, (result, status) => {
				if (status === 'OK') {
					resolve(result);
				} else {
					reject(status);
				}
			});
		});
	};


	const renderDirections = () => {
		const waypointChunks = splitWaypoints(waypoints, 25);

		const fetchDirectionsForChunks = async () => {

			const directionsPromises = waypointChunks.map(chunk =>
				fetchDirections(center, center, chunk)
			);

			try {
				const directionsResults = await Promise.all(directionsPromises);
				const mergedDirections = directionsResults.reduce((merged, result) => {
					merged.routes[0].legs.push(...result.routes[0].legs);
					return merged;
				});
				setDirections(mergedDirections);
			} catch (error) {
				console.log('Error fetching directions:', error);
			}
		};

		fetchDirectionsForChunks();
	}

	useEffect(() => {
		if (waypoints.length > 2) {
			renderDirections();
		}
	}, [waypoints]); // Only run once on component mount

	return (
		<LoadScript googleMapsApiKey={process.env.REACT_APP_MAP_API_KEY}>
			<GoogleMap
				zoom={10}
				mapContainerStyle={{ height: "800px", width: "100%" }}
				center={center}
			>
				{directions && <DirectionsRenderer directions={directions} options={{ suppressMarkers: true }} />}
			</GoogleMap>
		</LoadScript>
	);
};

export default VehicleMap;