/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useCallback, useRef, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { KTSVG } from '../../_metronic/helpers';
import Swal from 'sweetalert2';
import { useHistory } from "react-router-dom";
import VehicleInfo from "../components/VehicleInfo";
import VehicleDetails from "../components/VehicleDetails";
import VehicleInsurance from "../components/VehicleInsurance";
import VehicleAssign from "../components/VehicleAssign";
import { Tab, Tabs } from "react-bootstrap-v5";
import { GetPagePermission } from "../components/UserPagePermission";
import { getRequestOptions } from "../components/GetToken";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import userPermissionContext from '../../context/userPermissionContext';

function EditVehicle() {
    const { jwt } = useContext(userPermissionContext);
    const { id } = useParams();

    const vehicleInfoRef = useRef();
    const vehicleInsuranceRef = useRef();
    const vehicleDetailsRef = useRef();
    const vehicleAssignRef = useRef();

    let history = useHistory();
    const [vehicleInfo, setVehicleInfo] = useState({});
    const [vehicleDetails, setVehicleDetails] = useState({});
    const [vehicleInsurance, setVehicleInsurance] = useState({});
    const [vehicleAssign, setVehicleAssign] = useState({});
    const [idVmsVehicleInfo, setIdVmsVehicleInfo] = useState("");
    const [vehicleInfoEditData, setVehicleInfoEditData] = useState({});
    const [vehicleDetailsEditData, setVehicleDetailsEditData] = useState({});
    const [vehicleInsuranceEditData, setVehicleInsuranceEditData] = useState({});
    const [vehicleAssignEditData, setVehicleAssignEditData] = useState({});


    const onVehicleInfoChange = useCallback((vehicleInfo) => {
        setVehicleInfo(vehicleInfo);
    }, [])

    const onVehicleDetailsChange = useCallback((vehicleDetails) => {
        setVehicleDetails(vehicleDetails);
    }, [])

    const onVehicleInsuranceChange = useCallback((vehicleInsurance) => {
        setVehicleInsurance(vehicleInsurance);
    }, [])

    const onVehicleAssignChange = useCallback((vehicleAssign) => {
        setVehicleAssign(vehicleAssign);
    }, [])

    const hasPermission = GetPagePermission("/vehicleAdd");

    useEffect(() => {
        if (id !== undefined) {
            setIdVmsVehicleInfo(id);
        }
    }, [id]);

    useEffect(() => {

        const getVehicleDetails = () => {
            fetch(process.env.REACT_APP_API_URL + "vehicleEditInfo/" + idVmsVehicleInfo, getRequestOptions())
                .then((resp) => {
                    return resp.json()
                })
                .then((resp) => {
                    setVehicleInfoEditData({
                        vehicleOwner: resp.data.vehicleOwner !== null ? resp.data.vehicleOwner : '',
                        vehicleType: resp.data.vehicle_type !== null ? resp.data.vehicle_type : '',
                        carUseType: resp.data.carUseType !== null ? resp.data.carUseType : '',
                        vendor: {
                            idVendors: resp.data.idVendors,
                            vendorName: resp.data.vendorName,
                            vendorAddress: resp.data.idVendors,
                            phoneNumber: resp.data.idVendors,
                            label: resp.data.vendorName,
                            value: resp.data.idVendors
                        },
                        idCategories: resp.data.idCategories !== null ? resp.data.idCategories : '',
                        idColor: resp.data.idColor !== null ? resp.data.idColor : '',
                        idProducts: resp.data.idProducts !== null ? resp.data.idProducts : '',
                        idModels: resp.data.idModels !== null ? resp.data.idModels : '',
                        capDetails: {
                            capitalizationId: resp.data.capitalizationId,
                            docNo: resp.data.docNo,
                            capDetailsId: resp.data.capDetailsId,
                            code: resp.data.code,
                            fixedAssetProject: resp.data.fixedAssetProject,
                            quantity: resp.data.quantity,
                            idCategories: resp.data.idCategories,
                            itemName: resp.data.itemName,
                            itemCode: resp.data.itemCode,
                            itemId: resp.data.itemId,
                            idColor: resp.data.idColor,
                            idProducts: resp.data.idProducts,
                            idModels: resp.data.idModels,
                            modelName: resp.data.modelName,
                            productsName: resp.data.productsName,
                            colorName: resp.data.colorName,
                            colorLabel: resp.data.colorName,
                            colorValue: resp.data.idColor,
                            brandLabel: resp.data.productsName,
                            brandValue: resp.data.idProducts,
                            modelLabel: resp.data.modelName,
                            modelValue: resp.data.idModels,
                            label: resp.data.code,
                            value: resp.data.capDetailsId
                        },
                        categories: {
                            idCategories: resp.data.idCategories,
                            categoryName: resp.data.categoryName,
                            label: resp.data.categoryName,
                            value: resp.data.idCategories
                        },
                        color: {
                            idColor: resp.data.idColor,
                            colorName: resp.data.colorName,
                            label: resp.data.colorName,
                            value: resp.data.idColor
                        },
                        brand: {
                            idProducts: resp.data.idProducts,
                            productsName: resp.data.productsName,
                            label: resp.data.productsName,
                            value: resp.data.idProducts
                        },
                        model: {
                            idModels: resp.data.idModels,
                            modelName: resp.data.modelName,
                            label: resp.data.modelName,
                            value: resp.data.idModels
                        },
                        fixedAmount: resp.data.fixedAmount !== null ? resp.data.fixedAmount : '',
                        lastPolicyReviewOn: resp.data.lastPolicyReviewOn !== null ? resp.data.lastPolicyReviewOn : '',
                        milage: resp.data.milage !== null ? resp.data.milage : '',
                        monthlyExpense: resp.data.monthlyExpense !== null ? resp.data.monthlyExpense : '',
                        lastReviewDate: resp.data.lastReviewDate !== null ? resp.data.lastReviewDate : '',
                        vehicleCity: resp.data.vehicleNumber !== null ? resp.data.vehicleNumber.split("-")[0] : '',
                        vehicleClass: resp.data.vehicleNumber !== null ? resp.data.vehicleNumber.split("-")[1] : '',
                        vehicleRegistrationNumber: resp.data.vehicleNumber !== null ? resp.data.vehicleNumber.split("-")[2] + '-' + resp.data.vehicleNumber.split("-")[3] : '',
                        vehicleNumber: resp.data.vehicleNumber !== null ? resp.data.vehicleNumber : '',
                        manufacturer: resp.data.manufacturer !== null ? resp.data.manufacturer : '',
                        yearOfManufacture: resp.data.yearOfManufacture !== null ? resp.data.yearOfManufacture : '',
                        chasisNumber: resp.data.chasisNumber !== null ? resp.data.chasisNumber : '',
                        vtsNo: resp.data.vtsNo !== null ? resp.data.vtsNo : '',
                        eTrackingNumber: resp.data.eTrackingNumber !== null ? resp.data.eTrackingNumber : '',
                        overHang: resp.data.overHang !== null ? resp.data.overHang : '',
                        registrationDate: resp.data.registrationDate !== null ? resp.data.registrationDate : '',
                        transactionNumber: resp.data.transactionNumber !== null ? resp.data.transactionNumber : '',
                        nameOfRegistration: resp.data.nameOfRegistration !== null ? resp.data.nameOfRegistration : '',
                        currentMeterReading: resp.data.currentMeterReading !== null ? resp.data.currentMeterReading : '',
                        taxAmount: resp.data.taxAmount !== null ? resp.data.taxAmount : '',
                        taxTokenExpirationDate: resp.data.taxTokenExpirationDate !== null ? resp.data.taxTokenExpirationDate : '',
                        fitnessExpirationDate: resp.data.fitnessExpirationDate !== null ? resp.data.fitnessExpirationDate : '',
                        routePermitExpirationDate: resp.data.routePermitExpirationDate !== null ? resp.data.routePermitExpirationDate : '',
                        vehicleStatus: resp.data.vehicleStatus !== null ? resp.data.vehicleStatus : '',
                        usageType: resp.data.usageType !== null ? resp.data.usageType : '',
                        functional: resp.data.functionalStatus !== null ? resp.data.functionalStatus : '',
                    });
                    setVehicleDetailsEditData(resp.data.vehicle_details);
                    setVehicleInsuranceEditData(resp.data.vehicle_insurance);
                    setVehicleAssignEditData({
                        project: {
                            projectId: resp.data.projectId,
                            projectName: resp.data.projectName,
                            companyId: resp.data.companyId,
                            projectCode: resp.data.projectCode,
                            label: resp.data.projectName,
                            value: resp.data.projectId
                        },
                        driver: {
                            idVmsDrivers: resp.data.idVmsDrivers,
                            fullName: resp.data.driverFullName,
                            mobileNumber: resp.data.mobileNumber,
                            label: resp.data.driverFullName,
                            value: resp.data.idVmsDrivers
                        },
                        assignedUser: {
                            employeeId: resp.data.employeeId,
                            fullName: resp.data.assignedUserName,
                            designation: resp.data.designation,
                            employeeCustomId: resp.data.employeeCustomId,
                            label: resp.data.assignedUserName,
                            value: resp.data.employeeId
                        },
                        payrollUser: resp.data.assignedUserName !== null ? 'yes' : 'no',
                        consultantOrAdvisorName: resp.data.consultantOrAdvisorName !== null ? resp.data.consultantOrAdvisorName : '',
                    });
                })
                .catch((error) => {
                    console.log(error, "catch the hoop")
                });
        }
        if (idVmsVehicleInfo !== '') {
            getVehicleDetails();
        }
    }, [idVmsVehicleInfo]);


    const handleSubmit = evt => {
        evt.preventDefault();
        Swal.fire({
            title: 'Are you sure?',
            text: 'You want to update this Vehicle!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {
                const formData = {
                    idVmsVehicleInfo: idVmsVehicleInfo,
                    vehicleInfo: vehicleInfo,
                    vehicleDetails: vehicleDetails,
                    vehicleInsurance: vehicleInsurance,
                    vehicleAssign: vehicleAssign
                };
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt.token },
                    body: JSON.stringify(formData)
                };

                fetch(process.env.REACT_APP_API_URL + "vehicleUpdate", requestOptions)
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((resp) => {

                        if (resp.success === true) {

                            var successMsg = [`Vehicle Number '${resp.data.vehicleNumber}' has been updated`];

                            Swal.fire({
                                icon: 'success',
                                title: 'Success',
                                text: successMsg,
                            })
                            vehicleInfoRef.current.setInitialState();
                            vehicleInsuranceRef.current.setInitialState();
                            vehicleDetailsRef.current.setInitialState();
                            vehicleAssignRef.current.setInitialState();
                            history.push("/vehicleDetails/" + resp.data.id);
                        }
                        else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Error',
                                html: resp.errorMessage
                            })
                        }
                    })
                    .catch((error) => {
                        console.log(error, "catch the hoop")
                    });

            }
        })
    }


    return (
        <>
            {hasPermission === 1 &&

                <div className="card card-custom">
                    <div className="card-header">
                        <div className="card-title">
                            <KTSVG
                                path='/media/svg/icons/Devices/CPU1.svg'
                                className='svg-icon-1 svg-icon-primary'
                            />
                            <span className="text fw-bolder" style={{ marginLeft: "10px" }}>
                                UPDATE VEHICLE INFORMATION
                            </span>
                        </div>
                    </div>

                    <div className="ml-12">
                        <form onSubmit={handleSubmit}>
                            <div className="card-body">
                                <Tabs defaultActiveKey="vehicleInfo" id="uncontrolled-tab-example" className="mb-3">
                                    <Tab eventKey="vehicleInfo" title="Vehicle Info">
                                        <VehicleInfo onVehicleInfoChange={onVehicleInfoChange} ref={vehicleInfoRef} vehicleInfoEditData={vehicleInfoEditData} idVmsVehicleInfo={idVmsVehicleInfo} />
                                    </Tab>
                                    <Tab eventKey="vehicleDetails" title="Vehicle Details">
                                        <VehicleDetails onVehicleDetailsChange={onVehicleDetailsChange} ref={vehicleDetailsRef} vehicleDetailsEditData={vehicleDetailsEditData} />
                                    </Tab>
                                    <Tab eventKey="vehicleInsurance" title="Vehicle Insurance" >
                                        <VehicleInsurance onVehicleInsuranceChange={onVehicleInsuranceChange} ref={vehicleInsuranceRef} vehicleInsuranceEditData={vehicleInsuranceEditData} />
                                    </Tab>
                                    <Tab eventKey="vehicleAssign" title="Vehicle Assign" >
                                        <VehicleAssign onVehicleAssignChange={onVehicleAssignChange} ref={vehicleAssignRef} vehicleAssignEditData={vehicleAssignEditData} />
                                    </Tab>
                                </Tabs>
                                <div className="row mt-5">
                                    <div className="col-5">
                                    </div>
                                    <div className="col-6">
                                        <input type="submit" className="btn btn-sm btn-success me-2" value={'Update'} />
                                        <button type="reset" className="btn btn-sm btn-secondary" onClick={() => history.goBack()}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                            {/* <pre>
                                {JSON.stringify(vehicleInfo, null, 2)}
                                {JSON.stringify(vehicleDetails, null, 2)}
                                {JSON.stringify(vehicleInsurance, null, 2)}
                                {JSON.stringify(vehicleAssign, null, 2)}
                            </pre> */}
                            {/* <pre> */}
                            {/* {JSON.stringify(idVmsVehicleInfo, null, 2)} */}
                            {/* {JSON.stringify(details, null, 2)} */}
                            {/* {JSON.stringify(vehicleInsuranceEditData, null,2)}
                                {JSON.stringify(vehicleDetailsEditData, null,2)}
                                {JSON.stringify(vehicleAssignEditData, null,2)} */}
                            {/* {JSON.stringify(vehicleInfoEditData, null,2)} */}
                            {/* </pre> */}
                        </form>
                    </div>
                    <ToastContainer />
                </div>
            }
            {hasPermission === 0 &&
                <div className='card card-custom' style={{ padding: "15px" }} >
                    <SkeletonTheme>
                        <p>
                            <Skeleton
                                containerClassName="avatar-skeleton"
                                count={40} ></Skeleton>
                        </p>
                    </SkeletonTheme>
                </div>
            }
        </>
    )
}

export default EditVehicle