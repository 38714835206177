import { useState, useEffect, useContext } from "react";
import { getRequestOptions } from "../components/GetToken";
import AsyncSelect from 'react-select/async';
import { KTSVG } from "../../_metronic/helpers";
import Swal from 'sweetalert2';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import userPermissionContext from '../../context/userPermissionContext';

const customStylesSelect = {
	control: (provided) => ({
		...provided,
		height: 'calc(1.35em + 1.1rem + 2px)',
		minHeight: '35px'
	}),
	valueContainer: (provided) => ({
		...provided,
	})
};


function DriverAssign(props) {
	const [vehicleNumber, setVehicleNumber] = useState("");
	const [currentDriver, setCurrentDriver] = useState("");
	const [currentDriverName, setCurrentDriverName] = useState("");
	const [newDriver, setNewDriver] = useState("");
	const [assignDate, setAssignDate] = useState("");
	const { jwt } = useContext(userPermissionContext);

	useEffect(() => {
		if (props.idVmsVehicleInfo !== "") {
			fetch(
				process.env.REACT_APP_API_URL + "getVehicleInfoForDriverAssign/" + props.idVmsVehicleInfo,
				getRequestOptions()
			)
				.then((response) => response.json())
				.then((data) => {
					setVehicleNumber(data.data.vehicleNumber);
					setCurrentDriver(data.data.currentDriver);
					setCurrentDriverName(data.data.currentDriverName);
				})
				.catch((error) => {
					console.log(error);
				});
		}
	}, [props.idVmsVehicleInfo]);

	const getAllDriver = (inputValue, callback) => {

		if (!inputValue) {
			callback([]);

		}
		else {
			var url = process.env.REACT_APP_API_URL + "getAllDriver?q=" + inputValue;

			setTimeout(() => {
				fetch(url,
					getRequestOptions())

					.then((resp) => {
						return resp.json()
					})
					.then((resp) => {
						const tempArray = resp.data.map(function (element) {
							element.label = `${element.fullName} (${element.mobileNumber})`;
							element.value = element.idVmsDrivers;
							return element;
						}).filter(e => e.idVmsDrivers !== currentDriver);
						callback(tempArray);
					})
					.catch((error) => {
						console.log(error, "catch the hoop")
					});
			});
		}
	}

	const handleDriverChange = driver => {
		setNewDriver(driver);
	}

	const handleAssignDateChange = evt => {
		const date = new Date().toISOString().slice(0, 10);
		if (date < evt.target.value) {
			toast.error("Assign Date cannot be greater than current date");
		}
		else {
			setAssignDate(evt.target.value);
		}
	}

	const handleSubmit = evt => {
		evt.preventDefault();
		Swal.fire({
			title: 'Are you sure?',
			text: "You want to assign this driver!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes',
			cancelButtonText: 'No'
		}).then((result) => {
			if (result.value) {
				const formData = {
					idVmsVehicleInfo: props.idVmsVehicleInfo,
					currentDriver: currentDriver,
					newDriver: newDriver,
					assignDate: assignDate
				};
				const requestOptions = {
					method: 'POST',
					headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt.token },
					body: JSON.stringify(formData)
				};

				var apiEnd = "assignDriverToVehicle";

				fetch(process.env.REACT_APP_API_URL + apiEnd, requestOptions)
					.then((resp) => {
						return resp.json()
					})
					.then((resp) => {
						if (resp.success === true) {
							let successMsg = ["Driver has been assigned"];

							Swal.fire({
								icon: 'success',
								title: 'Success',
								text: successMsg,
							})
							props.onAssignDriverConfirm();
						}
						else {
							Swal.fire({
								icon: 'error',
								title: 'Error',
								html: resp.errorMessage
							})
						}
					})
					.catch((error) => {
						console.log(error, "catch the hoop")
					});

			}
		})
	}

	return (
		<>
			<div className="ml-12">
				<form onSubmit={handleSubmit}>
					<div className="form-group row">
						<label htmlFor="vehicleNumber" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }}>Vehicle Number </label>
						<div className="col-lg-5">
							<input type="text" name="vehicleNumber" id="vehicleNumber" value={vehicleNumber} className="form-control form-control-sm" readOnly />
						</div>
					</div>
					<div className="form-group row">
						<label htmlFor="currentDriverName" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }}>Current Driver </label>
						<div className="col-lg-5">
							<input type="text" name="currentDriverName" id="currentDriverName" value={currentDriverName} className="form-control form-control-sm" readOnly />
						</div>
					</div>
					<div className="form-group row">
						<label htmlFor="example-text-input" style={{ "textAlign": "right" }} className="col-lg-4 col-form-label" >New Driver<span className="required text-danger"></span></label>
						<div className="col-lg-5">
							<AsyncSelect
								value={newDriver}
								loadOptions={getAllDriver}
								placeholder="Select Driver"
								onChange={handleDriverChange}
								styles={customStylesSelect}
							/>
						</div>
					</div>
					<div className="form-group row">
						<label htmlFor="assignDate" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }} >Assign Date <span className="required text-danger"></span></label>
						<div className="col-lg-5">
							<input type="date" name="assignDate" id="assignDate" value={assignDate} onChange={handleAssignDateChange} className="form-control form-control-sm" />
						</div>
					</div>
					<div className="row MT-5">
						<div className="col-5">
						</div>
						<div className="col-4">
							<button type="submit" className="btn btn-light-success btn-sm">
								<span>
									<KTSVG
										path='/media/svg/icons/Navigation/Check.svg'
										className='svg-icon-1 svg-icon-primary'
									/>
								</span>
								Assign
							</button>
						</div>
					</div>
				</form>
			</div>
			<ToastContainer />
		</>
	)

}
export default DriverAssign;