import React, { useEffect, useState, useContext } from "react";
import { GetPagePermission } from "../../components/UserPagePermission";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import userPermissionContext from '../../../context/userPermissionContext';
import { KTSVG } from '../../../_metronic/helpers';
import Select from 'react-select';
import { getRequestOptions } from "../../components/GetToken";
import BackendTable from 'react-backend-table';

function VehicleCostReport() {
    const { jwt } = useContext(userPermissionContext);
    const hasPermission = GetPagePermission('/report-vehicleCostReport');
    const [projectList, setProjectList] = useState([]);
    const [formData, setFormData] = useState({
        project: "",
        month: "",
        year: ""
    });
    const [yearList, setYearList] = useState([]);

    useEffect(() => {
        getProjectList();
        getYearList();
    }, []);

    const getProjectList = () => {
        fetch(process.env.REACT_APP_API_URL + "getProjectList", getRequestOptions())
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                setProjectList(resp.data.map((item) => ({ label: item.label, value: item.projectId })));
            })
            .catch((error) => {
                console.log(error, "catch the hoop")
            });
    }

    const handleProjectChange = (selectedOption) => {
        setFormData({ ...formData, project: selectedOption });
    }

    const handleMonthChange = (selectedOption) => {
        setFormData({ ...formData, month: selectedOption });
    }

    const handleYearChange = (selectedOption) => {
        setFormData({ ...formData, year: selectedOption });
    }

    const getYearList = () => {
        let yearList = [];
        let currentYear = new Date().getFullYear();
        for (let i = 0; i < 5; i++) {
            yearList.push({ label: currentYear - i, value: currentYear - i });
        }
        setYearList(yearList);
    }

    const monthList = [
        { label: "January", value: "1" },
        { label: "February", value: "2" },
        { label: "March", value: "3" },
        { label: "April", value: "4" },
        { label: "May", value: "5" },
        { label: "June", value: "6" },
        { label: "July", value: "7" },
        { label: "August", value: "8" },
        { label: "September", value: "9" },
        { label: "October", value: "10" },
        { label: "November", value: "11" },
        { label: "December", value: "12" }
    ]

    let columns = [
        { title: 'Vehicle Id', field: 'idVmsVehicleInfo', sortable: true, searchable: true },
        { title: 'Vehicle Number', field: 'vehicleNumber', sortable: true, searchable: true },
        { title: 'Vehicle Type', field: 'vehicleType', sortable: true, searchable: true },
        { title: 'Name of Registration', field: 'nameOfRegistration', sortable: true, searchable: true },
        { title: 'Vehicle Owner', field: 'vehicleOwner', sortable: true, searchable: true },
        { title: 'Vehicle Color', field: 'colorName', sortable: true, searchable: true },
        { title: 'Year of Manufacture', field: 'yearOfManufacture', sortable: true, searchable: true },
        { title: 'CC of Vehicle', field: 'engineCCDisplacement', sortable: true, searchable: true },
        { title: 'Seat Capacity', field: 'seatCapacity', sortable: true, searchable: true },
        { title: 'Business Unit', field: 'projectName', sortable: true, searchable: true },
        { title: 'User', field: 'userName', sortable: true, searchable: true },
        { title: 'Designation', field: 'designation', sortable: true, searchable: true },
        { title: 'Car Use Type', field: 'carUseType', sortable: true, searchable: true },
        { title: 'VTS No', field: 'vtsNo', sortable: true, searchable: true },
        { title: 'Usage Type', field: 'usageType', sortable: true, searchable: true },
        { title: 'Maintenance Cost', field: 'totalMaintenanceCost', sortable: true, searchable: true },
        { title: 'Miscellaneous Cost', field: 'totalMiscellaneousCost', sortable: true, searchable: true },
        { title: 'Documentation Cost', field: 'totalDocumentationCost', sortable: true, searchable: true },
        { title: 'Fuel Cost', field: 'totalFuelCost', sortable: true, searchable: true },
        { title: 'Rental Cost', field: 'totalRentalCost', sortable: true, searchable: true },
        { title: 'Total Cost', field: 'totalCost', sortable: true, searchable: true },
        { title: 'Driver Name', field: 'driverName', sortable: true, searchable: true },
        { title: 'Driver Mobile', field: 'driverMobileNo', sortable: true, searchable: true },
    ]

    let options = {
        url: process.env.REACT_APP_API_URL + 'vehicleCostReport',
        authorization: 'Bearer ' + jwt.token,
        perPage: [50, 100, 500, 1000, 'All'],
        orderBy: 'idVmsVehicleInfo',
        orderType: 'desc',
        columnSearch: true,
        extraData: formData
    }

    useEffect(() => {
        if (formData.project !== '' && formData.month !== '' && formData.year !== '') {
            options.reloadMyTable();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData.project, formData.month, formData.year]);

    return (
        <>
            {hasPermission === 1 &&
                <div className="card card-custom">
                    <div className="card-header">
                        <div className="card-title">
                            <KTSVG
                                path='/media/svg/icons/Shopping/Money.svg'
                                className='svg-icon-1 svg-icon-primary'
                            />
                            <span className="text fw-bolder" style={{ marginLeft: "10px" }}>
                                VEHICLE COST REPORT
                            </span>
                        </div>
                    </div>

                    <div className="ml-12">
                        <div className="card-body">
                            <div className="form-group row mt-5">
                                <label htmlFor="project" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }} >Project<span className="required text-danger"></span></label>
                                <div className="col-lg-4">
                                    <Select
                                        value={formData.project}
                                        options={projectList}
                                        onChange={handleProjectChange}
                                        isClearable
                                        menuPosition="fixed"
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="month" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }} >Month<span className="required text-danger"></span></label>
                                <div className="col-lg-4">
                                    <Select
                                        value={formData.month}
                                        options={monthList}
                                        onChange={handleMonthChange}
                                        isClearable
                                        menuPosition="fixed"

                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="year" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }} >Year<span className="required text-danger"></span></label>
                                <div className="col-lg-4">
                                    <Select
                                        value={formData.year}
                                        options={yearList}
                                        onChange={handleYearChange}
                                        isClearable
                                        menuPosition="fixed"
                                    />
                                </div>
                            </div>
                            <BackendTable columns={columns} options={options} />
                        </div>

                    </div>
                </div>
            }
            {
                hasPermission === 0 &&
                <div className='card card-custom' style={{ padding: "15px" }} >
                    <SkeletonTheme>
                        <p>
                            <Skeleton
                                containerClassName="avatar-skeleton"
                                count={40} ></Skeleton>
                        </p>
                    </SkeletonTheme>
                </div>
            }

        </>
    )
}

export default VehicleCostReport;