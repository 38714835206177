import BackendTable from 'react-backend-table';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { KTSVG } from '../../_metronic/helpers';
import { GetPagePermission } from "../components/UserPagePermission";
import userPermissionContext from '../../context/userPermissionContext';
import { useContext } from 'react';

function DriverList() {
	const { jwt } = useContext(userPermissionContext);
	const hasPermission = GetPagePermission("/driverList");
	let columns = [
		{ title: 'Id', field: 'idVmsDrivers', sortable: true, searchable: true, thStyle: { textAlign: "center" }, tdStyle: { textAlign: "center" } },
		{ title: 'Driver Name', field: 'fullName', sortable: true, searchable: true },
		{ title: 'Employee ID', field: 'employeeId', sortable: true, searchable: true },
		{ title: 'Driver Type', field: 'driverCategory', sortable: true, searchable: true },
		{ title: 'Mobile Number', field: 'mobileNumber', searchable: true },
		{ title: 'Phone Number', field: 'phoneNumber', searchable: true },
		{ title: 'National Id', field: 'driverNid', searchable: true },
		{ title: 'Job Title', field: 'jobTitle', sortable: true, searchable: true },
		{ title: 'Company', field: 'companyName', sortable: true, searchable: true },
		{ title: 'Project', field: 'projectName', sortable: true, searchable: true },
	]

	let options = {
		url: process.env.REACT_APP_API_URL + 'driverList',
		authorization: 'Bearer ' + jwt.token,
		perPage: [50, 100, 500, 1000, 'All'],
		orderBy: 'idVmsDrivers',
		orderType: 'desc',
		columnSearch: true,
	}

	return (
		<>
			{hasPermission === 1 &&
				<div className='card card-custom'>
					<div className='card-header'>
						<div className='card-title'>
							<span>
								<KTSVG
									path='/media/svg/icons/Text/Bullet-list.svg'
									className='svg-icon-1 svg-icon-primary'
								/>
							</span>
							<span className='text fw-bolder' style={{ marginLeft: '10px' }}>
								DRIVERS LIST
							</span>
						</div>
					</div>

					<div className='ml-12'>
						<BackendTable columns={columns} options={options} />
					</div>
				</div>
			}
			{hasPermission === 0 &&
				<div className='card card-custom' style={{ padding: "15px" }} >
					<SkeletonTheme>
						<p>
							<Skeleton
								containerClassName="avatar-skeleton"
								count={40} ></Skeleton>
						</p>
					</SkeletonTheme>
				</div>
			}
		</>
	)
}

export default DriverList
