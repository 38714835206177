import React, { useContext } from 'react'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import userPermissionContext from '../../../../context/userPermissionContext'

export function AsideMenuMain() {

	const userPermission = useContext(userPermissionContext);

	return (
		<>
			<AsideMenuItem
				to='/dashboard'
				icon='/media/icons/duotune/abstract/abs014.svg'
				title='Dashboard'
			/>
			<AsideMenuItem
				to='/to-do-list'
				icon='/media/svg/icons/General/Clipboard.svg'
				title='To Do List'
			/>
			{userPermission.loading &&
				<>
					<AsideMenuItem to="" title=""><Skeleton count={1} height={15} ></Skeleton></AsideMenuItem>

					<div style={{ paddingLeft: "15px" }}>
						<Skeleton count={3} ></Skeleton>
					</div>
					<AsideMenuItem to="" title=""><Skeleton count={1} height={15} ></Skeleton></AsideMenuItem>
					<div style={{ paddingLeft: "15px" }}>
						<Skeleton count={2} ></Skeleton>
					</div>
					<AsideMenuItem to="" title=""><Skeleton count={1} height={15} ></Skeleton></AsideMenuItem>
					<div style={{ paddingLeft: "15px" }}>
						<Skeleton count={4} ></Skeleton>
					</div>
					<AsideMenuItem to="" title=""><Skeleton count={1} height={15} ></Skeleton></AsideMenuItem>
					<div style={{ paddingLeft: "15px" }}>
						<Skeleton count={6} ></Skeleton>
					</div>
				</>
			}

			{userPermission.pageList.length > 0 && userPermission.pageList.map((item: any, index: number) => (
				item.totalSubMenu > 0 ?

					<AsideMenuItemWithSub to={`/${item.pagesLink}`} icon={item.iconPath} title={item.pagesTitle} key={index}>
						{item.subMenu.map((subItem: any, subIndex: number) => (
							<AsideMenuItem to={`/${subItem.pagesLink}`} title={subItem.pagesTitle} icon={subItem.iconPath} key={subIndex} />
						))}
					</AsideMenuItemWithSub>
					:
					<AsideMenuItem to={`/${item.pagesLink}`} icon={item.iconPath} title={item.pagesTitle} key={index} ></AsideMenuItem>

			))}

		</>

	)
}
