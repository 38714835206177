/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getRequestOptions } from "../components/GetToken";
import { KTSVG } from '../../_metronic/helpers';

function VehicleReleaseDetails() {
	const { id } = useParams();
	const [releaseDetailsData, setReleaseDetailsData] = useState([]);
	const [releaseData, setReleaseData] = useState([]);


	useEffect(() => {
		const getVehicleReleaseDetails = () => {
			fetch(process.env.REACT_APP_API_URL + "getVehicleReleaseDetails/" + id, getRequestOptions())
				.then((resp) => {
					return resp.json()
				})
				.then((resp) => {
					setReleaseDetailsData(resp.data.vehicleReleaseDetailsData);
					setReleaseData(resp.data.vehicleReleaseData);
				})
				.catch((error) => {
					console.log(error, "catch the hoop")
				});
		}
		getVehicleReleaseDetails();

	}, [id]);

	return (

		<div className="card card-custom">
			<div className="card-header">
				<div className="card-title">
					<KTSVG
						path='/media/svg/icons/Code/Info-circle.svg'
						className='svg-icon-1 svg-icon-primary'
					/>
					<span className="text fw-bolder" style={{ marginLeft: "10px" }}>
						VEHICLE RELEASE ORDER DETAILS
					</span>
				</div>
			</div>
			<div className="card-body">
				<ul className="nav nav-tabs nav-tabs-line">
					<li className="nav-item">
						<a className={`nav-link && "active"}`} data-toggle="tab" role="tab">VEHICLE RELEASE DETAILS</a>
					</li>
				</ul>
				<div className="row">
					<div className="col-md-6 mt-5">
						<table className="table table-sm table-hover table-bordered">
							<tbody>
								<tr>
									<th>Vehicle Release Order Number</th>
									<td>{releaseData.vroNo}</td>
								</tr>
								<tr>
									<th>Releasing Business Unit</th>
									<td>{releaseData.releasingBusinessUnit}</td>
								</tr>
								<tr>
									<th>Remarks</th>
									<td>{releaseData.remarks}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div className="row mt-5">
					<div className="tableFixHead">
						<div className="table-responsive" style={{ maxHeight: "400px", overflow: "auto" }}>
							<table className="table table-striped table-bordered">
								<thead>
									<tr className="text-primary">
										<th>SL</th>
										<th>Vehicle Number</th>
										<th>VMO Date</th>
										<th>Receive Date</th>
										<th>Release Date</th>
										<th>Remarks</th>
									</tr>
								</thead>
								<tbody>
									{
										releaseDetailsData && releaseDetailsData.map((releaseDetails, index) =>
											<tr key={index}>
												<td>{index + 1}</td>
												<td> {releaseDetails.vehicleNumber}</td>
												<td> {releaseDetails.vmoDate}</td>
												<td> {releaseDetails.receiveDate}</td>
												<td> {releaseDetails.releaseDate}</td>
												<td> {releaseDetails.remarks}</td>
											</tr>
										)
									}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default VehicleReleaseDetails;