import React, { useState } from 'react';
import { KTSVG } from '../../_metronic/helpers';
import ExpenseHeadCreate from '../components/ExpenseHeadCreate';
import ExpenseHeadDt from '../components/ExpenseHeadDt';
import { GetPagePermission } from "../components/UserPagePermission";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

function ExpenseHead() {

    const [id, setId] = useState("");
    const [headName, setHeadName] = useState("");
    const [newExpenseHead, setNewExpenseHead] = useState([]);
    const hasPermission = GetPagePermission("/setup-expense-head");
    const onCreate = (newExpenseHead) => {
        setNewExpenseHead(newExpenseHead);
    }

    const onAction = (id, headName) => {
        setId(id);
        setHeadName(headName);
    }

    return (
        <>
            {hasPermission === 1 &&
                <>
                    <div className="card card-custom">
                        <div className="card-header">
                            <div className="card-title">
                                <KTSVG
                                    path='/media/svg/icons/Shopping/Money.svg'
                                    className='svg-icon-1 svg-icon-primary'
                                />
                                <span className="text fw-bolder" style={{ marginLeft: "10px" }}>
                                    ADD EXPENSE HEAD
                                </span>
                            </div>
                        </div>
                        <div className="card-body">
                            <ExpenseHeadCreate onCreate={onCreate} id={id} headName={headName} />
                        </div>
                    </div>
                    <div className="card card-custom mt-5">
                        <div className="card-header">
                            <div className="card-title">
                                <KTSVG
                                    path='/media/svg/icons/Text/Bullet-list.svg'
                                    className='svg-icon-1 svg-icon-primary'
                                />
                                <span className="text fw-bolder" style={{ marginLeft: "10px" }}>
                                    EXPENSE HEAD LIST
                                </span>
                            </div>
                        </div>
                        <div className="card-body">
                            <ExpenseHeadDt addNewExpenseHead={newExpenseHead} onAction={onAction} />
                        </div>
                    </div>
                </>
            }
            {hasPermission === 0 &&
                //    <Error500/>
                <div className='card card-custom' style={{ padding: "15px" }} >
                    <SkeletonTheme>
                        <p>
                            <Skeleton
                                containerClassName="avatar-skeleton"
                                count={40} ></Skeleton>
                        </p>
                    </SkeletonTheme>
                </div>
            }

        </>
    )
}

export default ExpenseHead