import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import Swal from 'sweetalert2';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getRequestOptions } from "../components/GetToken";
import AsyncSelect from 'react-select/async';
import { KTSVG } from '../../_metronic/helpers';
import userPermissionContext from '../../context/userPermissionContext';
import Select from 'react-select';

function ExpenseCreate(props) {

    let history = useHistory();
    const [id, setId] = useState("");
    const [expenseName, setExpenseName] = useState("");
    const [ledger, setLedger] = useState("");
    const [status, setStatus] = useState("active");
    const [expenseHead, setExpenseHead] = useState({});
    const [allExpenseHeads, setAllExpenseHeads] = useState([]);
    const { jwt } = useContext(userPermissionContext);

    const getExpenseInfoById = (id) => {

        fetch(process.env.REACT_APP_API_URL + "getExpenseInfoById/" + id,
            getRequestOptions())
            .then(response => response.json())
            .then(resp => {
                setExpenseName(resp.data.expenseName);
                setExpenseHead({ value: resp.data.idVmsExpenseHead, label: resp.data.headName, idVmsExpenseHead: resp.data.idVmsExpenseHead, headName: resp.data.headName });
                if (resp.data.idLedgers !== null) {
                    setLedger({ ...ledger, value: resp.data.idLedgers, label: resp.data.ledgerName, idLedgers: resp.data.idLedgers, ledgerPath: resp.data.ledgerPath, ledgerName: resp.data.ledgerName });
                }
                else {
                    setLedger("");
                }
                setStatus(resp.data.status);
            })
            .catch(error => {
                console.log(error);
            });
    }

    useEffect(() => {
        const getAllExpenseHeads = () => {

            fetch(process.env.REACT_APP_API_URL + "getAllExpenseHeads",
                getRequestOptions())
                .then((resp) => {
                    return resp.json()
                })
                .then((resp) => {
                    setAllExpenseHeads(resp.data);
                })
                .catch((error) => {
                    console.log(error, "catch the hoop")
                });

        }
        getAllExpenseHeads();
    }, [])

    useEffect(() => {
        if (id !== props.id) {
            setId(props.id);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.id])

    useEffect(() => {
        if (id !== "") {
            getExpenseInfoById(id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    const getAllLedgers = (inputValue, callback) => {
        fetch(process.env.REACT_APP_API_URL + "getAllLedgers?q=" + inputValue,
            getRequestOptions())
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                let tempArray = resp.data.map(function (element) {
                    element.label = element.ledgerName;
                    element.value = element.idLedgers;
                    return element;
                });
                callback(tempArray);
            })
            .catch((error) => {
                console.log(error, "catch the hoop")
            });
    }

    const handleExpenseHeadChange = (expenseHead) => {
        setExpenseHead(expenseHead);
    }

    const ledgerNameChange = (ledger) => {
        setLedger(ledger);
    }
    const handleSubmit = evt => {
        evt.preventDefault();
        if (id !== "") {
            var text = 'You want to update this Expense!';
        }
        else {
            text = 'You want to add this Expense!';
        }
        Swal.fire({
            title: 'Are you sure?',
            text: text,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {
                const formData = {
                    id: id,
                    expenseName: expenseName,
                    ledger: ledger,
                    status: status,
                    expenseHead: expenseHead.value
                };
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt.token },
                    body: JSON.stringify(formData)
                };
                if (id !== "") {
                    var apiEnd = "expenseUpdate";
                }
                else {
                    apiEnd = "expenseAdd";
                }

                fetch(process.env.REACT_APP_API_URL + apiEnd, requestOptions)
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((resp) => {
                        if (resp.success === true) {
                            if (id !== "") {
                                var successMsg = [`Expense '${resp.data.expenseName}' has been updated`];
                            }
                            else {
                                successMsg = [`Expense '${resp.data.expenseName}' has been added`];;
                            }

                            Swal.fire({
                                icon: 'success',
                                title: 'Success',
                                text: successMsg,
                            })
                            setId("");
                            setExpenseName("");
                            setLedger("");
                            setStatus("active");
                            setExpenseHead({});
                            props.onCreate({ id: resp.data.id });
                        }
                        else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Error',
                                html: resp.errorMessage
                            })
                        }
                    })
                    .catch((error) => {
                        console.log(error, "catch the hoop")
                    });

            }
        })
    };

    const expenseStatusArr = [
        { value: 'active', label: 'Active' },
        { value: 'inactive', label: 'Inactive' }
    ]

    return (
        <>
            <div className="ml-12">
                <form onSubmit={handleSubmit}>
                    <div className="form-group row">
                        <label htmlFor="expenseName" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Expense Name <span className="required text-danger"></span></label>
                        <div className="col-lg-5">
                            <input type="text" name="expenseName" id="expenseName" value={expenseName} onChange={(e) => setExpenseName(e.target.value)} className="form-control form-control-sm" />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="example-text-input" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Expense Head<span className="required text-danger"></span></label>
                        <div className="col-lg-5">
                            <Select
                                value={expenseHead}
                                options={allExpenseHeads}
                                onChange={handleExpenseHeadChange}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="example-text-input" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Ledger Name<span className="required text-danger"></span></label>
                        <div className="col-lg-5">
                            <AsyncSelect
                                value={ledger}
                                defaultOptions
                                loadOptions={getAllLedgers}
                                onChange={ledgerNameChange}
                                getOptionLabel={(option) => (
                                    <>
                                        <KTSVG
                                            path='/media/svg/icons/Files/File.svg'
                                            className='svg-icon-2 svg-icon-primary'
                                        />
                                        <span> {option.label} </span><br></br>
                                        <KTSVG
                                            path='media/svg/icons/Map/Marker1.svg'
                                            className='svg-icon-2 svg-icon-warning'
                                        />
                                        <span>{option.ledgerPath} </span>
                                    </>
                                )}
                            />
                        </div>
                    </div>
                    <div className="form-group row mt-2">
                        <label htmlFor="status" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }} >Status<span className="required text-danger"></span></label>
                        <div className="col-lg-5">
                            <select className="form-select form-select-sm" id="status" name="status" value={status} onChange={(e) => setStatus(e.target.value)} >
                                {expenseStatusArr.map(function (item, id) {
                                    return <option key={id} value={item.value}>{item.label}</option>
                                })
                                }
                            </select>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-5">
                        </div>
                        <div className="col-6">
                            <input type="submit" className="btn btn-sm btn-success me-2" value={id !== "" ? 'Update' : 'Save'} />
                            <button type="reset" className="btn btn-sm btn-secondary" onClick={() => history.goBack()}>Cancel</button>
                        </div>
                    </div>
                    {/* <pre>
                        {JSON.stringify(id, null, 2)},
                        {JSON.stringify(expenseName, null, 2)},
                        {JSON.stringify(ledger, null, 2)},
                        {JSON.stringify(status, null, 2)}
                    </pre> */}
                </form>
            </div>
            <ToastContainer />
        </>
    )

}

export default ExpenseCreate;