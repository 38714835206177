/* eslint-disable no-restricted-globals */
import clsx from 'clsx'
import React, { FC, useState, useEffect } from 'react'
import { KTSVG } from '../../../helpers'
import { HeaderUserMenu } from '../../../partials'
import { useLayout } from '../../core'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
	toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px'

const Topbar: FC = () => {
	const { config } = useLayout()

	const getRootDomain = () => {
		var root_domain = "";
		if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(location.host.split(':')[0])) {
			return location.host.split(':')[0];
		} else if (location.host.indexOf('localhost') !== -1) {
			return 'localhost';
		} else {
			var temp = location.host.split('.').reverse();
			root_domain = '.' + temp[1] + '.' + temp[0];
			return root_domain;
		}
	}


	const getCookie = (name: string) => {
		const value = `; ${document.cookie}`;
		const parts = value.split(`; ${name}=`);
		return parts.pop()?.split(';').shift()

	}

	const [fullname, setFullname] = useState(null);
	const [pic, setPic] = useState(null);

	useEffect(() => {

		var jwt = { token: getCookie('MyToken') };
		const requestOptions = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt.token },

		};
		fetch(process.env.REACT_APP_API_URL + "users/getUserInfo", requestOptions)
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {
				setFullname(resp.data.fullName);
				setPic(resp.data.fileName);
			})
			.catch((error) => {
				document.cookie = 'MyToken=;path=/;domain=' + getRootDomain() + ';expires=' + new Date(0).toUTCString();
				window.location.reload();
			});


	}, []);

	return (
		<div className='d-flex align-items-stretch flex-shrink-0'>


			{/* begin::User */}
			<div
				className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
				id='kt_header_user_menu_toggle'
			>
				{/* begin::Toggle */}
				<div
					className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
					data-kt-menu-trigger='click'
					data-kt-menu-attach='parent'
					data-kt-menu-placement='bottom-end'
					data-kt-menu-flip='bottom'
				>
					{fullname !== null &&
						<>
							<img src={`${process.env.REACT_APP_USER_ASSET}${pic}`} alt='user' /> <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3" style={{ color: "rgb(255, 255, 255)" }}>{fullname}</span>
						</>
					}
				</div>
				<HeaderUserMenu />
				{/* end::Toggle */}
			</div>
			{/* end::User */}

			{/* begin::Aside Toggler */}
			{config.header.left === 'menu' && (
				<div className='d-flex align-items-center d-lg-none ms-2 me-n3' title='Show header menu'>
					<div
						className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
						id='kt_header_menu_mobile_toggle'
					>
						<KTSVG path='/media/icons/duotune/text/txt001.svg' className='svg-icon-1' />
					</div>
				</div>
			)}
		</div>
	)
}

export { Topbar }
