import React, { useState } from 'react';
import { KTSVG } from '../../_metronic/helpers';
import ExpenseCreate from '../components/ExpenseCreate';
import ExpenseDt from '../components/ExpenseDt';
import { GetPagePermission } from "../components/UserPagePermission";
// import Error500 from '../components/Error500';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

function Expense() {

    const [id, setId] = useState("");
    const [newExpenseType, setNewExpenseType] = useState([]);
    const hasPermission = GetPagePermission("/setup-expense-types");
    const onCreate = (newExpenseType) => {
        setNewExpenseType(newExpenseType);
    }

    const onAction = (id) => {
        setId(id);
    }

    return (
        <>
            {hasPermission === 1 &&
                <>
                    <div className="card card-custom">
                        <div className="card-header">
                            <div className="card-title">
                                <KTSVG
                                    path='/media/svg/icons/Shopping/Money.svg'
                                    className='svg-icon-1 svg-icon-primary'
                                />
                                <span className="text fw-bolder" style={{ marginLeft: "10px" }}>
                                    ADD EXPENSE TYPE
                                </span>
                            </div>
                        </div>
                        <div className="card-body">
                            <ExpenseCreate onCreate={onCreate} id={id} />
                        </div>
                    </div>
                    <div className="card card-custom mt-5">
                        <div className="card-header">
                            <div className="card-title">
                                <KTSVG
                                    path='/media/svg/icons/Text/Bullet-list.svg'
                                    className='svg-icon-1 svg-icon-primary'
                                />
                                <span className="text fw-bolder" style={{ marginLeft: "10px" }}>
                                    EXPENSES TYPE LIST
                                </span>
                            </div>
                        </div>
                        <div className="card-body">
                            <ExpenseDt addNewExpenseType={newExpenseType} onAction={onAction} />
                        </div>
                    </div>
                </>
            }
            {hasPermission === 0 &&
                //    <Error500/>
                <div className='card card-custom' style={{ padding: "15px" }} >
                    <SkeletonTheme>
                        <p>
                            <Skeleton
                                containerClassName="avatar-skeleton"
                                count={40} ></Skeleton>
                        </p>
                    </SkeletonTheme>
                </div>
            }

        </>
    )
}

export default Expense