import { useEffect, useContext } from 'react';
import BackendTable from 'react-backend-table';
import { KTSVG } from '../../_metronic/helpers';
import userPermissionContext from '../../context/userPermissionContext';

function ExpenseHeadDt(props) {
    const { jwt } = useContext(userPermissionContext);
    let columns = [
        { title: 'Id', field: 'idVmsExpenseHead', sortable: true, searchable: true, thStyle: { textAlign: "center" }, tdStyle: { textAlign: "center" } },
        { title: 'Expense Head', field: 'headName', sortable: true, searchable: true },
        {
            title: "Action",
            field: "action",
            thStyle: { textAlign: "center" },
            tdClass: "text-center",
            tdStyle: { whiteSpace: 'nowrap' },
            sortable: false,
            searchable: false,
            hasComponent: true,
            componentValue: ((rowValue) => {
                return (<button className="btn btn-light-primary btn-sm" onClick={() => { props.onAction(rowValue.idVmsExpenseHead, rowValue.headName) }}>
                    <KTSVG
                        path='/media/svg/icons/Communication/Write.svg'
                        className='svg-icon-5 svg-icon'
                    />
                    Edit</button>
                )
            })
        }
    ]

    let options = {
        url: process.env.REACT_APP_API_URL + 'expenseHeadList',
        authorization: 'Bearer ' + jwt.token,
        perPage: [5, 10, 30, 50, 'All'],
        orderBy: 'idVmsExpenseHead',
        orderType: 'desc',
        columnSearch: true,
    }

    useEffect(() => {
        options.reloadMyTable();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.addNewExpenseHead, props.onCreate])
    return (
        <BackendTable columns={columns} options={options} />
    )
}

export default ExpenseHeadDt