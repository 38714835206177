/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import { ToastContainer } from 'react-toastify';
import AsyncSelect from 'react-select/async';
import 'react-toastify/dist/ReactToastify.css';
import { KTSVG } from '../../_metronic/helpers';
import Swal from 'sweetalert2';
import { useHistory } from "react-router-dom";
import { getRequestOptions } from "../components/GetToken";
import { GetPagePermission } from "../components/UserPagePermission";
// import Error500 from "../components/Error500";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import userPermissionContext from '../../context/userPermissionContext';

const customStylesSelect = {
    control: (provided) => ({
        ...provided,
        height: 'calc(1.35em + 1.1rem + 2px)',
        minHeight: '35px'
    }),
    valueContainer: (provided) => ({
        ...provided,
    })
};

function RentalBillCreate() {
    const { jwt } = useContext(userPermissionContext);
    const initialState = () => ({
        fromBusinessUnit: "",
        toBusinessUnit: "",
        month: "",
        year: "",
        billDate: "",
        totalRegularBillAmount: 0,
        totalBillAmount: 0,
        totalDeductionAmount: 0,
        billDetails: []
    })
    const [rentalBill, setRentalBill] = useState(initialState());
    const hasPermission = GetPagePermission("/rental-billCreate");

    let history = useHistory();

    useEffect(() => {
        const rentalBillGenerate = () => {
            const formData = {
                fromBusinessUnit: rentalBill.fromBusinessUnit,
                toBusinessUnit: rentalBill.toBusinessUnit,
                month: rentalBill.month,
                year: rentalBill.year
            };
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt.token },
                body: JSON.stringify(formData)
            };
            var apiEnd = "rentalBillGenerate";
            fetch(process.env.REACT_APP_API_URL + apiEnd, requestOptions)
                .then((resp) => {
                    return resp.json()
                })
                .then((resp) => {
                    const newBillItems = resp.data.map(function (fobj) {
                        fobj.regularBillingAmount = Number(fobj.billAmountCalc.split("-")[0]);
                        fobj.deductionAmount = Number(fobj.deductionAmount);
                        fobj.billingAmount = (Number(fobj.billAmountCalc.split("-")[0]) - Number(fobj.deductionAmount)).toFixed(2);
                        fobj.billPeriod = fobj.billAmountCalc.split("-")[1] > 0 ? '1 Month' : fobj.billAmountCalc.split("-")[2] + ' Days'
                        fobj.billAmount = fobj.billAmountCalc.split("-")[0];
                        return fobj;
                    })
                    setRentalBill({ ...rentalBill, billDetails: newBillItems });
                })
                .catch((error) => {
                    console.log(error, "catch the hoop")
                });
        }

        if (rentalBill.fromBusinessUnit.projectId && rentalBill.toBusinessUnit.projectId && rentalBill.month !== "" && rentalBill.year !== "") {
            rentalBillGenerate();
        }
    }, [rentalBill.fromBusinessUnit, rentalBill.toBusinessUnit, rentalBill.month, rentalBill.year]);

    useEffect(() => {
        if (rentalBill.billDetails !== "") {
            calcTotal();
        }
    }, [rentalBill.billDetails])

    const getAllProject = (inputValue, callback) => {

        if (!inputValue) {
            callback([]);

        }
        else {
            var url = process.env.REACT_APP_API_URL + "getBusinessUnits?q=" + inputValue;

            setTimeout(() => {
                fetch(url,
                    getRequestOptions())

                    .then((resp) => {
                        return resp.json()
                    })
                    .then((resp) => {
                        const tempArray = resp.data.map(function (element) {
                            element.label = element.projectName;
                            element.value = element.projectId;
                            return element;
                        });
                        callback(tempArray);
                    })
                    .catch((error) => {
                        console.log(error, "catch the hoop")
                    });
            });
        }
    }

    const handleFromBusinessUnitChange = businessUnit => {
        setRentalBill({ ...rentalBill, fromBusinessUnit: businessUnit });
    }

    const handleToBusinessUnitChange = businessUnit => {
        setRentalBill({ ...rentalBill, toBusinessUnit: businessUnit });
    }

    const handleInputChange = (event) => {
        setRentalBill({ ...rentalBill, [event.target.name]: event.target.value });
    }


    const regularBillingAmountChange = index => evt => {
        const newItems = rentalBill.billDetails.map(function (row, rindex) {
            if (index === rindex) {
                row.regularBillingAmount = evt.target.value;
                row.billAmount = (Number(row.regularBillingAmount) - Number(row.deductionAmount)).toFixed(2);
            }
            return row;
        })
        setRentalBill({ ...rentalBill, billDetails: newItems });
    }

    const deductionAmountChange = index => evt => {
        const newItems = rentalBill.billDetails.map(function (row, rindex) {
            if (index === rindex) {
                row.deductionAmount = evt.target.value;
                row.billAmount = (Number(row.regularBillingAmount) - Number(row.deductionAmount)).toFixed(2);
            }
            return row;
        })
        setRentalBill({ ...rentalBill, billDetails: newItems });
    }

    const objPsum = (items, prop) => {
        return items.reduce(function (a, b) {
            return Number(a) + Number(b[prop]);
        }, 0);
    }
    const calcTotal = () => {
        setRentalBill({ ...rentalBill, totalRegularBillAmount: objPsum(rentalBill.billDetails, 'regularBillingAmount'), totalDeductionAmount: objPsum(rentalBill.billDetails, 'deductionAmount'), totalBillAmount: objPsum(rentalBill.billDetails, 'billAmount') })
    }

    const handleSubmit = evt => {
        evt.preventDefault();
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to create this rental bill!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {
                const formData = { ...rentalBill };
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt.token },
                    body: JSON.stringify(formData)
                };
                var apiEnd = "rentalBillCreate";
                fetch(process.env.REACT_APP_API_URL + apiEnd, requestOptions)
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((resp) => {
                        if (resp.success === true) {
                            Swal.fire({
                                icon: 'success',
                                title: 'Success',
                                text: resp.successMessage,
                            })
                            setRentalBill(initialState());
                        }
                        else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Error',
                                html: resp.errorMessage
                            })
                        }
                    })
                    .catch((error) => {
                        console.log(error, "catch the hoop")
                    });
            }
        })
    }

    const yearEndRange = new Date().getFullYear();
    const yearStartRange = yearEndRange - 1;

    const monthOption = [{ label: 'January', value: '1', },
    { label: 'February', value: '2' },
    { label: 'March', value: '3' },
    { label: 'April', value: '4' },
    { label: 'May', value: '5' },
    { label: 'June', value: '6' },
    { label: 'July', value: '7' },
    { label: 'August', value: '8' },
    { label: 'September', value: '9' },
    { label: 'October', value: '10' },
    { label: 'November', value: '11' },
    { label: 'December', value: '12' },
    ];


    const yearOption = [];
    for (let i = yearStartRange; i <= yearEndRange; i++) {
        yearOption.push(i);
    }

    return (
        <>
            {hasPermission === 1 &&
                <div className="card card-custom">
                    <div className="card-header">
                        <div className="card-title">
                            <span>
                                <KTSVG
                                    path='/media/svg/icons/Shopping/Price1.svg'
                                    className='svg-icon-1 svg-icon-primary'
                                />
                            </span>
                            <span className="text fw-bolder" style={{ marginLeft: "10px" }}>
                                RENTAL BILL CREATE
                            </span>
                        </div>
                    </div>

                    <div className="ml-12">
                        <form onSubmit={handleSubmit}>
                            <div className="card-body">
                                <div className="form-group row">
                                    <label htmlFor="example-text-input" style={{ "textAlign": "right" }} className="col-lg-3 col-form-label" >From Business Unit<span className="required text-danger"></span></label>
                                    <div className="col-lg-5">
                                        <AsyncSelect
                                            value={rentalBill.fromBusinessUnit}
                                            defaultOptions
                                            loadOptions={getAllProject}
                                            placeholder="Select Project"
                                            onChange={handleFromBusinessUnitChange}
                                            styles={customStylesSelect}
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="example-text-input" style={{ "textAlign": "right" }} className="col-lg-3 col-form-label" >To Business Unit<span className="required text-danger"></span></label>
                                    <div className="col-lg-5">
                                        <AsyncSelect
                                            value={rentalBill.toBusinessUnit}
                                            defaultOptions
                                            loadOptions={getAllProject}
                                            placeholder="Select Project"
                                            onChange={handleToBusinessUnitChange}
                                            styles={customStylesSelect}
                                        />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label htmlFor="month" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Month<span className="required text-danger"></span></label>
                                    <div className="col-lg-6">
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <select className="form-select form-select-sm" id="month" name="month" value={rentalBill.month} onChange={handleInputChange} >
                                                    <option value="">Select Month</option>
                                                    {monthOption.map(function (item, id) {
                                                        return <option key={id} value={item.value}>{item.label}</option>
                                                    })
                                                    }
                                                </select>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group row">
                                                    <label htmlFor="year" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }}>Year<span className="required text-danger"></span></label>
                                                    <div className="col-lg-8">
                                                        <select className="form-select form-select-sm" id="year" name="year" value={rentalBill.year} onChange={handleInputChange} >
                                                            <option value="">Select Year</option>
                                                            {yearOption.map(function (item, id) {
                                                                return <option key={id} value={item}>{item}</option>
                                                            })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="date" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }} > Bill Date <span className="required text-danger"></span></label>
                                    <div className="col-lg-5">
                                        <input type="date" name="billDate" id="billDate" value={rentalBill.billDate} onChange={handleInputChange} className="form-control form-control-sm" />
                                    </div>
                                </div>
                                {rentalBill.billDetails.length > 0 &&

                                    <div className="table-responsive mt-5">
                                        <table className="table table-bordered table-hover" style={{ minWidth: "max-content" }}>
                                            <thead>
                                                <tr>
                                                    <th>SL</th>
                                                    <th>Vehicle Number</th>
                                                    <th>VMO</th>
                                                    <th>VRO</th>
                                                    <th>Bill Periods</th>
                                                    <th>Monthly Rent</th>
                                                    <th>Regular Billing Amount</th>
                                                    <th>Deduction Amount</th>
                                                    <th>Bill Amount</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {rentalBill.billDetails.map((item, index) =>
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{item.vehicleNumber}</td>
                                                        <td>
                                                            <span style={{ whiteSpace: "nowrap" }}>{item.vmoNo}</span><br />
                                                            {item.vmoReceiveDate}
                                                        </td>
                                                        <td>
                                                            <span style={{ whiteSpace: "nowrap" }}>{item.vroNo}</span><br />
                                                            {item.vehicleReleaseDate}
                                                        </td>
                                                        <td>{item.billPeriod}</td>
                                                        <td>{item.monthlyRent}</td>
                                                        <td><input type="number" name="regularBillingAmount" value={item.regularBillingAmount} onChange={regularBillingAmountChange(index)} className="form-control form-control-sm" /></td>
                                                        <td><input type="number" name="deductionAmount" value={item.deductionAmount} onChange={deductionAmountChange(index)} className="form-control form-control-sm" /></td>
                                                        <td>{item.billAmount}</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td colSpan={6} style={{ "textAlign": "right" }}> Total</td>
                                                    <td>{rentalBill.totalRegularBillAmount}</td>
                                                    <td>{rentalBill.totalDeductionAmount}</td>
                                                    <td>{rentalBill.totalBillAmount}</td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>


                                }
                                <div className="row mt-2">
                                    <div className="col-5">
                                    </div>
                                    <div className="col-6">
                                        <button type="submit" className="btn btn-sm btn-success me-2">Submit</button>
                                        <button type="reset" className="btn btn-sm btn-secondary" onClick={() => history.goBack()}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                            {/* <pre>
                                {JSON.stringify(rentalBill, null, 2)}
                            </pre> */}
                        </form>
                    </div>
                    <ToastContainer />
                </div>
            }
            {hasPermission === 0 &&
                <div className='card card-custom' style={{ padding: "15px" }} >
                    <SkeletonTheme>
                        <p>
                            <Skeleton
                                containerClassName="avatar-skeleton"
                                count={40} ></Skeleton>
                        </p>
                    </SkeletonTheme>
                </div>
            }

        </>
    )
}

export default RentalBillCreate