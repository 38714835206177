import { useContext } from 'react';
import BackendTable from 'react-backend-table';
import { KTSVG } from '../../_metronic/helpers';
import { GetPagePermission } from "../components/UserPagePermission";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Link } from "react-router-dom";
import Badge from 'react-bootstrap/Badge';
import userPermissionContext from '../../context/userPermissionContext';

function VmoList() {
    const { jwt } = useContext(userPermissionContext);
    const hasPermission = GetPagePermission("/vmo-list");
    let columns = [
        { title: 'Id', field: 'idVmo', sortable: true, searchable: true, thStyle: { textAlign: "center" }, tdStyle: { textAlign: "center" } },
        { title: 'Vmo No', field: 'vmoNo', sortable: true, searchable: true },
        { title: 'Vmo Date', field: 'vmodate', sortable: true, searchable: true },
        { title: 'Business Unit', field: 'ownBusinessUnit', searchable: true, sortable: true },
        { title: 'Receiving Business Unit', field: 'recBusinessUnit', searchable: true, sortable: true },
        { title: 'Remarks', field: 'remarks', searchable: true, sortable: true },
        {
            title: 'Status',
            field: 'status',
            tdStyle: { textAlign: "center" },
            sortable: true,
            searchable: true,
            hasComponent: true,
            componentValue: ((rowValue) => {
                return (
                    <>
                        {rowValue.status === "created" && <Badge bg="primary">Created</Badge>}
                        {rowValue.status === "approved" && <Badge bg="warning">Approved</Badge>}
                        {rowValue.status === "partially_released" && <Badge bg="info">Partially Released</Badge>}
                        {rowValue.status === "fully_released" && <Badge bg="success">Fully Released</Badge>}
                    </>
                )
            })
        },
        {
            title: "Action",
            field: "action",
            thStyle: { textAlign: "center" },
            tdStyle: { whiteSpace: 'nowrap' },
            sortable: false,
            searchable: false,
            hasComponent: true,
            componentValue: ((rowValue) => {
                return (
                    <>
                        <Link to={`/vmo-details/${rowValue.idVmo}`}>
                            <button className="btn btn-light-info btn-sm">
                                <KTSVG
                                    path='/media/svg/icons/Text/Bullet-list.svg'
                                    className='svg-icon-5 svg-icon'
                                />		Details
                            </button>
                            <br />
                        </Link>

                        {rowValue.status === "created" &&
                            <>
                                <Link to={`/vmo-edit/${rowValue.idVmo}`}>
                                    <button className="btn btn-light-primary btn-sm mt-1">
                                        <KTSVG
                                            path='/media/svg/icons/Communication/Write.svg'
                                            className='svg-icon-5 svg-icon'
                                        />		Edit
                                    </button>
                                </Link>
                                <br />
                                <Link to={`/vmo-approve/${rowValue.idVmo}`}>
                                    <button className="btn btn-light-success btn-sm mt-1">
                                        <KTSVG
                                            path='/media/svg/icons/General/Shield-check.svg'
                                            className='svg-icon-5 svg-icon'
                                        />		Approve
                                    </button>
                                </Link>
                            </>
                        }

                    </>
                )
            })
        }
    ]

    let options = {
        url: process.env.REACT_APP_API_URL + 'vmoList',
        authorization: 'Bearer ' + jwt.token,
        perPage: [50, 100, 500, 1000, 'All'],
        orderBy: 'idVmo',
        orderType: 'desc',
        columnSearch: true,
    }

    return (
        <>
            {hasPermission === 1 &&
                <div className='card card-custom'>
                    <div className='card-header'>
                        <div className='card-title'>
                            <span>
                                <KTSVG
                                    path='/media/svg/icons/Text/Bullet-list.svg'
                                    className='svg-icon-1 svg-icon-primary'
                                />
                            </span>
                            <span className='text fw-bolder' style={{ marginLeft: '10px' }}>
                                VMO LIST
                            </span>
                        </div>
                    </div>

                    <div className='ml-12'>
                        <BackendTable columns={columns} options={options} />
                    </div>
                </div>
            }
            {hasPermission === 0 &&
                <div className='card card-custom' style={{ padding: "15px" }} >
                    <SkeletonTheme>
                        <p>
                            <Skeleton
                                containerClassName="avatar-skeleton"
                                count={40} ></Skeleton>
                        </p>
                    </SkeletonTheme>
                </div>
            }

        </>
    )
}

export default VmoList;