/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useContext } from "react";
import { ToastContainer } from 'react-toastify';
import AsyncSelect from 'react-select/async';
import 'react-toastify/dist/ReactToastify.css';
import { KTSVG } from '../../_metronic/helpers';
import Swal from 'sweetalert2';
import { useHistory } from "react-router-dom";
import { getRequestOptions } from "../components/GetToken";
import { GetPagePermission } from "../components/UserPagePermission";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import userPermissionContext from '../../context/userPermissionContext';

const customStylesSelect = {
	control: (provided) => ({
		...provided,
		height: 'calc(1.35em + 1.1rem + 2px)',
		minHeight: '35px'
	}),
	valueContainer: (provided) => ({
		...provided
	})
};


function VehicleRelease() {
	const { jwt } = useContext(userPermissionContext);
	const initialState = () => ({
		releasingBusinessUnit: "",
		releasingBusinessUnitId: "",
		remarks: "",
		vehicleReleaseDetails: [{ vehicle: "", vmoDate: "", receiveDate: "", releaseDate: "", remarks: "" }]
	})
	const [vehicleRelease, setVehicleRelease] = useState(initialState());
	const hasPermission = GetPagePermission("/vro-create");

	let history = useHistory();

	const getAllProject = (inputValue, callback) => {

		if (!inputValue) {
			callback([]);

		}
		else {
			var url = process.env.REACT_APP_API_URL + "getBusinessUnits?q=" + inputValue;

			setTimeout(() => {
				fetch(url,
					getRequestOptions())

					.then((resp) => {
						return resp.json()
					})
					.then((resp) => {
						const tempArray = resp.data.map(function (element) {
							element.label = element.projectName;
							element.value = element.projectId;
							return element;
						});
						callback(tempArray);
					})
					.catch((error) => {
						console.log(error, "catch the hoop")
					});
			});
		}
	}

	const getAllVehicle = (inputValue, callback) => {

		if (!inputValue) {
			callback([]);

		}
		else if (!vehicleRelease.releasingBusinessUnitId) {
			callback([]);
			Swal.fire({
				title: 'Warning!',
				text: "Select releasing business unit first!",
				icon: 'warning',
				showCancelButton: false,
				confirmButtonText: 'Ok',
				cancelButtonText: 'No'
			});
		}
		else {
			var url = process.env.REACT_APP_API_URL + "vehicleRelease/vehicleSearch?q=" + inputValue + "&r=" + vehicleRelease.releasingBusinessUnitId;

			setTimeout(() => {
				fetch(url,
					getRequestOptions())

					.then((resp) => {
						return resp.json()
					})
					.then((resp) => {
						const tempArray = resp.data.map(function (element) {
							element.label = element.vehicleNumber;
							element.value = element.vehicleInfoId;
							return element;
						});
						callback(tempArray);
					})
					.catch((error) => {
						console.log(error, "catch the hoop")
					});
			});
		}
	}

	const handleBusinessUnitChange = businessUnit => {
		setVehicleRelease({
			...vehicleRelease, releasingBusinessUnit: businessUnit,
			releasingBusinessUnitId: businessUnit.projectId,
		});
	}

	const handleInputChange = (event) => {
		setVehicleRelease({ ...vehicleRelease, [event.target.name]: event.target.value });
	}

	const handleVehicleChange = index => vehicle => {
		const newItems = vehicleRelease.vehicleReleaseDetails.map(function (row, rindex) {
			if (index === rindex) {
				row.vehicle = vehicle;
				row.vmoDate = vehicle.vmoDate;
				row.receiveDate = vehicle.receiveDate;
			}
			return row;
		})
		setVehicleRelease({ ...vehicleRelease, vehicleReleaseDetails: newItems });
	}

	const handleReleaseDateChange = index => evt => {
		const newItems = vehicleRelease.vehicleReleaseDetails.map(function (row, rindex) {
			if (index === rindex) {
				row.releaseDate = evt.target.value;
			}
			return row;
		})
		setVehicleRelease({ ...vehicleRelease, vehicleReleaseDetails: newItems });
	}

	const handleRemarksChange = index => evt => {
		const newItems = vehicleRelease.vehicleReleaseDetails.map(function (row, rindex) {
			if (index === rindex) {
				row.remarks = evt.target.value;
			}
			return row;
		})
		setVehicleRelease({ ...vehicleRelease, vehicleReleaseDetails: newItems });
	}

	const addItem = () => {
		setVehicleRelease({ ...vehicleRelease, vehicleReleaseDetails: vehicleRelease.vehicleReleaseDetails.concat([{ vehicle: "", vmoDate: "", receiveDate: "", releaseDate: "", remarks: "" }]) });
	}

	const removeItem = (index) => () => {
		setVehicleRelease({ ...vehicleRelease, vehicleReleaseDetails: vehicleRelease.vehicleReleaseDetails.filter((row, rindex) => index !== rindex) });
	}

	const handleSubmit = evt => {
		evt.preventDefault();
		Swal.fire({
			title: 'Are you sure?',
			text: "You want to create this vehicle release order!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes',
			cancelButtonText: 'No'
		}).then((result) => {
			if (result.value) {
				const formData = { ...vehicleRelease };
				const requestOptions = {
					method: 'POST',
					headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt.token },
					body: JSON.stringify(formData)
				};
				var apiEnd = "vehicleReleaseOrderCreate";
				fetch(process.env.REACT_APP_API_URL + apiEnd, requestOptions)
					.then((resp) => {
						return resp.json()
					})
					.then((resp) => {
						if (resp.success === true) {
							Swal.fire({
								icon: 'success',
								title: 'Success',
								text: resp.successMessage,
							})
							setVehicleRelease(initialState());
						}
						else {
							Swal.fire({
								icon: 'error',
								title: 'Error',
								html: resp.errorMessage
							})
						}
					})
					.catch((error) => {
						console.log(error, "catch the hoop")
					});
			}
		})
	}

	return (
		<>
			{hasPermission === 1 &&
				<div className="card card-custom">
					<div className="card-header">
						<div className="card-title">
							<span>
								<KTSVG
									path='/media/svg/icons/Devices/Server.svg'
									className='svg-icon-1 svg-icon-primary'
								/>
							</span>
							<span className="text fw-bolder" style={{ marginLeft: "10px" }}>
								VEHICLE RELEASE ORDER
							</span>
						</div>
					</div>

					<div className="ml-12">
						<form onSubmit={handleSubmit}>
							<div className="card-body">
								<div className="form-group row">
									<label htmlFor="example-text-input" style={{ "textAlign": "right" }} className="col-lg-3 col-form-label" >Releasing Business Unit<span className="required text-danger"></span></label>
									<div className="col-lg-5">
										<AsyncSelect
											value={vehicleRelease.businessUnit}
											defaultOptions
											loadOptions={getAllProject}
											placeholder="Select Project"
											onChange={handleBusinessUnitChange}
											styles={customStylesSelect}
										/>
									</div>
								</div>
								<div className="form-group row">
									<label htmlFor="remarks" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }} > Remarks </label>
									<div className="col-lg-5">
										<textarea name="remarks" id="remarks" value={vehicleRelease.remarks} onChange={handleInputChange} className="form-control form-control-sm" />
									</div>
								</div>
								<div className="mt-5">
									<div className="table" style={{ margin: "5px" }}>
										<table className="table table-bordered table-hover" style={{ minWidth: "max-content" }}>
											<thead>
												<tr>
													<th>Sl</th>
													<th>Vehicle Number</th>
													<th>VMO Date</th>
													<th>Receive Date</th>
													<th>Release Date</th>
													<th>Remarks</th>
													<th>Action</th>
												</tr>
											</thead>
											<tbody>
												{
													vehicleRelease.vehicleReleaseDetails.map((item, index) =>
														<tr key={index}>
															<td>{index + 1}</td>
															<td> <AsyncSelect
																value={item.vehicle}
																defaultOptions
																loadOptions={getAllVehicle}
																placeholder="Select Vehicle"
																onChange={handleVehicleChange(index)}
																styles={customStylesSelect}
															/></td>
															<td>{item.vmoDate}</td>
															<td>{item.receiveDate}</td>
															<td><input type="date" name="releaseDate" value={item.releaseDate} onChange={handleReleaseDateChange(index)} className="form-control form-control-sm" /></td>
															<td><textarea name="remarks" value={item.remarks} onChange={handleRemarksChange(index)} className="form-control form-control-sm" /></td>
															<td>
																{index === (vehicleRelease.vehicleReleaseDetails.length - 1) &&
																	<button type="button" className="btn btn-icon btn-primary btn-sm me-1" onClick={addItem}>
																		<KTSVG path='/media/svg/icons/Navigation/Plus.svg' className='svg-icon svg-icon-5' />
																	</button>
																}
																{vehicleRelease.vehicleReleaseDetails.length > 1 &&
																	<button type="button" className="btn btn-icon btn-danger btn-sm me-1" onClick={removeItem(index)}>
																		<KTSVG path='/media/svg/icons/General/Trash.svg' className='svg-icon svg-icon-5' />
																	</button>
																}
															</td>
														</tr>
													)
												}

											</tbody>
										</table>
									</div>
								</div>

								<div className="row mt-2">
									<div className="col-5">
									</div>
									<div className="col-6">
										<button type="submit" className="btn btn-sm btn-success me-2">Submit</button>
										<button type="reset" className="btn btn-sm btn-secondary" onClick={() => history.goBack()}>Cancel</button>
									</div>
								</div>
							</div>
							{/* <pre>
								{JSON.stringify(vmo, null, 2)}
							</pre> */}
						</form>
					</div>
					<ToastContainer />
				</div>
			}
			{hasPermission === 0 &&
				<div className='card card-custom' style={{ padding: "15px" }} >
					<SkeletonTheme>
						<p>
							<Skeleton
								containerClassName="avatar-skeleton"
								count={40} ></Skeleton>
						</p>
					</SkeletonTheme>
				</div>
			}

		</>
	)
}

export default VehicleRelease