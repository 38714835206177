import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import Swal from 'sweetalert2';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import userPermissionContext from '../../context/userPermissionContext';

function ExpenseHeadCreate(props) {

    let history = useHistory();
    const [id, setId] = useState("");
    const [headName, setHeadName] = useState("");
    const { jwt } = useContext(userPermissionContext);

    useEffect(() => {
        if (id !== props.id && headName !== props.headName) {
            setId(props.id);
            setHeadName(props.headName);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.id])

    const handleSubmit = evt => {
        evt.preventDefault();
        if (id !== "") {
            var text = 'You want to update this Expense Head!';
        }
        else {
            text = 'You want to add this Expense Head!';
        }
        Swal.fire({
            title: 'Are you sure?',
            text: text,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {
                const formData = {
                    id: id,
                    headName: headName,
                };
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt.token },
                    body: JSON.stringify(formData)
                };
                if (id !== "") {
                    var apiEnd = "expenseHeadUpdate";
                }
                else {
                    apiEnd = "expenseHeadAdd";
                }

                fetch(process.env.REACT_APP_API_URL + apiEnd, requestOptions)
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((resp) => {
                        if (resp.success === true) {
                            if (id !== "") {
                                var successMsg = [`Expense Head '${resp.data.headName}' has been updated`];
                            }
                            else {
                                successMsg = [`Expense Head '${resp.data.headName}' has been added`];;
                            }

                            Swal.fire({
                                icon: 'success',
                                title: 'Success',
                                text: successMsg,
                            })
                            setId("");
                            setHeadName("");
                            props.onCreate({ id: resp.data.id });
                        }
                        else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Error',
                                html: resp.errorMessage
                            })
                        }
                    })
                    .catch((error) => {
                        console.log(error, "catch the hoop")
                    });

            }
        })
    };

    return (
        <>
            <div className="ml-12">
                <form onSubmit={handleSubmit}>
                    <div className="form-group row">
                        <label htmlFor="headName" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Expense Head <span className="required text-danger"></span></label>
                        <div className="col-lg-5">
                            <input type="text" name="headName" id="headName" value={headName} onChange={(e) => setHeadName(e.target.value)} className="form-control form-control-sm" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-5">
                        </div>
                        <div className="col-6">
                            <input type="submit" className="btn btn-sm btn-success me-2" value={id !== "" ? 'Update' : 'Save'} />
                            <button type="reset" className="btn btn-sm btn-secondary" onClick={() => history.goBack()}>Cancel</button>
                        </div>
                    </div>
                    {/* <pre>
                        {JSON.stringify(id, null, 2)},
                        {JSON.stringify(mainHeadName, null, 2)},
                    </pre> */}
                </form>
            </div>
            <ToastContainer />
        </>
    )

}

export default ExpenseHeadCreate;