import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getRequestOptions } from "../components/GetToken";
import { KTSVG } from '../../_metronic/helpers';
import Badge from 'react-bootstrap/Badge';

function VmoDetails() {
    const { id } = useParams();
    const [details, setDetails] = useState([]);

    useEffect(() => {
        const getRentalBillDetails = () => {
            fetch(process.env.REACT_APP_API_URL + "rentalBillDetails/" + id, getRequestOptions())
                .then((resp) => {
                    return resp.json()
                })
                .then((resp) => {
                    setDetails(resp.data);
                })
                .catch((error) => {
                    console.log(error, "catch the hoop")
                });
        }
        getRentalBillDetails();
    }, [id]);

    return (
        <>
            <div className="card card-custom">
                <div className="card-header">
                    <div className="card-title">
                        <KTSVG
                            path='/media/icons/duotune/general/gen005.svg'
                            className='svg-icon-1 svg-icon-primary'
                        />
                        <span className="text fw-bolder">
                            RENTAL BILL DETAILS
                        </span>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-8 mt-5">
                            <table className="table table-sm table-hover table-bordered">
                                <tbody>
                                    <tr>
                                        <th>Bill No</th>
                                        <td>{details.billNo}</td>
                                    </tr>
                                    {details.from_bu && <tr>
                                        <th>From Businessunit</th>
                                        <td>{details.from_bu.fromBuName}</td>
                                    </tr>}
                                    {details.to_bu &&
                                        <tr>
                                            <th>To Businessunit</th>
                                            <td>{details.to_bu.toBuName}</td>
                                        </tr>
                                    }
                                    <tr>
                                        <th>Bill Date</th>
                                        <td>{details.billDate}</td>
                                    </tr>
                                    <tr>
                                        <th>Bill Month</th>
                                        <td>{details.billMonth}</td>
                                    </tr>
                                    <tr>
                                        <th>Status</th>
                                        <td>{details.status === "created" && <Badge bg="primary">Created</Badge>}
                                            {details.status === "approved" && <Badge bg="warning">Approved</Badge>}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="table-responsive">
                            <table className="table table-striped table-bordered" style={{ minWidth: "max-content" }}>
                                <thead>
                                    <tr>
                                        <th>SL</th>
                                        <th>Vehicle Number</th>
                                        <th>VMO</th>
                                        <th>VRO</th>
                                        <th>Bill Periods</th>
                                        <th>Monthly Rent</th>
                                        <th>Regular Bill Amount</th>
                                        <th>Deduction Amount</th>
                                        <th>Bill Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        details.bill_details && details.bill_details.map((item, index) =>
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td> {item.vehicleNumber}</td>
                                                <td>  <span style={{ whiteSpace: "nowrap" }}>{item.vmoNo}</span><br />
                                                    {item.vmoNo !== null && <span>({item.vmoReceiveDate})</span>}

                                                </td>
                                                <td> <span style={{ whiteSpace: "nowrap" }}>{item.releaseNo}</span><br />
                                                    {item.releaseNo !== null && <span>({item.vehicleReleaseDate})</span>}
                                                </td>
                                                <td> {item.billPeriodMonth !== null ? `${item.billPeriodMonth} Month` : `${item.billPeriodDay} Days`}</td>
                                                <td> {item.monthlyRent}</td>
                                                <td> {item.regularBillingAmount}</td>
                                                <td> {item.deductionAmount}</td>
                                                <td> {item.billAmount}</td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colSpan="6" className="text-end">Total</td>
                                        <td>{details.totalRegularBillAmount}</td>
                                        <td>{details.totalDeductionAmount}</td>
                                        <td>{details.totalBillAmount}</td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VmoDetails;