import { FC } from 'react'

const Error500: FC = () => {
  return (
    <>
      <h1 className='fw-bolder fs-4x text-danger mb-10'>Permission Denied</h1>
    </>
  )
}

export { Error500 }
