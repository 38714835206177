import { useEffect, useContext } from 'react';
import BackendTable from 'react-backend-table';
import { KTSVG } from '../../_metronic/helpers';
import userPermissionContext from '../../context/userPermissionContext';

function RoleDt(props) {
	const { jwt } = useContext(userPermissionContext);
	let columns = [
		{ title: 'Id', field: 'idRoles', sortable: true, searchable: true, thStyle: { textAlign: "center" }, tdStyle: { textAlign: "center" } },
		{ title: 'Role Name', field: 'roleName', sortable: true, searchable: true },
		{ title: 'Details', field: 'details', sortable: true, searchable: true },
		{
			title: 'Status',
			field: 'activeStatus',
			sortable: true,
			searchable: true,
			tdClass: "text-center",
			hasComponent: true,
			componentValue: ((rowValue) => {
				return (rowValue.activeStatus === "Active" ? <span className="badge badge-light-success fw-bolder">Active</span> : <span className="badge badge-light-danger fw-bolder">Inactive</span>)
			})
		},
		{
			title: "Action",
			field: "action",
			thStyle: { textAlign: "center" },
			tdClass: "text-center",
			tdStyle: { whiteSpace: 'nowrap' },
			sortable: false,
			searchable: false,
			hasComponent: true,
			componentValue: ((rowValue) => {
				return (<button className="btn btn-light-primary btn-sm" onClick={() => { props.onAction(rowValue.idRoles) }}>
					<KTSVG
						path='/media/svg/icons/Communication/Write.svg'
						className='svg-icon-5 svg-icon'
					/>
					Edit</button>
				)
			})
		}
	]

	let options = {
		url: process.env.REACT_APP_API_URL + 'roleList',
		authorization: 'Bearer ' + jwt.token,
		perPage: [5, 10, 30, 50],
		orderBy: 'idRoles',
		orderType: 'desc',
		columnSearch: true,
	}

	useEffect(() => {
		options.reloadMyTable();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.addNewRole, props.onCreate])
	return (
		<BackendTable columns={columns} options={options} />
	)
}

export default RoleDt;