import React, { useEffect, useState, useContext } from 'react';
import BackendTable from 'react-backend-table';
import { Link } from "react-router-dom";
import Swal from 'sweetalert2';
import { KTSVG } from '../../_metronic/helpers';
// import Error500 from '../components/Error500';
import { GetPagePermission } from "../components/UserPagePermission";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { getRequestOptions } from "../components/GetToken";
import userPermissionContext from '../../context/userPermissionContext';

function VehicleReleaseList() {
	const { jwt } = useContext(userPermissionContext);
	const [reloadMyTable, setReloadMyTable] = useState(false);
	const hasPermission = GetPagePermission("/vro-list");
	const [deletePermission, setDeletePermission] = useState("");
	let columns = [
		{ title: 'Id', field: 'idVehicleRelease', sortable: true, searchable: true, thStyle: { textAlign: "center" }, tdStyle: { textAlign: "center" } },
		{ title: 'Vehicle Release Order Number', field: 'vehicleReleaseOrderNumber', sortable: true, searchable: true },
		{ title: 'Releasing Business Unit', field: 'releasingBusinessUnit', sortable: true, searchable: true },
		{ title: 'Remarks', field: 'remarks', searchable: true, sortable: true },
		{
			title: "Action",
			field: "action",
			thStyle: { textAlign: "center" },
			tdStyle: { whiteSpace: 'nowrap' },
			sortable: false,
			searchable: false,
			hasComponent: true,
			componentValue: ((rowValue) => {
				return (
					<>
						<Link to={`/vro-vehicleReleaseDetails/${rowValue.idVehicleRelease}`}>
							<button className="btn btn-light-info btn-sm">
								<KTSVG
									path='/media/svg/icons/Text/Bullet-list.svg'
									className='svg-icon-5 svg-icon'
								/>		Details
							</button>
							<br />
						</Link>
						{/* style={{ display: `none` }} */}
						{(deletePermission !== "") &&
							<button className="btn btn-light-danger btn-sm" onClick={() => deletePage(rowValue.idVehicleRelease)}>
								<KTSVG
									path='/media/svg/icons/General/Trash.svg'
									className='svg-icon-5 svg-icon'
								/>		Delete
							</button>
						}
					</>
				)
			})
		}
	]

	useEffect(() => {
		const vehicleReleaseDeletePermission = () => {
			fetch(process.env.REACT_APP_API_URL + "vehicleReleaseDeletePermission", getRequestOptions())
				.then((resp) => {
					return resp.json()
				})
				.then((resp) => {
					setDeletePermission(resp.data.idPages);
				})
				.catch((error) => {
					console.log(error, "catch the hoop")
				});

		}

		vehicleReleaseDeletePermission();

	}, [])

	const deletePage = (idVehicleRelease) => {
		Swal.fire({
			title: 'Are You Sure?',
			text: "You Want To Delete This Vehicle Release Order!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes',
			cancelButtonText: 'No'
		}).then((result) => {
			if (result.value) {
				const requestOptions = {
					method: 'DELETE',
					headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt.token },
				};
				var apiEnd = "vehicleReleaseOrderDelete/" + idVehicleRelease;
				fetch(process.env.REACT_APP_API_URL + apiEnd, requestOptions)
					.then((resp) => {
						return resp.json()
					})
					.then((resp) => {
						if (resp.success === true) {
							Swal.fire({
								icon: 'success',
								title: 'Success',
								text: "Vehicle Release Order Deleted Successfully",
							})
							setReloadMyTable(true);
						}
						else {
							Swal.fire({
								icon: 'error',
								title: 'Error',
								html: resp.errorMessage
							})
						}
					})
					.catch((error) => {
						console.log(error, "catch the hoop")
					});
			}
		})
	}

	let options = {
		url: process.env.REACT_APP_API_URL + 'vehicleReleaseList',
		authorization: 'Bearer ' + jwt.token,
		perPage: [100, 200, 500, 1000, 'All'],
		orderBy: 'idVehicleRelease',
		orderType: 'desc',
		columnSearch: true,
	}

	useEffect(() => {
		if (reloadMyTable) {
			options.reloadMyTable();
			setReloadMyTable(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reloadMyTable])

	return (
		<>
			{hasPermission === 1 &&
				<div className='card card-custom'>
					<div className='card-header'>
						<div className='card-title'>
							<span>
								<KTSVG
									path='/media/svg/icons/Text/Bullet-list.svg'
									className='svg-icon-1 svg-icon-primary'
								/>
							</span>
							<span className='text fw-bolder' style={{ marginLeft: '10px' }}>
								VEHICLE RELEASE LIST
							</span>
						</div>
					</div>

					<div className='ml-12'>
						<BackendTable columns={columns} options={options} />
					</div>
				</div>
			}
			{hasPermission === 0 &&
				<div className='card card-custom' style={{ padding: "15px" }} >
					<SkeletonTheme>
						<p>
							<Skeleton
								containerClassName="avatar-skeleton"
								count={40} ></Skeleton>
						</p>
					</SkeletonTheme>
				</div>
			}

		</>
	)
}

export default VehicleReleaseList


