/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { getRequestOptions } from "../components/GetToken";
import VehicleExpenseList from "../components/VehicleExpenseList";
import VehicleMap from "../components/VehicleMap";
import { KTSVG } from '../../_metronic/helpers';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import Swal from 'sweetalert2';
import ReactLoading from 'react-loading';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import userPermissionContext from '../../context/userPermissionContext';
import InputGroup from 'react-bootstrap/InputGroup';

const localizer = momentLocalizer(moment);

function DetailsVehicle() {
	const { id } = useParams();
	const { jwt } = useContext(userPermissionContext);
	const [details, setDetails] = useState({});
	const [comments, setComments] = useState("");
	const [tab, setTab] = useState("vehicleInfo");
	const [imageAttachment, setImageAttachment] = useState(null);
	const [file, setFile] = useState([]);
	const [image, setImage] = useState([]);
	const [imageTitle, setImageTitle] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [transferHistory, setTransferHistory] = useState([]);
	const [reload, setReload] = useState(false);
	const tabChange = tab => {
		setTab(tab);
	}
	useEffect(() => {
		const getVehicleDetails = () => {
			fetch(process.env.REACT_APP_API_URL + "vehicleDetails/" + id, getRequestOptions())
				.then((resp) => {
					return resp.json()
				})
				.then((resp) => {
					setDetails(resp.data);
					if (resp.data.transferHistory.length > 0) {
						var tempArray = resp.data.transferHistory.map(item => {
							item.start = new Date(item.start.split("-")[0], (Number(item.start.split("-")[1]) - 1), item.start.split("-")[2]);
							item.end = new Date(item.end.split("-")[0], (Number(item.end.split("-")[1]) - 1), item.end.split("-")[2]);
							return item;
						})
					}
					setTransferHistory(tempArray);
				})
				.catch((error) => {
					console.log(error, "catch the hoop")
				});
		}
		getVehicleDetails();
	}, [id]);

	useEffect(() => {
		const getVehicleFiles = () => {
			fetch(process.env.REACT_APP_API_URL + "vehicleFiles/" + id, getRequestOptions())
				.then((resp) => {
					return resp.json()
				})
				.then((resp) => {
					setFile(resp.data.files);
					setImage(resp.data.images);
					setReload(false);
				})
				.catch((error) => {
					console.log(error, "catch the hoop")
				});
		}

		getVehicleFiles();
	}, [id, reload]);

	const onImageChange = event => {
		setImageAttachment({ ...imageAttachment, image: event.target.files[0], lastModified: event.target.files[0].lastModified, name: event.target.files[0].name, size: event.target.files[0].size, type: event.target.files[0].type });
	}

	const handleInputOnChange = (e) => {
		setImageTitle(e.target.value);
	}

	const resetAttachedUploadImage = () => {
		setImageAttachment([]);
	}

	const handleAttachedImageSubmit = evt => {
		evt.preventDefault();
		Swal.fire({
			title: 'Are you sure?',
			text: "You want to upload this attached image!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes',
			cancelButtonText: 'No'
		}).then((result) => {
			if (result.value) {
				if (imageAttachment.image) {
					const formData = new FormData();
					formData.append('attachedImage', imageAttachment.image);
					formData.append("idVmsVehicleInfo", id);
					formData.append("imageTitle", imageTitle);
					setIsLoading(true);
					axios.post(process.env.REACT_APP_API_URL + "vehicleImageUpload", formData, {
						headers: { 'Content-Type': 'multipart/form-data', 'Authorization': 'Bearer ' + jwt.token },
					})
						.then(response => {
							if (response.data.success === true) {
								Swal.fire({
									icon: 'success',
									title: 'Success',
									text: response.data.successMessage,
								})
								setImageAttachment([]);
								setIsLoading(false);
								setImageTitle("");
								setReload(true);
							}
							else {
								Swal.fire({
									icon: 'error',
									title: 'Error',
									html: response.data.errorMessage
								})
								setIsLoading(false);
							}
						})
						.catch((error) => {
							Swal.fire({
								icon: 'error',
								title: 'Error',
								html: error.response.data.errorMessage
							})
							setIsLoading(false);
						})
				}
				else {
					Swal.fire({
						icon: 'error',
						title: 'Error',
						html: 'Please attach a image before submit'
					})
					setIsLoading(false);
				}
			}
		})
	}

	const isMainChange = (idVmsVehicleImages) => {
		const formData = { idVmsVehicleImages: idVmsVehicleImages };
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt.token },
			body: JSON.stringify(formData)
		};
		fetch(process.env.REACT_APP_API_URL + "setVehicleMainImage", requestOptions)
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {
				if (resp.success === true) {
					Swal.fire({
						position: "top-right",
						icon: "success",
						title: "Main Image Changed",
						showConfirmButton: false
					});
					setReload(true);
				}
				else {
					var errorsMessage = [];

					if (resp.errorMessage !== undefined && typeof resp.errorMessage === 'object') {
						var errorsObj = resp.errorMessage;
						Object.keys(errorsObj).forEach(function (value) {
							errorsObj[value].forEach(function (v) {
								errorsMessage.push(v)
							});

						});

					} else if (resp.errorMessage !== undefined && (typeof resp.errorMessage === 'string' || resp.errorMessage instanceof String)) {
						errorsMessage.push(resp.errorMessage);
					} else {

						errorsMessage.push("Something went wrong");
					}
					Swal.fire({
						icon: 'error',
						title: resp.heading,
						text: errorsMessage,
					})
				}
			})
			.catch((error) => {
				console.log(error, "catch the hoop")
			});
	}

	const removeFile = (idVmsVehicleImages) => {
		Swal.fire({
			title: 'Are you sure?',
			text: "You want to delete this image!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes',
			cancelButtonText: 'No'
		}).then((result) => {
			if (result.value) {
				const requestOptions = {
					method: 'DELETE',
					headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt.token },
				};
				fetch(process.env.REACT_APP_API_URL + `removeVehicleImage/${idVmsVehicleImages}`, requestOptions)
					.then((resp) => {
						return resp.json()
					})
					.then((resp) => {
						if (resp.success === true) {
							Swal.fire({
								position: "top-right",
								icon: "success",
								title: "Image Deleted",
								showConfirmButton: false
							});
							setReload(true);
						}
						else {
							var errorsMessage = [];

							if (resp.errorMessage !== undefined && typeof resp.errorMessage === 'object') {
								var errorsObj = resp.errorMessage;
								Object.keys(errorsObj).forEach(function (value) {
									errorsObj[value].forEach(function (v) {
										errorsMessage.push(v)
									});

								});

							} else if (resp.errorMessage !== undefined && (typeof resp.errorMessage === 'string' || resp.errorMessage instanceof String)) {
								errorsMessage.push(resp.errorMessage);
							} else {

								errorsMessage.push("Something went wrong");
							}
							Swal.fire({
								icon: 'error',
								title: resp.heading,
								text: errorsMessage,

							})
						}
					})
					.catch((error) => {
						console.log(error, "catch the hoop")
					});
			}
		})
	}

	const addComments = () => {
		const formData = { comments: comments, idVmsVehicleInfo: id };
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt.token },
			body: JSON.stringify(formData)
		};
		fetch(process.env.REACT_APP_API_URL + "addComments", requestOptions)
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {
				if (resp.success === true) {
					setComments("");
					setReload(true);
				}
				else {
					var errorsMessage = [];

					if (resp.errorMessage !== undefined && typeof resp.errorMessage === 'object') {
						var errorsObj = resp.errorMessage;
						Object.keys(errorsObj).forEach(function (value) {
							errorsObj[value].forEach(function (v) {
								errorsMessage.push(v)
							});

						});

					} else if (resp.errorMessage !== undefined && (typeof resp.errorMessage === 'string' || resp.errorMessage instanceof String)) {
						errorsMessage.push(resp.errorMessage);
					} else {

						errorsMessage.push("Something went wrong");
					}
					Swal.fire({
						icon: 'error',
						title: resp.heading,
						text: errorsMessage,
					})
				}
			})
			.catch((error) => {
				console.log(error, "catch the hoop")
			});
	}



	function Event({ event }) {
		return (
			<span>
				<strong>{event.title}</strong>
				<br></br>
				{event.userName !== "" ? event.userName + "( " + event.designation + " )" : ""}
			</span>
		)
	}

	const filehost = process.env.REACT_APP_AWS_URL + 'vms/uploads/';
	const filePath = id + '/renewal';
	const imagePath = id + '/images';
	return (
		<>
			<div className="card card-custom">
				<div className="card-header">
					<div className="card-title">
						<KTSVG
							path='/media/svg/icons/Code/Info-circle.svg'
							className='svg-icon-1 svg-icon-primary'
						/>
						<span className="text fw-bolder" style={{ marginLeft: "10px" }}>
							VEHICLE INFORMATION
						</span>
					</div>
				</div>
				<div className="card-body">
					<ul className="nav nav-tabs nav-tabs-line">
						<li className="nav-item" onClick={() => tabChange("vehicleInfo")}>
							<a className={`nav-link ${tab === "vehicleInfo" && "active"}`} data-toggle="tab" role="tab" aria-selected={(tab === "vehicleInfo").toString()}>Basic Info</a>
						</li>
						<li className="nav-item" onClick={() => tabChange("vehicleInsurance")}>
							<a className={`nav-link ${tab === "vehicleInsurance" && "active"}`} data-toggle="tab" role="tab" aria-selected={(tab === "vehicleInsurance").toString()}>Insurance</a>
						</li>
						<li className="nav-item" onClick={() => tabChange("vehicleComments")}>
							<a className={`nav-link ${tab === "vehicleComments" && "active"}`} data-toggle="tab" role="tab" aria-selected={(tab === "vehicleComments").toString()}>Comments</a>
						</li>
						<li className="nav-item" onClick={() => tabChange("vehicleFiles")}>
							<a className={`nav-link ${tab === "vehicleFiles" && "active"}`} data-toggle="tab" role="tab" aria-selected={(tab === "vehicleFiles").toString()}>Files</a>
						</li>
						<li className="nav-item" onClick={() => tabChange("vehicleExpenseList")}>
							<a className={`nav-link ${tab === "vehicleExpenseList" && "active"}`} data-toggle="tab" role="tab" aria-selected={(tab === "vehicleExpenseList").toString()}>Expense List</a>
						</li>
						<li className="nav-item" onClick={() => tabChange("vehicleHistory")}>
							<a className={`nav-link ${tab === "vehicleHistory" && "active"}`} data-toggle="tab" role="tab" aria-selected={(tab === "vehicleHistory").toString()}>History</a>
						</li>
						{/* <li className="nav-item" onClick={() => tabChange("vehicleMap")}>
							<a className={`nav-link ${tab === "vehicleMap" && "active"}`} data-toggle="tab" role="tab" aria-selected={(tab === "vehicleMap").toString()}>Map</a>
						</li> */}
					</ul>

					{tab === "vehicleInfo" &&
						<div className="row">
							<div className="col-md-4 mt-5">
								<table className="table table-sm table-hover  table-striped table-bordered">
									<tbody>
										<tr>
											<th>Owner</th>
											<td>{details.vehicleOwner}</td>
										</tr>
										<tr>
											<th>Payable / Non Payable Item</th>
											<td>{details.usageType}</td>
										</tr>

										<tr>
											<th>Vehicle Number</th>
											<td>{details.vehicleNumber}</td>
										</tr>
										{details.vehicleOwner === "In House" &&
											<>
												<tr>
													<th>Fixed Asset Code</th>
													<td>{details.faCode}</td>
												</tr>

												<tr>
													<th>Fixed Asset Project</th>
													<td>{details.faProjectName}</td>
												</tr>
											</>
										}
										{details.vehicleOwner === "Third Party" &&
											<>
												<tr>
													<th>Vendor</th>
													<td>{details.vendorName}</td>
												</tr>
												<tr>
													<th>Category </th>
													<td>{details.categoryName}</td>
												</tr>
											</>
										}
										<tr>
											<th>Color</th>
											<td>{details.colorName}</td>
										</tr>
										<tr>
											<th>Brand</th>
											<td>{details.productName}</td>
										</tr>
										<tr>
											<th>Model</th>
											<td>{details.modelName}</td>
										</tr>
										<tr>
											<th>Manufacturer</th>
											<td>{details.manufacturer}</td>
										</tr>
										<tr>
											<th>Vehicle Type</th>
											<td>{details.vehicleType}</td>
										</tr>
										<tr>
											<th>Car Use Type</th>
											<td>{details.carUseType}</td>
										</tr>
										<tr>
											<th>Name Of Registration</th>
											<td>{details.nameOfRegistration}</td>
										</tr>
										<tr>
											<th>Chassis No</th>
											<td>{details.chassisNo}</td>
										</tr>
										<tr>
											<th>VTS No</th>
											<td>{details.vtsNo}</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div className="col-md-4 mt-5">
								<table className="table table-sm table-hover  table-striped table-bordered">
									<tbody>
										<tr>
											<th>Year Of Manufacture</th>
											<td>{details.yearOfManufacture}</td>
										</tr>
										<tr>
											<th>E Tracking Number</th>
											<td>{details.eTrackingNumber}</td>
										</tr>
										<tr>
											<th>Overhang</th>
											<td>{details.overHang}</td>
										</tr>
										<tr>
											<th>Transaction Number</th>
											<td>{details.transactionNumber}</td>
										</tr>
										<tr>
											<th>Current Meter Reading</th>
											<td>{details.currentMeterReading}</td>
										</tr>
										<tr>
											<th>Fixed Amount</th>
											<td>{details.fixedAmount}</td>
										</tr>
										<tr>
											<th>Milage</th>
											<td>{details.milage}</td>
										</tr>
										<tr>
											<th>Monthly Expense</th>
											<td>{details.monthlyExpense}</td>
										</tr>
										<tr>
											<th>Last Review Date</th>
											<td>{details.lastReviewDate}</td>
										</tr>
										<tr>
											<th>Last Policy Review On</th>
											<td>{details.lastPolicyReviewOn}</td>
										</tr>
										<tr>
											<th>Registration Date</th>
											<td>{details.registrationDate}</td>
										</tr>
										<tr>
											<th>Tax Amount</th>
											<td>{details.taxAmount}</td>
										</tr>
										<tr>
											<th>Tax Token Expiration Date</th>
											<td>{details.taxTokenExpirationDate}</td>
										</tr>
										<tr>
											<th>Fitness Expiration Date</th>
											<td>{details.fitnessExpirationDate}</td>
										</tr>
										<tr>
											<th>Route Permit Expiration Date</th>
											<td>{details.routePermitExpirationDate}</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div className="col-md-4 mt-5">
								<table className="table table-sm table-hover  table-striped table-bordered">
									<tbody>
										{details.vehicle_details &&
											<>
												<tr>
													<th>No Of Tyres</th>
													<td>{details.vehicle_details.noOfTyres}</td>
												</tr>
												<tr>
													<th>Seat Capacity</th>
													<td>{details.vehicle_details.seatCapacity}</td>
												</tr>
												<tr>
													<th>Spare Tyres</th>
													<td>{details.vehicle_details.spareTyres}</td>
												</tr>
												<tr>
													<th>Fuel Capcity</th>
													<td>{details.vehicle_details.fuelCapacity}</td>
												</tr>
												<tr>
													<th>Cylinder Capacity</th>
													<td>{details.vehicle_details.cylinderCapacity}</td>
												</tr>
												<tr>
													<th>Engine Number</th>
													<td>{details.vehicle_details.engineNumber}</td>
												</tr>
												<tr>
													<th>Laden Weight (Kg)</th>
													<td>{details.vehicle_details.ladenWeight}</td>
												</tr>
												<tr>
													<th>Engine (CC) Displacement</th>
													<td>{details.vehicle_details.engineCcDisplacement}</td>
												</tr>
											</>
										}
										<tr>
											<th>Current Project</th>
											<td>{details.currentProjectName}</td>
										</tr>
										{details.currentUserName !== null &&
											<>
												<tr>
													<th>Current User</th>
													<td>{details.currentUserName} ({details.currentUserDesignation})</td>
												</tr>
												<tr>
													<th>Current User Phone</th>
													<td>{details.currentUserPhone}</td>
												</tr>
											</>
										}
										{details.consultantOrAdvisorName !== null &&
											<tr>
												<th>Consultant Or Advisor</th>
												<td>{details.consultantOrAdvisorName}</td>
											</tr>
										}
										{details.currentDriverName !== null &&
											<tr>
												<th>Current Driver</th>
												<td>{details.currentDriverName} ({details.driverMobileNumber})</td>
											</tr>
										}
										<tr>
											<th>Created By</th>
											<td>{details.createdUser}</td>
										</tr>
										<tr>
											<th>Functional Status</th>
											<td>{details.functionalStatus}</td>
										</tr>
										<tr>
											<th>Status</th>
											<td>{details.vehicleStatus === "Active" ? <span className="badge badge-light-success fw-bolder">Active</span> : <span className="badge badge-light-danger fw-bolder">Inactive</span>}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					}
					{tab === "vehicleInsurance" &&
						<div className="col-md-8 mt-5">
							<table className="table table-sm table-hover  table-striped table-bordered">
								<tbody>
									<tr>
										<th>Insurance Type</th>
										<td>{details.vehicle_insurance.insuranceType}</td>
									</tr>
									<tr>
										<th>Insurance Company</th>
										<td>{details.vehicle_insurance.insuranceCompany}</td>
									</tr>
									<tr>
										<th>Insurance Policy Number</th>
										<td>{details.vehicle_insurance.insurancePolicyNumber}</td>
									</tr>
									<tr>
										<th>Insurance Expiration Date</th>
										<td>{details.vehicle_insurance.insuranceExpirationDate}</td>
									</tr>
									<tr>
										<th>Yearly Premium</th>
										<td>{details.vehicle_insurance.yearlyPremium}</td>
									</tr>
									<tr>
										<th>Source</th>
										<td>{details.vehicle_insurance.source}</td>
									</tr>
								</tbody>
							</table>
						</div>
					}
					{tab === "vehicleComments" &&
						<>
							<div className="col-md-8 mt-5">
								<InputGroup size='sm'>
									<Form.Control
										placeholder="Comments"
										aria-label="Comments"
										aria-describedby="basic-addon2"
										value={comments}
										onChange={(e) => setComments(e.target.value)}
									/>
									<button className="btn btn-success btn-sm" type="button" onClick={addComments} disabled={comments === ""}>Add</button>
								</InputGroup>
							</div>
							<div className='card-body pt-5'>
								<div className='timeline-label'>
									{details.vehicle_comments && details.vehicle_comments.length > 0 && details.vehicle_comments.map((item, index) => (
										<>
											<div className='timeline-item'>
												<div className='timeline-label fw-bold text-gray-800' key={index}>
													{/* {item.commentorName} */}
												</div>
												<div className='timeline-badge'>
													<i className='fa fa-genderless text-warning fs-1'></i>
												</div>
												<div className='fw-mormal timeline-content text-gray-800 ps-3'>
													{item.comments}
												</div>
											</div>
										</>
									))}

								</div>
							</div>
						</>
					}
					{tab === "vehicleHistory" &&
						<div className="mt-5" style={{ height: "650px" }}>
							<Calendar
								events={transferHistory}
								startAccessor="start"
								endAccessor="end"
								defaultDate={moment().toDate()}
								localizer={localizer}
								views={{ month: true }}
								components={{
									event: Event
								}}
								eventPropGetter={event => ({
									style: {
										backgroundColor: '#009ef7'
									},
								})}
							/>
						</div>
					}

					{tab === "vehicleExpenseList" &&
						<VehicleExpenseList idVmsVehicleInfo={id} />
					}
					{tab === "vehicleFiles" &&
						<>
							<div className='row'>
								<div className='col-md-6'>
									<form onSubmit={handleAttachedImageSubmit}>
										<div className="mt-5">
											<div className="form-group row">
												<label htmlFor="imageAttachment" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }}>Attach Image </label>
												<div className="col-lg-8">
													<Form.Control type="file" size="sm" name="imageAttachment" id="imageAttachment" onChange={onImageChange} onClick={e => (e.target.value = null)} />
												</div>
											</div>
											<div className="form-group row">
												<label htmlFor="imageTitle" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }} >Image Title</label>
												<div className="col-lg-8">
													<input type="text" name="imageTitle" id="imageTitle" value={imageTitle} className="form-control form-control-sm" onChange={handleInputOnChange} />
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-6">
											</div>
											<div className="col-6">
												<button type="submit" disabled={isLoading === true} className="btn btn-success btn-sm mb-5">Attach</button>
												<button type="reset" className="btn btn-danger btn-sm mb-5" onClick={() => resetAttachedUploadImage()}>Reset</button>
												{isLoading === true &&
													<ReactLoading type={"bubbles"} color={"#4739ea3d"} height={'15%'} width={'15%'} />
												}
											</div>
										</div>
									</form>
								</div>
							</div>
							<div className='row'>
								<div className="col-6">
									<div className='table-responsive' style={{ "overflowY": "auto" }}>
										<table className="table table-bordered table-hover mt-2">
											<thead>
												<tr>
													<td>Sl</td>
													<td>Image</td>
													<td>Name</td>
													<td>Title</td>
													<td style={{ "textAlign": "center" }}>Main Image</td>
													<td style={{ "textAlign": "center" }}>Action</td>
												</tr>
											</thead>
											{image.length > 0 ?
												<tbody>
													{image.map((item, index) =>
														<tr key={index}>
															<td>{index + 1}</td>
															<td>
																<img src={`${filehost}${imagePath}/thumb/${item.thumbnailName}`} alt="" style={{ width: "100px", height: "100px", objectFit: "cover" }} />
															</td>
															<td>{item.originalFileName}</td>
															<td>{item.title}</td>
															<td style={{ "padding": "0.75rem", "textAlign": "center" }} onClick={() => isMainChange(item.idVmsVehicleImages)}>
																{item.isMain === '1' &&
																	<KTSVG
																		path='/media/icons/duotune/arrows/arr012.svg'
																		className='svg-icon-lg svg-icon-success'
																	/>
																}
																{item.isMain === '0' &&
																	<KTSVG
																		path='/media/icons/duotune/arrows/arr011.svg'
																		className='svg-icon-lg svg-icon-danger'
																	/>
																}
															</td>
															<td style={{ "textAlign": "center" }}>
																<a href={`${filehost}${imagePath}/${item.fileName}`} target="_blank" rel="noopener noreferrer" className="btn btn-sm btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary">
																	<span >
																		<KTSVG
																			path='/media/svg/icons/Files/DownloadedFile.svg'
																			className='svg-icon svg-icon-1'
																		/>
																	</span>
																</a>
																<button className="btn btn-sm btn-outline btn-outline-dashed btn-outline-danger btn-active-light-danger" onClick={() => { removeFile(item.idVmsVehicleImages) }}>
																	<span >
																		<KTSVG
																			path='/media/svg/icons/General/Trash.svg'
																			className='svg-icon svg-icon-1'
																		/>
																	</span>
																</button>
															</td>
														</tr>
													)}
												</tbody>
												:
												<tbody>
													<tr>
														<td colSpan="6"><p style={{ "textAlign": "center" }}><strong>No Image Found</strong></p></td>
													</tr>
												</tbody>
											}
										</table>
									</div>
								</div>
								<div className="col-6">
									<div className='table-responsive' style={{ "overflowY": "auto" }}>
										<table className="table table-bordered table-hover mt-2">
											<thead>
												<tr>
													<td>Sl</td>
													<td>Name</td>
													<td>Size</td>
													<td style={{ "textAlign": "center" }}>Action</td>
												</tr>
											</thead>
											{file.length > 0 ?
												<tbody>
													{file.map((item, index) =>
														<tr key={index}>
															<td>{index + 1}</td>
															<td>{item.originalFileName}</td>
															<td>{item.fileSize}</td>
															<td style={{ "textAlign": "center" }}>
																<a href={`${filehost}${filePath}/${item.fileName}`} target="_blank" rel="noopener noreferrer" className="btn btn-sm btn-outline-primary mr-3"><i className="flaticon2-download-1"></i> Download</a>
															</td>
														</tr>
													)}
												</tbody>
												:
												<tbody>
													<tr>
														<td colSpan="4"><p style={{ "textAlign": "center" }}><strong>No File Found</strong></p></td>
													</tr>
												</tbody>
											}
										</table>
									</div>
								</div>
							</div>
						</>
					}
					{tab === "vehicleMap" &&
						<VehicleMap />
					}
				</div>
				{/* <pre>{JSON.stringify(transferHistory,null,2)}</pre> */}
			</div>
		</>
	)
}

export default DetailsVehicle;