import { useContext, useState } from 'react';
import BackendTable from 'react-backend-table';
import { KTSVG } from '../../_metronic/helpers';
import { GetPagePermission } from "../components/UserPagePermission";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import userPermissionContext from '../../context/userPermissionContext';
import { Modal, Button } from "react-bootstrap";
import RateChange from '../components/RateChange';
import { Link } from "react-router-dom";

function VehicleRateSetup() {
	const { jwt } = useContext(userPermissionContext);
	const hasPermission = GetPagePermission("/setup-vehicle-rate");
	const [rateModalShow, setRateModalShow] = useState(false);
	const [idVmsVehicleInfo, setIdVmsVehicleInfo] = useState('');
	let columns = [
		{ title: 'Id', field: 'idVmsVehicleInfo', sortable: true, searchable: true, thStyle: { textAlign: "center" }, tdStyle: { textAlign: "center" } },
		{
			title: 'Vehicle Number', field: 'vehicleNumber', sortable: true, searchable: true,
			hasComponent: true,
			componentValue: ((rowValue) => {
				return (
					<Link to={`/vehicleDetails/${rowValue.idVmsVehicleInfo}`} target="_blank" rel="noopener noreferrer">
						{rowValue.vehicleNumber}
					</Link>
				)

			})
		}, { title: 'Vehicle Owner', field: 'vehicleOwner', sortable: true, searchable: true },
		{ title: 'FIxed Asset', field: 'faCode', sortable: true, searchable: true },
		{ title: 'Vendor', field: 'vendorName', searchable: true, sortable: true },
		{ title: 'Vehicle Type', field: 'vehicleType', searchable: true, sortable: true },
		{ title: 'Rate', field: 'rate', searchable: true, sortable: true, thStyle: { textAlign: "center" }, tdStyle: { textAlign: "right" } },
		{
			title: "Action",
			field: "action",
			thStyle: { textAlign: "center" },
			tdStyle: { whiteSpace: 'nowrap' },
			sortable: false,
			searchable: false,
			hasComponent: true,
			componentValue: ((rowValue) => {
				return (
					<>
						<Button onClick={() => confirmRate(rowValue.idVmsVehicleInfo)} variant="primary" size="sm">Change Rate</Button>
					</>
				)
			})
		}
	]

	const confirmRate = (idVmsVehicleInfo) => {
		setRateModalShow(true);
		setIdVmsVehicleInfo(idVmsVehicleInfo);
	}

	const onRateConfirm = () => {
		setRateModalShow(false);
		options.reloadMyTable();
	}

	let options = {
		url: process.env.REACT_APP_API_URL + 'vehicleRateList',
		authorization: 'Bearer ' + jwt.token,
		perPage: [50, 100, 500, 1000, 'All'],
		orderBy: 'idVmsVehicleInfo',
		orderType: 'desc',
		columnSearch: true,
	}

	let rateModalClose = () => {
		setRateModalShow(false);
	};

	return (
		<>
			{hasPermission === 1 &&
				<>
					<div className='card card-custom'>
						<div className='card-header'>
							<div className='card-title'>
								<span>
									<KTSVG
										path='/media/svg/icons/Text/Bullet-list.svg'
										className='svg-icon-1 svg-icon-primary'
									/>
								</span>
								<span className='text fw-bolder' style={{ marginLeft: '10px' }}>
									VEHICLE RATE SETUP
								</span>
							</div>
						</div>

						<div className='ml-12'>
							<BackendTable columns={columns} options={options} />
						</div>
					</div>
					<Modal size="lg" show={rateModalShow} onHide={rateModalClose} aria-labelledby="example-modal-sizes-title-lg">
						<Modal.Body>
							<RateChange onRateConfirm={onRateConfirm} idVmsVehicleInfo={idVmsVehicleInfo} />
						</Modal.Body>

						<Modal.Footer>
							<Button variant="outline-primary" size="sm" onClick={rateModalClose}>Ok</Button>
							<Button variant="outline-secondary" size="sm" onClick={rateModalClose}>Cancel</Button>
						</Modal.Footer>
					</Modal>
				</>
			}
			{hasPermission === 0 &&
				<div className='card card-custom' style={{ padding: "15px" }} >
					<SkeletonTheme>
						<p>
							<Skeleton
								containerClassName="avatar-skeleton"
								count={40} ></Skeleton>
						</p>
					</SkeletonTheme>
				</div>
			}

		</>
	)
}

export default VehicleRateSetup;