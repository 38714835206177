/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import { ToastContainer, toast } from 'react-toastify';
import AsyncSelect from 'react-select/async';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import { getRequestOptions } from "../components/GetToken";
import { KTSVG } from '../../_metronic/helpers';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';
import CostCenterAssign from '../components/CostCenterAssign';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import { GetPagePermission } from "../components/UserPagePermission";
import { useHistory } from "react-router-dom";
// import Error500 from "../components/Error500";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import ReactLoading from 'react-loading';
import userPermissionContext from '../../context/userPermissionContext';

function InvoiceBillCreate() {
	const { jwt } = useContext(userPermissionContext);
	let history = useHistory();
	const customStylesSelect = {
		control: (provided) => ({
			...provided,
			height: 'calc(1.35em + 1.1rem + 2px)',
			minHeight: '35px'
		}),
		valueContainer: (provided) => ({
			...provided,
		})
	};
	const hasPermission = GetPagePermission("/expenses-invoiceBillCreate");
	const initialState = () => ({
		vehicleInfo: "",
		vehicleInfoId: "",
		driver: [],
		project: [],
		invoice: [],
		invoiceId: "",
		invoiceBillAmount: 0,
		driverId: "",
		projectId: "",
		comments: "",
		vendor: [],
		vendorId: "",
		invoiceNo: "",
		vendorBillDate: "",
		approver: [],
		approverId: "",
		ap: [],
		apId: "",
		projectAuthorityDate: "",
		totalBillAmount: 0,
		employeeId: "",
		employeeName: "",
		idVmsVehicleType: ""
	})

	const tempBillExpenseDetailsInitialState = () => ({
		expenseHead: "",
		expenseType: "",
		expenseTypeId: "",
		ledgerName: "",
		ledgerId: "",
		costCenterName: "",
		costCenterId: "",
		expanded: [],
		expenseDate: "",
		expenseDateFrom: "",
		expenseDateTo: "",
		expenseAmount: "",
		hours: "",
		quantity: "",
		rate: "",
		currentMeterReading: "",
		previousMeterReading: "",
		distance: "",
		itemName: "",
		insuranceType: "",
		purpose: "",
		place: "",
		lastDateOfChanges: "",
		vehicleCondition: "",
		purchaseFrom: "",
		remarks: "",
		fileAttachment: null
	})
	const [tempBillExpenseDetails, setBillTempExpenseDetails] = useState(tempBillExpenseDetailsInitialState());
	const [mainBillExpenseDetails, setMainBillExpenseDetails] = useState([]);
	const [expenseHeads, setExpenseHeads] = useState([]);
	const [invoiceBillInfo, setInvoiceBillInfo] = useState(initialState());
	const [expenseTypes, setExpenseTypes] = useState([]);
	const [assignCostCenterModalShow, setAssignCostCenterModalShow] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	var dataStatus = true;
	// const [costCenterShowHide, setCostCenterShowHide] = useState(true);

	useEffect(() => {
		fetch(process.env.REACT_APP_API_URL + "getAllExpenseHeads", getRequestOptions())
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {
				setExpenseHeads(resp.data);
			})
			.catch((error) => {
				console.log(error, "catch the hoop")
			});
	}, [])

	useEffect(() => {
		if (tempBillExpenseDetails.expenseHead !== "") {
			fetch(process.env.REACT_APP_API_URL + "getExpenseTypesByHead/" + tempBillExpenseDetails.expenseHead.value, getRequestOptions())
				.then((resp) => {
					return resp.json()
				})
				.then((resp) => {
					setExpenseTypes(resp.data);
				})
				.catch((error) => {
					console.log(error, "catch the hoop")
				});
		}
	}, [tempBillExpenseDetails.expenseHead])

	useEffect(() => {
		distanceChange();
	}, [tempBillExpenseDetails.currentMeterReading, tempBillExpenseDetails.previousMeterReading])

	useEffect(() => {
		if (tempBillExpenseDetails.expenseTypeId === 2 || tempBillExpenseDetails.expenseTypeId === 5 || tempBillExpenseDetails.expenseTypeId === 17 || tempBillExpenseDetails.expenseTypeId === 22 ||
			tempBillExpenseDetails.expenseTypeId === 32 || tempBillExpenseDetails.expenseTypeId === 49) {
			rateOrQuantityChange();
		}

	}, [tempBillExpenseDetails.rate, tempBillExpenseDetails.quantity])

	useEffect(() => {
		setInvoiceBillInfo({ ...invoiceBillInfo, totalBillAmount: objPsum(mainBillExpenseDetails, 'expenseAmount') });
	}, [mainBillExpenseDetails])

	const objPsum = (items, prop) => {
		return items.reduce(function (a, b) {
			return Number(a) + Number(b[prop]);
		}, 0);
	}


	const searchVehicles = (inputValue, callback) => {

		if (!inputValue) {
			callback([]);

		}
		else {
			var url = process.env.REACT_APP_API_URL + "vehicleSearchThirdParty?q=" + inputValue;

			setTimeout(() => {
				fetch(url,
					getRequestOptions())

					.then((resp) => {
						return resp.json()
					})
					.then((resp) => {
						const tempArray = resp.data.map(function (element) {
							element.label = element.vehicleNumber;
							element.value = element.vehicleInfoId;
							return element;
						});
						callback(tempArray);
					})
					.catch((error) => {
						console.log(error, "catch the hoop")
					});
			});
		}
	}

	const searchUsers = (inputValue, callback) => {

		if (!inputValue) {
			callback([]);

		}
		else {
			var url = process.env.REACT_APP_API_URL + "userSearch?q=" + inputValue;

			setTimeout(() => {
				fetch(url,
					getRequestOptions())

					.then((resp) => {
						return resp.json()
					})
					.then((resp) => {
						const tempArray = resp.data.map(function (element) {
							element.label = element.fullName;
							element.value = element.idUsers;
							return element;
						});
						callback(tempArray);
					})
					.catch((error) => {
						console.log(error, "catch the hoop")
					});
			});
		}
	}

	const searchInvoice = (inputValue, callback) => {

		if (!inputValue) {
			callback([]);
		}
		else if (!invoiceBillInfo.vehicleInfoId) {
			callback([]);
			Swal.fire({
				title: 'Warning!',
				text: "Select Vehicle first!",
				icon: 'warning',
				showCancelButton: false,
				confirmButtonText: 'Ok',
				cancelButtonText: 'No'
			});
		}
		else {
			var url = process.env.REACT_APP_API_URL + "searchInvoice?q=" + inputValue + "&r=" + invoiceBillInfo.vehicleInfoId;

			setTimeout(() => {
				fetch(url,
					getRequestOptions())

					.then((resp) => {
						return resp.json()
					})
					.then((resp) => {
						const tempArray = resp.data.map(function (element) {
							element.label = "Invoice: " + element.invoiceNo;
							element.value = element.invoiceId;
							return element;
						});
						callback(tempArray);
					})
					.catch((error) => {
						console.log(error, "catch the hoop")
					});
			});
		}
	}

	const handleVehicleChange = vehicleDetails => {
		setInvoiceBillInfo({
			...invoiceBillInfo, vehicleInfoId: vehicleDetails.vehicleInfoId,
			projectId: vehicleDetails.currentProject,
			vendorId: vehicleDetails.vendorId,
			driverId: vehicleDetails.driverId,
			vehicleInfo: { label: vehicleDetails.vehicleNumber, value: vehicleDetails.vehicleInfoId },
			project: vehicleDetails.currentProjectName,
			vendor: !!(vehicleDetails.vendorName) ? vehicleDetails.vendorName : "",
			driver: vehicleDetails.driverName,
			employeeId: vehicleDetails.currentUser,
			employeeName: vehicleDetails.currentUserName,
			idVmsVehicleType: vehicleDetails.idVmsVehicleType
		});
		setMainBillExpenseDetails([]);
		// projectAccountingBudgetModuleCheck(vehicleDetails.currentProject);
	}

	const handleInvoiceChange = invoiceDetails => {
		setInvoiceBillInfo({
			...invoiceBillInfo, invoiceId: invoiceDetails.invoiceId,
			invoice: { label: "Sl: " + invoiceDetails.invoiceId, value: invoiceDetails.invoiceId },
			invoiceNo: invoiceDetails.invoiceNo,
			vendorBillDate: invoiceDetails.invoiceDate,
			invoiceBillAmount: Number(invoiceDetails.invoiceAmount),
		});
	}

	const handleApproverChange = userDetails => {
		setInvoiceBillInfo({
			...invoiceBillInfo, approverId: userDetails.idUsers,
			approver: { label: userDetails.fullName, value: userDetails.idUsers },
		});
	}

	const handleApChange = userDetails => {
		setInvoiceBillInfo({
			...invoiceBillInfo, apId: userDetails.idUsers,
			ap: { label: userDetails.fullName, value: userDetails.idUsers },
		});
	}

	const handleExpenseHeadChange = expenseHead => {
		setBillTempExpenseDetails({
			...tempBillExpenseDetails,
			expenseHead: { label: expenseHead.label, value: expenseHead.value },
			expenseType: "",
			expenseTypeId: "",
			ledgerName: "",
			ledgerId: "",
		});
	}


	const handleExpenseTypeChange = expense => {
		setBillTempExpenseDetails({
			...tempBillExpenseDetails,
			expenseTypeId: expense.expenseTypeId,
			expenseType: { label: expense.label, value: expense.value },
			ledgerName: expense.ledgerName !== null ? expense.ledgerName : "",
			ledgerId: expense.ledgerId !== null ? expense.ledgerId : ""
		});
	}

	const handleExpenseBillCommonInfoChange = evt => {
		if (evt.target.name === "projectAuthorityDate") {
			const date = new Date().toISOString().slice(0, 10);
			if (date < evt.target.value) {
				toast.error("Project authority date cannot be greater than current date");
			}
			else {
				setInvoiceBillInfo({ ...invoiceBillInfo, [evt.target.name]: evt.target.value });
			}
		}
		else {
			setInvoiceBillInfo({ ...invoiceBillInfo, [evt.target.name]: evt.target.value });
		}
	}

	const handleInvoiceBillInfoChange = evt => {
		if (evt.target.name === "expenseDate") {
			const date = new Date().toISOString().slice(0, 10);
			if (date < evt.target.value) {
				toast.error("Expense Date cannot be greater than current date");
			}
			else {
				setBillTempExpenseDetails({ ...tempBillExpenseDetails, [evt.target.name]: evt.target.value });
			}
		}
		else {
			setBillTempExpenseDetails({ ...tempBillExpenseDetails, [evt.target.name]: evt.target.value });
		}

	}

	const distanceChange = () => {
		setBillTempExpenseDetails({ ...tempBillExpenseDetails, distance: (Number(tempBillExpenseDetails.currentMeterReading) - Number(tempBillExpenseDetails.previousMeterReading)) })
	}

	const rateOrQuantityChange = () => {
		setBillTempExpenseDetails({ ...tempBillExpenseDetails, expenseAmount: (Number(tempBillExpenseDetails.rate) * Number(tempBillExpenseDetails.quantity)) })
	}

	// const projectAccountingBudgetModuleCheck = (projectId) => {
	// 	if (projectId !== null) {
	// 		fetch(process.env.REACT_APP_API_URL + "projectBudgetModuleConnectionCheck/" + projectId, getRequestOptions())
	// 			.then((resp) => {
	// 				return resp.json()
	// 			})
	// 			.then((resp) => {
	// 				if (resp.data.budgetSetup === 0) {
	// 					setCostCenterShowHide(false);
	// 				}
	// 				else {
	// 					setCostCenterShowHide(true);
	// 				}
	// 			})
	// 			.catch((error) => {
	// 				console.log(error, "catch the hoop")
	// 			});
	// 	}
	// }

	const addExpenseData = (invoiceBillInfoData) => {

		if (invoiceBillInfoData.expenseTypeId !== "") {
			if (invoiceBillInfoData.expenseDate === "" || invoiceBillInfoData.expenseAmount === "") {
				dataStatus = false;
			}
			// if (costCenterShowHide === true) {
			// 	if (invoiceBillInfoData.costCenterId === "") {
			// 		dataStatus = false;
			// 	}
			// }
			if (invoiceBillInfoData.expenseTypeId === 2 || invoiceBillInfoData.expenseTypeId === 5 || invoiceBillInfoData.expenseTypeId === 17 || invoiceBillInfoData.expenseTypeId === 22 || invoiceBillInfoData.expenseTypeId === 31 || invoiceBillInfoData.expenseTypeId === 32 || invoiceBillInfoData.expenseTypeId === 49) {
				if (invoiceBillInfo.quantity === "") {
					dataStatus = false;
				}
			}
			if (invoiceBillInfoData.expenseTypeId === 2 || invoiceBillInfoData.expenseTypeId === 5 || invoiceBillInfoData.expenseTypeId === 7 || invoiceBillInfoData.expenseTypeId === 12 || invoiceBillInfoData.expenseTypeId === 16 || invoiceBillInfoData.expenseTypeId === 17 || invoiceBillInfoData.expenseTypeId === 19 || invoiceBillInfoData.expenseTypeId === 22 || invoiceBillInfoData.expenseTypeId === 32 || invoiceBillInfoData.expenseTypeId === 49) {
				if (invoiceBillInfoData.rate === "") {
					dataStatus = false;
				}
			}
			if (invoiceBillInfoData.expenseTypeId === 2 || invoiceBillInfoData.expenseTypeId === 5 || invoiceBillInfoData.expenseTypeId === 12 || invoiceBillInfoData.expenseTypeId === 17 || invoiceBillInfoData.expenseTypeId === 22 || invoiceBillInfoData.expenseTypeId === 25 || invoiceBillInfoData.expenseTypeId === 49) {
				if (invoiceBillInfoData.currentMeterReading === "" || invoiceBillInfoData.previousMeterReading === "" || invoiceBillInfoData.distance === "") {
					dataStatus = false;
				}
			}
			if (invoiceBillInfoData.expenseTypeId === 3) {
				if (invoiceBillInfoData.itemName === "") {
					dataStatus = false;
				}
			}
			if (invoiceBillInfoData.expenseTypeId === 11) {
				if (invoiceBillInfoData.insuranceType === "") {
					dataStatus = false;
				}
			}
			if (invoiceBillInfoData.expenseTypeId === 13 || invoiceBillInfoData.expenseTypeId === 18 || invoiceBillInfoData.expenseTypeId === 23 || invoiceBillInfoData.expenseTypeId === 24 || invoiceBillInfoData.expenseTypeId === 25 || invoiceBillInfoData.expenseTypeId === 26 || invoiceBillInfoData.expenseTypeId === 27) {
				if (invoiceBillInfoData.purpose === "") {
					dataStatus = false;
				}
			}
			if (invoiceBillInfoData.expenseTypeId === 21 || invoiceBillInfoData.expenseTypeId === 30) {
				if (invoiceBillInfoData.place === "") {
					dataStatus = false;
				}
			}
			if (invoiceBillInfoData.expenseTypeId === 33) {
				if (invoiceBillInfoData.vehicleCondition === "" || invoiceBillInfoData.purchaseFrom === "") {
					dataStatus = false;
				}
			}
			if (invoiceBillInfoData.expenseTypeId === 31 || invoiceBillInfoData.expenseTypeId === 32) {
				if (invoiceBillInfoData.remarks === "") {
					dataStatus = false;
				}
			}

			if (invoiceBillInfoData.expenseTypeId === 19) {
				if (invoiceBillInfoData.hours === "") {
					dataStatus = false;
				}
			}
		}
		else {
			dataStatus = false;
		}
		if (dataStatus === true) {
			setMainBillExpenseDetails(mainBillExpenseDetails => [...mainBillExpenseDetails, invoiceBillInfoData]);
			setBillTempExpenseDetails(tempBillExpenseDetailsInitialState());
		}
		else {
			Swal.fire({
				title: 'Warning!',
				text: "Required Field Missing!",
				icon: 'warning',
				showCancelButton: false,
				confirmButtonText: 'Ok',
				cancelButtonText: 'No'
			});
		}
	}

	// const assignCostCenter = () => {
	// 	if (invoiceBillInfo.projectId === "") {
	// 		setAssignCostCenterModalShow(false);
	// 		Swal.fire({
	// 			title: 'Warning!',
	// 			text: "Select Vehicle first!",
	// 			icon: 'warning',
	// 			showCancelButton: false,
	// 			confirmButtonText: 'Ok',
	// 			cancelButtonText: 'No'
	// 		});
	// 	}
	// 	else {
	// 		setAssignCostCenterModalShow(true);
	// 	}
	// }
	const assignCostCenterModalClose = () => {
		setAssignCostCenterModalShow(false);
	}
	const onAssignCostCenterConfirm = (costCenterId, costCenterName, expanded) => {
		setBillTempExpenseDetails({ ...tempBillExpenseDetails, costCenterId: costCenterId, costCenterName: costCenterName, expanded: expanded });
	}

	const onFileChange = event => {
		setBillTempExpenseDetails({ ...tempBillExpenseDetails, fileAttachment: { file: event.target.files[0], lastModified: event.target.files[0].lastModified, name: event.target.files[0].name, size: event.target.files[0].size, type: event.target.files[0].type } });
	}

	const removeExpenseDetails = (index) => () => {
		setMainBillExpenseDetails(mainBillExpenseDetails => mainBillExpenseDetails.filter((row, rindex) => index !== rindex));
	}

	const editExpenseDetails = (index) => () => {
		setBillTempExpenseDetails(mainBillExpenseDetails[index]);
		setMainBillExpenseDetails(mainBillExpenseDetails => mainBillExpenseDetails.filter((row, rindex) => index !== rindex));
	}

	const handleSubmit = evt => {
		evt.preventDefault();
		Swal.fire({
			title: 'Are you sure?',
			text: "You want to create this Invoice Bill!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes',
			cancelButtonText: 'No'
		}).then((result) => {
			if (result.value) {
				const formData = new FormData();
				// setMainBillExpenseDetails(mainBillExpenseDetails => [...mainBillExpenseDetails.reverse()]);
				for (var i = 0; i < mainBillExpenseDetails.length; i++) {
					if (mainBillExpenseDetails[i].fileAttachment !== null) {
						formData.append('myFile[]', mainBillExpenseDetails[i].fileAttachment.file);
					}
					// else {
					// 	formData.append('myFile[]', null);
					// }
				}
				formData.append("invoiceBillInfo", JSON.stringify(invoiceBillInfo));
				formData.append("invoiceExpenseBillDetails", JSON.stringify(mainBillExpenseDetails));

				setIsLoading(true);
				axios.post(process.env.REACT_APP_API_URL + "invoiceBillCreate", formData, {
					headers: { 'Content-Type': 'multipart/form-data', 'Authorization': 'Bearer ' + jwt.token },
				})
					.then(response => {
						if (response.data.success === true) {
							Swal.fire({
								icon: 'success',
								title: 'Success',
								text: response.data.successMessage,
							})
							resetExpenseData();
							setIsLoading(false);
							history.push('/expenses-invoiceBillDetails/' + response.data.data.id);
						}
						else {
							Swal.fire({
								icon: 'error',
								title: 'Error',
								html: response.data.errorMessage
							})
							setIsLoading(false);
						}
					})
					.catch((error) => {
						// setMainBillExpenseDetails(mainBillExpenseDetails => [...mainBillExpenseDetails.reverse()]);
						Swal.fire({
							icon: 'error',
							title: 'Error',
							html: error.response.data.errorMessage
						})
						setIsLoading(false);
					})
			}
		})
	}

	const resetExpenseData = () => {
		setInvoiceBillInfo(initialState());
		setMainBillExpenseDetails([]);
		setBillTempExpenseDetails(tempBillExpenseDetailsInitialState());
	}

	const insuranceTypes = [
		{ label: 'Select Insurance Type', value: '' },
		{ label: '1st Party', value: '1st Party' },
		{ label: '3rd Party', value: '3rd Party' }
	]

	const vehicleConditions = [
		{ label: 'Select Vehicle Conditions', value: '' },
		{ label: 'Brand New', value: 'Brand New' },
		{ label: 'Recondition', value: 'Recondition' },
		{ label: 'Second Hand', value: 'Second Hand' }
	]

	return (
		<>
			{hasPermission === 1 &&
				<div className="card card-custom">
					<div className="card-header">
						<div className="card-title">
							<KTSVG
								path='/media/svg/icons/Communication/Clipboard-list.svg'
								className='svg-icon-1 svg-icon-primary'
							/>
							<span className="text fw-bolder" style={{ marginLeft: "10px" }}>
								CREATE INVOICE BILL
							</span>
						</div>
					</div>

					<div className="ml-12">
						<form onSubmit={handleSubmit}>
							<div className="card-body">
								<div className="ml-12">
									<div className="row">
										<div className="col-md-6">
											<div className="form-group row">
												<label htmlFor="example-text-input" style={{ "textAlign": "right" }} className="col-lg-4 col-form-label" >Search Vehicle<span className="required text-danger"></span></label>
												<div className="col-lg-8">
													<AsyncSelect
														value={invoiceBillInfo.vehicleInfo}
														defaultOptions
														loadOptions={searchVehicles}
														placeholder="Select Vehicle"
														onChange={handleVehicleChange}
														styles={customStylesSelect}
													/>
												</div>
											</div>
											<div className="form-group row">
												<label htmlFor="projectId" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }} > Current Project<span className="required text-danger"></span></label>
												<div className="col-lg-8">
													<input type="text" name="project" id="project" value={invoiceBillInfo.project} readOnly placeholder="Project" className="form-control form-control-sm" />
												</div>
											</div>
											<div className="form-group row">
												<label htmlFor="driverId" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }} >Current Driver{invoiceBillInfo.idVmsVehicleType !== 14 && <span className="required text-danger"></span>}</label>
												<div className="col-lg-8">
													<input type="text" name="driver" id="driver" value={invoiceBillInfo.driver} readOnly placeholder="Driver" className="form-control form-control-sm" />
												</div>
											</div>
											<div className="form-group row">
												<label htmlFor="employeeName" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }} >Current User{invoiceBillInfo.idVmsVehicleType === 14 && <span className="required text-danger"></span>}</label>
												<div className="col-lg-8">
													<input type="text" name="employeeName" id="employeeName" value={invoiceBillInfo.employeeName} readOnly placeholder="User" className="form-control form-control-sm" />
												</div>
											</div>
											<div className="form-group row">
												<label htmlFor="vendor" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }} >Vendor<span className="required text-danger"></span></label>
												<div className="col-lg-8">
													<input type="text" name="vendor" id="vendor" value={invoiceBillInfo.vendor} readOnly placeholder="Vendor" className="form-control form-control-sm" />
												</div>
											</div>
											<div className="form-group row">
												<label htmlFor="invoice" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }} >Select from Invoice Register </label>
												<div className="col-lg-8">
													<AsyncSelect
														value={invoiceBillInfo.invoice}
														defaultOptions
														loadOptions={searchInvoice}
														placeholder="Search Invoice"
														onChange={handleInvoiceChange}
														styles={customStylesSelect}
													/>
												</div>
											</div>
											<div className="form-group row">
												<label htmlFor="invoiceBillAmount" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }} >Invoice Bill Amount</label>
												<div className="col-lg-8">
													<input type="number" name="invoiceBillAmount" id="invoiceBillAmount" value={invoiceBillInfo.invoiceBillAmount} onChange={handleExpenseBillCommonInfoChange} placeholder="Invoice Bill Amount" className="form-control form-control-sm" readOnly />
												</div>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group row">
												<label htmlFor="invoiceNo" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }} >Invoice No <span className="required text-danger"></span></label>
												<div className="col-lg-8">
													<input type="text" name="invoiceNo" id="invoiceNo" value={invoiceBillInfo.invoiceNo} onChange={handleExpenseBillCommonInfoChange} placeholder="Vendor Bill No" className="form-control form-control-sm" />
												</div>
											</div>
											<div className="form-group row">
												<label htmlFor="vendorBillDate" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }} >Bill Date <span className="required text-danger"></span></label>
												<div className="col-lg-8">
													<input type="date" name="vendorBillDate" id="vendorBillDate" value={invoiceBillInfo.vendorBillDate} onChange={handleExpenseBillCommonInfoChange} placeholder="Vendor Bill Date" className="form-control form-control-sm" />
												</div>
											</div>
											<div className="form-group row">
												<label htmlFor="approver" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }} >Select Approver<span className="required text-danger"></span></label>
												<div className="col-lg-8">
													<AsyncSelect
														value={invoiceBillInfo.approver}
														defaultOptions
														loadOptions={searchUsers}
														placeholder="Select Approver"
														onChange={handleApproverChange}
														styles={customStylesSelect}
													/>
												</div>
											</div>
											<div className="form-group row">
												<label htmlFor="ap" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }} >Select AP<span className="required text-danger"></span></label>
												<div className="col-lg-8">
													<AsyncSelect
														value={invoiceBillInfo.ap}
														defaultOptions
														loadOptions={searchUsers}
														placeholder="Select AP"
														onChange={handleApChange}
														styles={customStylesSelect}
													/>
												</div>
											</div>
											<div className="form-group row">
												<label htmlFor="projectAuthorityDate" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }} >Project Authority Date </label>
												<div className="col-lg-8">
													<input type="date" name="projectAuthorityDate" id="projectAuthorityDate" value={invoiceBillInfo.projectAuthorityDate} onChange={handleExpenseBillCommonInfoChange} placeholder="Select Project Authority Date" className="form-control form-control-sm" />
												</div>
											</div>
											<div className="form-group row">
												<label htmlFor="totalBillAmount" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }}>Total Bill Amount <span className="required text-danger"></span></label>
												<div className="col-lg-8">
													<input type="text" name="totalBillAmount" id="totalBillAmount" value={invoiceBillInfo.totalBillAmount} readOnly placeholder="Total Bill Amount" className="form-control form-control-sm" />
												</div>
											</div>
											<div className="form-group row">
												<label htmlFor="comments" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }}>Comments </label>
												<div className="col-lg-8">
													<input type="text" name="comments" id="comments" value={invoiceBillInfo.comments} onChange={handleExpenseBillCommonInfoChange} placeholder="Enter Comments" className="form-control form-control-sm" />
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="card-header">
									<div className="card-title">
										<KTSVG
											path='/media/svg/icons/Communication/Chat-check.svg'
											className='svg-icon-3 svg-icon-primary'
										/>
										<span className="text-primary" style={{ marginLeft: "5px" }}>
											Expenses Details
										</span>
									</div>
								</div>
								<div className="row">
									<div className="col-md-6">
										<div className="form-group row mt-5">
											<label htmlFor="expenseHead" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }} >Expense Head<span className="required text-danger"></span></label>
											<div className="col-lg-8">
												<Select
													value={tempBillExpenseDetails.expenseHead}
													options={expenseHeads}
													onChange={handleExpenseHeadChange}
													styles={customStylesSelect}
												/>
											</div>
										</div>
										<div className="form-group row">
											<label htmlFor="expenseType" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }} >Expense Type<span className="required text-danger"></span></label>
											<div className="col-lg-8">
												<Select
													value={tempBillExpenseDetails.expenseType}
													options={expenseTypes}
													onChange={handleExpenseTypeChange}
													styles={customStylesSelect}
													isDisabled={tempBillExpenseDetails.expenseHead === "" ? true : false}
												/>
											</div>
										</div>
										<div className="form-group row">
											<label htmlFor="ledgerName" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }}>Ledger Name <span className="required text-danger"></span></label>
											<div className="col-lg-8">
												<input type="text" name="ledgerName" id="ledgerName" value={tempBillExpenseDetails.ledgerName} readOnly placeholder="Ledger" className="form-control form-control-sm" />
											</div>
										</div>
										{/* {costCenterShowHide === true &&
											<div className="form-group row">
												<label htmlFor="costCenter" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }}>Cost Center <span className="required text-danger"></span></label>
												<div className="col-lg-6">
													<input type="text" name="costCenter" id="costCenter" value={tempBillExpenseDetails.costCenterName} readOnly placeholder="Assign Cost Center" className="form-control form-control-sm" />
												</div>
												<div className="col-lg-2">
													<button type="button" className="btn btn-light btn-sm btn-active-light-primary" style={{ "marginLeft": "-30px" }} onClick={() => assignCostCenter()}>
														<KTSVG
															path='/media/svg/icons/Communication/Write.svg'
															className='svg-icon-5 svg-icon'
														/>
													</button>
												</div>
											</div>
										} */}

										<div className="form-group row">
											<label htmlFor="expenseDate" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }} >Expense Date<span className="required text-danger"></span></label>
											<div className="col-lg-8">
												<input type="date" name="expenseDate" id="expenseDate" value={tempBillExpenseDetails.expenseDate} onChange={handleInvoiceBillInfoChange} placeholder="Select Expense Date" className="form-control form-control-sm" />
											</div>
										</div>
										<div className="form-group row">
											<label htmlFor="expenseAmount" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }}>Expense Amount <span className="required text-danger"></span></label>
											<div className="col-lg-8">
												<input type="number" name="expenseAmount" id="expenseAmount" value={tempBillExpenseDetails.expenseAmount} onChange={handleInvoiceBillInfoChange} placeholder="Enter Expense Amount" className="form-control form-control-sm" />
											</div>
										</div>
										<div className="form-group row">
											<label htmlFor="fileAttachment" className="col-lg-4 col-form-label mt-2" style={{ "textAlign": "right" }}>Attach File </label>
											<div className="col-lg-8  col-form-label">
												<Form.Control type="file" size="sm" name="fileAttachment" id="fileAttachment" onChange={onFileChange} onClick={e => (e.target.value = null)} />
											</div>
										</div>
									</div>
									<div className="col-md-6 mt-5">
										{(tempBillExpenseDetails.expenseTypeId === 1 || tempBillExpenseDetails.expenseTypeId === 7 || tempBillExpenseDetails.expenseTypeId === 8 || tempBillExpenseDetails.expenseTypeId === 9
											|| tempBillExpenseDetails.expenseTypeId === 10 || tempBillExpenseDetails.expenseTypeId === 11 || tempBillExpenseDetails.expenseTypeId === 12 || tempBillExpenseDetails.expenseTypeId === 14
											|| tempBillExpenseDetails.expenseTypeId === 16 || tempBillExpenseDetails.expenseTypeId === 19 || tempBillExpenseDetails.expenseTypeId === 28 || tempBillExpenseDetails.expenseTypeId === 29) &&
											<>
												<div className="form-group row">
													<label htmlFor="expenseDateFrom" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }} >Date From</label>
													<div className="col-lg-8">
														<input type="date" name="expenseDateFrom" id="expenseDateFrom" value={tempBillExpenseDetails.expenseDateFrom} onChange={handleInvoiceBillInfoChange} placeholder="Select Date From" className="form-control form-control-sm" />
													</div>
												</div>
												<div className="form-group row">
													<label htmlFor="expenseDateTo" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }} >Date To</label>
													<div className="col-lg-8">
														<input type="date" name="expenseDateTo" id="expenseDateTo" value={tempBillExpenseDetails.expenseDateTo} onChange={handleInvoiceBillInfoChange} placeholder="Select Date To" className="form-control form-control-sm" />
													</div>
												</div>
											</>
										}
										{tempBillExpenseDetails.expenseTypeId === 19 &&
											<div className="form-group row">
												<label htmlFor="hours" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Hours <span className="required text-danger"></span></label>
												<div className="col-lg-8">
													<input type="number" name="hours" id="hours" value={tempBillExpenseDetails.hours} onChange={handleInvoiceBillInfoChange} placeholder="Enter Hours" className="form-control form-control-sm" />
												</div>
											</div>
										}
										{(tempBillExpenseDetails.expenseTypeId === 2 || tempBillExpenseDetails.expenseTypeId === 5 || tempBillExpenseDetails.expenseTypeId === 17 || tempBillExpenseDetails.expenseTypeId === 22
											|| tempBillExpenseDetails.expenseTypeId === 31 || tempBillExpenseDetails.expenseTypeId === 32 || tempBillExpenseDetails.expenseTypeId === 49) &&
											<div className="form-group row">
												<label htmlFor="quantity" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Quantity <span className="required text-danger"></span></label>
												<div className="col-lg-8">
													<input type="number" name="quantity" id="quantity" value={tempBillExpenseDetails.quantity} onChange={handleInvoiceBillInfoChange} placeholder="Enter Quantity" className="form-control form-control-sm" />
												</div>
											</div>
										}
										{(tempBillExpenseDetails.expenseTypeId === 2 || tempBillExpenseDetails.expenseTypeId === 5 || tempBillExpenseDetails.expenseTypeId === 7 || tempBillExpenseDetails.expenseTypeId === 12
											|| tempBillExpenseDetails.expenseTypeId === 16 || tempBillExpenseDetails.expenseTypeId === 17 || tempBillExpenseDetails.expenseTypeId === 19 || tempBillExpenseDetails.expenseTypeId === 22
											|| tempBillExpenseDetails.expenseTypeId === 32 || tempBillExpenseDetails.expenseTypeId === 49) &&
											<div className="form-group row">
												<label htmlFor="rate" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Rate <span className="required text-danger"></span></label>
												<div className="col-lg-8">
													<input type="number" name="rate" id="rate" value={tempBillExpenseDetails.rate} onChange={handleInvoiceBillInfoChange} placeholder="Enter Rate" className="form-control form-control-sm" />
												</div>
											</div>
										}
										{(tempBillExpenseDetails.expenseTypeId === 2 || tempBillExpenseDetails.expenseTypeId === 5 || tempBillExpenseDetails.expenseTypeId === 12 || tempBillExpenseDetails.expenseTypeId === 17
											|| tempBillExpenseDetails.expenseTypeId === 22 || tempBillExpenseDetails.expenseTypeId === 25 || tempBillExpenseDetails.expenseTypeId === 49) &&
											<>
												<div className="form-group row">
													<label htmlFor="currentMeterReading" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Current Meter Reading <span className="required text-danger"></span></label>
													<div className="col-lg-8">
														<input type="number" name="currentMeterReading" id="currentMeterReading" value={tempBillExpenseDetails.currentMeterReading} onChange={handleInvoiceBillInfoChange} placeholder="Enter Current Meter Reading" className="form-control form-control-sm" />
													</div>
												</div>
												<div className="form-group row">
													<label htmlFor="previousMeterReading" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Previous Meter Reading <span className="required text-danger"></span></label>
													<div className="col-lg-8">
														<input type="number" name="previousMeterReading" id="previousMeterReading" value={tempBillExpenseDetails.previousMeterReading} onChange={handleInvoiceBillInfoChange} placeholder="Enter Previous Meter Reading" className="form-control form-control-sm" />
													</div>
												</div>
												<div className="form-group row">
													<label htmlFor="distance" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Distance <span className="required text-danger"></span></label>
													<div className="col-lg-8">
														<input type="number" name="distance" id="distance" value={tempBillExpenseDetails.distance} readOnly placeholder="Enter Distance" className="form-control form-control-sm" />
													</div>
												</div>
											</>
										}
										{tempBillExpenseDetails.expenseTypeId === 3 &&
											<div className="form-group row">
												<label htmlFor="itemName" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Item Name <span className="required text-danger"></span></label>
												<div className="col-lg-8">
													<input type="text" name="itemName" id="itemName" value={tempBillExpenseDetails.itemName} onChange={handleInvoiceBillInfoChange} placeholder="Enter Item Name" className="form-control form-control-sm" />
												</div>
											</div>
										}
										{tempBillExpenseDetails.expenseTypeId === 11 &&
											<div className="form-group row">
												<label htmlFor="insuranceType" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }} >Insurance Type<span className="required text-danger"></span></label>
												<div className="col-lg-8">
													<select className="form-select form-select-sm" id="insuranceType" name="insuranceType" value={tempBillExpenseDetails.insuranceType} onChange={handleInvoiceBillInfoChange} >
														{insuranceTypes.map(function (item, id) {
															return <option key={id} value={item.value}>{item.label}</option>
														})
														}
													</select>
												</div>
											</div>
										}
										{(tempBillExpenseDetails.expenseTypeId === 13 || tempBillExpenseDetails.expenseTypeId === 18 || tempBillExpenseDetails.expenseTypeId === 23 || tempBillExpenseDetails.expenseTypeId === 24
											|| tempBillExpenseDetails.expenseTypeId === 25 || tempBillExpenseDetails.expenseTypeId === 26 || tempBillExpenseDetails.expenseTypeId === 27) &&
											<div className="form-group row">
												<label htmlFor="purpose" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Purpose <span className="required text-danger"></span></label>
												<div className="col-lg-8">
													<input type="text" name="purpose" id="purpose" value={tempBillExpenseDetails.purpose} onChange={handleInvoiceBillInfoChange} placeholder="Enter Purpose" className="form-control form-control-sm" />
												</div>
											</div>
										}
										{(tempBillExpenseDetails.expenseTypeId === 21 || tempBillExpenseDetails.expenseTypeId === 30) &&
											<div className="form-group row">
												<label htmlFor="place" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Place <span className="required text-danger"></span></label>
												<div className="col-lg-8">
													<input type="text" name="place" id="place" value={tempBillExpenseDetails.place} onChange={handleInvoiceBillInfoChange} placeholder="Enter Place" className="form-control form-control-sm" />
												</div>
											</div>
										}
										{tempBillExpenseDetails.expenseTypeId === 25 &&
											<div className="form-group row">
												<label htmlFor="lastDateOfChanges" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }} >Last Date of Changes</label>
												<div className="col-lg-8">
													<input type="date" name="lastDateOfChanges" id="lastDateOfChanges" value={tempBillExpenseDetails.lastDateOfChanges} onChange={handleInvoiceBillInfoChange} placeholder="Select Last Date of Changes" className="form-control form-control-sm" />
												</div>
											</div>
										}
										{tempBillExpenseDetails.expenseTypeId === 33 &&
											<>
												<div className="form-group row">
													<label htmlFor="vehicleCondition" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }} >Vehicle Condition<span className="required text-danger"></span></label>
													<div className="col-lg-8">
														<select className="form-select form-select-sm" id="vehicleCondition" name="vehicleCondition" value={tempBillExpenseDetails.vehicleCondition} onChange={handleInvoiceBillInfoChange} >
															{vehicleConditions.map(function (item, id) {
																return <option key={id} value={item.value}>{item.label}</option>
															})
															}
														</select>
													</div>
												</div>
												<div className="form-group row">
													<label htmlFor="purchaseFrom" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Purchase From <span className="required text-danger"></span></label>
													<div className="col-lg-8">
														<input type="text" name="purchaseFrom" id="purchaseFrom" value={tempBillExpenseDetails.purchaseFrom} onChange={handleInvoiceBillInfoChange} placeholder="Enter Purchase From" className="form-control form-control-sm" />
													</div>
												</div>
											</>
										}
										{(tempBillExpenseDetails.expenseTypeId === 31 || tempBillExpenseDetails.expenseTypeId === 32) &&
											<div className="form-group row">
												<label htmlFor="remarks" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Remarks <span className="required text-danger"></span></label>
												<div className="col-lg-8">
													<input type="text" name="remarks" id="remarks" value={tempBillExpenseDetails.remarks} onChange={handleInvoiceBillInfoChange} placeholder="Enter Remarks" className="form-control form-control-sm" />
												</div>
											</div>
										}
									</div>
								</div>
								<div className="row">
									<div className="col-2">
									</div>
									<div className="col-6">
										<button type="button" className="btn btn-light-primary btn-sm" onClick={() => addExpenseData(tempBillExpenseDetails)}> <KTSVG
											path='/media/icons/duotune/files/fil005.svg'
											className='svg-icon-5 svg-icon'
										/>
											Add Expense</button>
									</div>
								</div>

								{mainBillExpenseDetails.length > 0 &&
									<>
										<div className="card-header">
											<div className="card-title">
												<KTSVG path='/media/svg/icons/Text/Bullet-list.svg' className='svg-icon-3 svg-icon-primary' />
												<span className="text-primary" style={{ marginLeft: "5px" }}>
													Expenses List
												</span>
											</div>
										</div>
										<div className="tableFixHead">
											<div className="table-responsive" style={{ maxHeight: "280px", overflow: "auto", margin: "5px" }}>
												<table className="table table-bordered table-hover" style={{ minWidth: "max-content" }}>
													<thead>
														<tr style={{ "backgroundColor": "#C7E9EC" }}>
															<th>Action</th>
															<th>Sl</th>
															<th>Expense Type</th>
															<th>Ledger Name</th>
															<th>Cost Center</th>
															<th>Expense Date</th>
															<th>Expense Amount</th>
															<th>Date From</th>
															<th>Date To</th>
															<th>Hours</th>
															<th>Quantity</th>
															<th>Rate</th>
															<th>Current Meter Reading</th>
															<th>Previous Meter Reading</th>
															<th>Distance</th>
															<th>Item Name</th>
															<th>Insurance Type</th>
															<th>Purpose</th>
															<th>Place</th>
															<th>Last Date Of Changes</th>
															<th>Vehicle Condition</th>
															<th>Purchase From</th>
															<th>Remarks</th>
															<th>Attachment</th>
														</tr>
													</thead>
													<tbody>
														{
															mainBillExpenseDetails.map((expense, index) =>
																<tr key={index}>
																	<td>
																		<button type="button" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1" onClick={editExpenseDetails(index)}>
																			<KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon svg-icon-5' />
																		</button>
																		<button type="button" className="btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1" onClick={removeExpenseDetails(index)}>
																			<KTSVG path='/media/svg/icons/General/Trash.svg' className='svg-icon svg-icon-5' />
																		</button>
																	</td>
																	<td>{index + 1}</td>
																	<td> {expense.expenseType.label}</td>
																	<td> {expense.ledgerName}</td>
																	<td> {expense.costCenterName}</td>
																	<td> {expense.expenseDate}</td>
																	<td> {expense.expenseAmount}</td>
																	<td> {expense.expenseDateFrom}</td>
																	<td> {expense.expenseDateTo}</td>
																	<td> {expense.hours}</td>
																	<td> {expense.quantity}</td>
																	<td> {expense.rate}</td>
																	<td> {expense.currentMeterReading}</td>
																	<td> {expense.previousMeterReading}</td>
																	<td> {expense.distance}</td>
																	<td> {expense.itemName}</td>
																	<td> {expense.insuranceType}</td>
																	<td> {expense.purpose}</td>
																	<td> {expense.place}</td>
																	<td> {expense.lastDateOfChanges}</td>
																	<td> {expense.vehicleCondition}</td>
																	<td> {expense.purchaseFrom}</td>
																	<td> {expense.remarks}</td>
																	<td> {expense.fileAttachment &&
																		<>
																			{expense.fileAttachment.name}
																		</>
																	}</td>
																</tr>
															)
														}

													</tbody>
												</table>
											</div>
										</div>
									</>
								}
							</div>
							<div className="row">
								<div className="col-5">
								</div>
								<div className="col-6">
									<button type="submit" disabled={isLoading === true} className="btn btn-success btn-sm mb-5">Submit</button>
									<button type="reset" className="btn btn-danger btn-sm mb-5" onClick={() => resetExpenseData()}>Reset</button>
									{isLoading === true &&
										<ReactLoading type={"bars"} color={"#4739ea3d"} height={'20%'} width={'20%'} />
									}
								</div>
							</div>
							{/* <div className="row">
						<div className="col-4">
							<pre>{JSON.stringify(invoiceBillInfo, null, 2)}</pre>
						</div>
						<div className="col-4">
							<pre>{JSON.stringify(tempBillExpenseDetails, null, 2)}</pre>
						</div>
						<div className="col-4">
							<pre>{JSON.stringify(mainBillExpenseDetails, null, 2)}</pre>
						</div>
					</div> */}
						</form>
					</div>
					<Modal size="lg" show={assignCostCenterModalShow} onHide={assignCostCenterModalClose} aria-labelledby="example-modal-sizes-title-lg">
						<Modal.Header style={{ paddingTop: "10px", paddingBottom: "10px" }}>
							<Modal.Title>
								<span>
									<KTSVG path='/media/svg/icons/Communication/Shield-user.svg' className='svg-icon-1 svg-icon-primary' />
								</span>
								<span className='text-primary fw-bolder' style={{ marginLeft: '5px' }}>
									ASSIGN COST CENTER
								</span>
								<span className='text-primary fw-bolder' style={{ marginLeft: '485px' }}>
									<button type="button" className='btn btn-sm btn-icon btn-active-color-primary' onClick={assignCostCenterModalClose}>
										<KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1 svg-icon' />
									</button>
								</span>
							</Modal.Title>
						</Modal.Header>
						<Modal.Body style={{ paddingTop: "0.75rem" }}>
							<CostCenterAssign onAssignCostCenterConfirm={onAssignCostCenterConfirm} idCostCenter={tempBillExpenseDetails.costCenterId} costCenterName={tempBillExpenseDetails.costCenterName} expanded={tempBillExpenseDetails.expanded} projectId={invoiceBillInfo.projectId} />
						</Modal.Body>
						<Modal.Footer style={{ marginTop: "-24px" }}>
							<button className='btn btn-success btn-sm' onClick={assignCostCenterModalClose}>Ok</button>
							<button className='btn btn-danger btn-sm' onClick={assignCostCenterModalClose}>Cancel</button>
						</Modal.Footer>
					</Modal>
					<ToastContainer />
				</div>
			}
			{hasPermission === 0 &&
				<div className='card card-custom' style={{ padding: "15px" }} >
					<SkeletonTheme>
						<p>
							<Skeleton
								containerClassName="avatar-skeleton"
								count={40} ></Skeleton>
						</p>
					</SkeletonTheme>
				</div>
			}

		</>
	)

}

export default InvoiceBillCreate