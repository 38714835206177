import React, { useState, useEffect, useContext } from 'react';
import BackendTable from 'react-backend-table';
import userPermissionContext from '../../context/userPermissionContext';

function VehicleExpenseList(props) {
    const { jwt } = useContext(userPermissionContext);
    const [idVmsVehicleInfo, setIdVmsVehicleInfo] = useState("");
    const [reload, setReload] = useState(false);
    const [formData, setFormData] = useState({
        startExpenseDate: "",
        endExpenseDate: ""
    });

    useEffect(() => {
        setIdVmsVehicleInfo(props.idVmsVehicleInfo);
        setReload(true);
    }, [props.idVmsVehicleInfo])

    useEffect(() => {
        if (reload) {
            options.reloadMyTable();
            setReload(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload])

    let columns = [
        { title: 'Id', field: 'idVmsExpenseDetails', sortable: true, searchable: true, thStyle: { textAlign: "center" }, tdStyle: { textAlign: "center" } },
        { title: 'Expense Date', field: 'expenseDate', sortable: true, searchable: true },
        { title: 'Expense Name', field: 'expenseName', sortable: true, searchable: true },
        { title: 'Project', field: 'projectName', searchable: true, sortable: true },
        { title: 'Expense Amount', field: 'expenseAmount', searchable: true, sortable: true },
        { title: 'Bill No', field: 'billNo', searchable: true, sortable: true },
        { title: 'Bill Date', field: 'billDate', sortable: true, searchable: true },
        { title: 'Driver', field: 'driverName', sortable: true, searchable: true },
        { title: 'Ledger', field: 'ledgerName', sortable: true, searchable: true },
        { title: 'Cost Center', field: 'costCenterName', sortable: true, searchable: true },
        { title: 'Module', field: 'moduleFrom', sortable: true, searchable: true }
    ]

    let options = {
        url: process.env.REACT_APP_API_URL + 'vehicleExpenseList/' + idVmsVehicleInfo,
        authorization: 'Bearer ' + jwt.token,
        perPage: [50, 100, 500, 1000, 'All'],
        orderBy: 'idVmsExpenseDetails',
        orderType: 'desc',
        columnSearch: true,
        extraData: formData
    }

    const handleInputOnChange = evt => {
        setFormData({ ...formData, [evt.target.name]: evt.target.value });
        setReload(true);
    }


    return (
        <>
            <div className='card card-custom'>
                <div className='row mt-5'>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <label htmlFor="startExpenseDate" className="col-form-label" >Expense Date Start</label>
                        <div className="col-lg-2">
                            <input type="date" name="startExpenseDate" id="startExpenseDate" value={formData.startExpenseDate} onChange={handleInputOnChange} className="form-control form-control-sm" style={{ marginLeft: "5px" }} />
                        </div>
                        <div style={{ width: '15px' }}>&nbsp;</div>

                        <label htmlFor="endExpenseDate" className="col-form-label">Expense Date End</label>
                        <div className="col-lg-2">
                            <input type="date" name="endExpenseDate" id="endExpenseDate" value={formData.endExpenseDate} onChange={handleInputOnChange} className="form-control form-control-sm" style={{ marginLeft: "5px" }} />
                        </div>
                    </div>
                </div>
                <div className='ml-12 mt-3'>
                    <BackendTable columns={columns} options={options} />
                </div>
            </div>

        </>
    )
}

export default VehicleExpenseList;