/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React, { useEffect, useState, useContext } from 'react';
import { getRequestOptions } from "../components/GetToken";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { KTSVG } from "../../_metronic/helpers";
import Swal from 'sweetalert2';
import userPermissionContext from '../../context/userPermissionContext';
import Form from 'react-bootstrap/Form';

const ExpirationDateModify = (props) => {

    const initialState = () => ({
        idVmsVehicleInfo: '',
        idVmsVehicleInsurance: '',
        taxTokenExpirationDate: '',
        insuranceExpirationDate: '',
        fitnessExpirationDate: '',
        routePermitExpirationDate: '',
        vehicleNumber: '',
        newTaxTokenExpirationDate: '',
        newInsuranceExpirationDate: '',
        newFitnessExpirationDate: '',
        newRoutePermitExpirationDate: '',
        type: '',
        prevUpdateDate: ''
    })
    const [renewData, setRenewData] = useState(initialState);
    const { jwt } = useContext(userPermissionContext);
    const [fileAttachment, setFileAttachment] = useState(null);

    useEffect(() => {
        const getVehicleRenewData = () => {
            fetch(process.env.REACT_APP_API_URL + "vehicleRenewData/" + props.idVmsVehicleInfo, getRequestOptions())
                .then((resp) => {
                    return resp.json()
                })
                .then((resp) => {
                    setRenewData({ idVmsVehicleInfo: resp.data.idVmsVehicleInfo, taxTokenExpirationDate: resp.data.taxTokenExpirationDate, insuranceExpirationDate: resp.data.insuranceExpirationDate, fitnessExpirationDate: resp.data.fitnessExpirationDate, routePermitExpirationDate: resp.data.routePermitExpirationDate, vehicleNumber: resp.data.vehicleNumber, type: props.type, newTaxTokenExpirationDate: '', newInsuranceExpirationDate: '', newFitnessExpirationDate: '', newRoutePermitExpirationDate: '', idVmsVehicleInsurance: resp.data.idVmsVehicleInsurance, prevUpdateDate: props.prevUpdateDate })
                })
                .catch((error) => {
                    console.log(error, "catch the hoop")
                });
        }
        getVehicleRenewData();
    }, [props.idVmsVehicleInfo])

    const handleInputOnChange = (e) => {
        setRenewData({ ...renewData, [e.target.name]: e.target.value })
    }

    const onFileChange = event => {
        setFileAttachment({ ...fileAttachment, file: event.target.files[0], lastModified: event.target.files[0].lastModified, name: event.target.files[0].name, size: event.target.files[0].size, type: event.target.files[0].type });
    }

    const handleSubmit = evt => {
        evt.preventDefault();
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to update this Renewal!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {
                const formData = new FormData();
                if (fileAttachment !== null) {
                    formData.append('myFile', fileAttachment.file);
                }
                else {
                    formData.append('myFile', null);
                }
                formData.append("renewalData", JSON.stringify(renewData));
                axios.post(process.env.REACT_APP_API_URL + "vehicleRenewalDataUpdate", formData, {
                    headers: { 'Content-Type': 'multipart/form-data', 'Authorization': 'Bearer ' + jwt.token },
                })
                    .then(response => {
                        if (response.data.success === true) {
                            Swal.fire({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.successMessage,
                            })
                            setRenewData(initialState);
                            setFileAttachment(null);
                            props.onRenewConfirm();
                        }
                        else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Error',
                                html: response.data.errorMessage
                            })
                        }
                    })
                    .catch((error) => {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            html: error.response.data.errorMessage
                        })
                    })
            }
        })
    }

    return (
        <>
            <div className="card card-custom">
                <div className="card-header">
                    <div className="card-title">
                        <KTSVG
                            path='/media/svg/icons/Communication/Clipboard-list.svg'
                            className='svg-icon-1 svg-icon-primary'
                        />
                        <span className="text fw-bolder" style={{ marginLeft: "10px" }}>
                            RENEW {props.type === 'tax_token' && 'TAX TOKEN'}{props.type === 'insurance' && 'INSURANCE'}{props.type === 'fitness' && 'FITNESS'}{props.type === 'route_permit' && 'ROUTE PERMIT'}
                        </span>
                    </div>
                </div>
                <div className="ml-12 mt-2">
                    <form onSubmit={handleSubmit}>
                        <div className="form-group row">
                            <label htmlFor="vehicleNumber" className="col-lg-5 col-form-label" style={{ "textAlign": "right" }}>Vehicle Number</label>
                            <div className="col-lg-5">
                                <input type="text" name="vehicleNumber" id="vehicleNumber" value={renewData.vehicleNumber} className="form-control form-control-sm" readOnly />
                            </div>
                        </div>
                        {renewData.prevUpdateDate !== null &&
                            <div className="form-group row">
                                <label htmlFor="prevUpdateDate" className="col-lg-5 col-form-label" style={{ "textAlign": "right" }}>Last Update Date</label>
                                <div className="col-lg-5">
                                    <input type="text" name="prevUpdateDate" id="prevUpdateDate" value={renewData.prevUpdateDate} className="form-control form-control-sm" readOnly />
                                </div>
                            </div>
                        }
                        {props.type === 'tax_token' &&
                            <>
                                <div className="form-group row">
                                    <label htmlFor="taxTokenExpirationDate" className="col-lg-5 col-form-label" style={{ "textAlign": "right" }}>Previous Tax Token Expiration Date</label>
                                    <div className="col-lg-5">
                                        <input type="date" name="taxTokenExpirationDate" id="taxTokenExpirationDate" value={renewData.taxTokenExpirationDate} className="form-control form-control-sm" readOnly />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="newTaxTokenExpirationDate" className="col-lg-5 col-form-label" style={{ "textAlign": "right" }}>New Tax Token Expiration Date <span className="required text-danger"></span></label>
                                    <div className="col-lg-5">
                                        <input type="date" name="newTaxTokenExpirationDate" id="newTaxTokenExpirationDate" value={renewData.newTaxTokenExpirationDate} className="form-control form-control-sm" onChange={handleInputOnChange} />
                                    </div>
                                </div>
                            </>
                        }
                        {props.type === 'insurance' &&
                            <>
                                <div className="form-group row">
                                    <label htmlFor="insuranceExpirationDate" className="col-lg-5 col-form-label" style={{ "textAlign": "right" }}>Previous Insurance Expiration Date <span className="required text-danger"></span></label>
                                    <div className="col-lg-5">
                                        <input type="date" name="insuranceExpirationDate" id="insuranceExpirationDate" value={renewData.insuranceExpirationDate} className="form-control form-control-sm" readOnly />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="newInsuranceExpirationDate" className="col-lg-5 col-form-label" style={{ "textAlign": "right" }}>New Insurance Expiration Date <span className="required text-danger"></span></label>
                                    <div className="col-lg-5">
                                        <input type="date" name="newInsuranceExpirationDate" id="newInsuranceExpirationDate" value={renewData.newInsuranceExpirationDate} className="form-control form-control-sm" onChange={handleInputOnChange} />
                                    </div>
                                </div>
                            </>
                        }
                        {props.type === 'fitness' &&
                            <>
                                <div className="form-group row">
                                    <label htmlFor="fitnessExpirationDate" className="col-lg-5 col-form-label" style={{ "textAlign": "right" }}>Previous Fitness Expiration Date <span className="required text-danger"></span></label>
                                    <div className="col-lg-5">
                                        <input type="date" name="fitnessExpirationDate" id="fitnessExpirationDate" value={renewData.fitnessExpirationDate} className="form-control form-control-sm" readOnly />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="newFitnessExpirationDate" className="col-lg-5 col-form-label" style={{ "textAlign": "right" }}>New Fitness Expiration Date <span className="required text-danger"></span></label>
                                    <div className="col-lg-5">
                                        <input type="date" name="newFitnessExpirationDate" id="newFitnessExpirationDate" value={renewData.newFitnessExpirationDate} className="form-control form-control-sm" onChange={handleInputOnChange} />
                                    </div>
                                </div>
                            </>
                        }
                        {props.type === 'route_permit' &&
                            <>
                                <div className="form-group row">
                                    <label htmlFor="routePermitExpirationDate" className="col-lg-5 col-form-label" style={{ "textAlign": "right" }}>Previous Route Permit Expiration Date <span className="required text-danger"></span></label>
                                    <div className="col-lg-5">
                                        <input type="date" name="routePermitExpirationDate" id="routePermitExpirationDate" value={renewData.routePermitExpirationDate} className="form-control form-control-sm" readOnly />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="newRoutePermitExpirationDate" className="col-lg-5 col-form-label" style={{ "textAlign": "right" }}>New Route Permit Expiration Date <span className="required text-danger"></span></label>
                                    <div className="col-lg-5">
                                        <input type="date" name="newRoutePermitExpirationDate" id="newRoutePermitExpirationDate" value={renewData.newRoutePermitExpirationDate} className="form-control form-control-sm" onChange={handleInputOnChange} />
                                    </div>
                                </div>
                            </>
                        }
                        <div className="form-group row">
                            <label htmlFor="fileAttachment" className="col-lg-5 col-form-label" style={{ "textAlign": "right" }}>Attach File </label>
                            <div className="col-lg-5">
                                <Form.Control type="file" size="sm" name="fileAttachment" id="fileAttachment" onChange={onFileChange} onClick={e => (e.target.value = null)} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6">
                            </div>
                            <div className="col-6">
                                <input type="submit" className="btn btn-sm btn-success me-2" value='Submit' />
                            </div>
                        </div>
                    </form>
                    {/* <pre>{JSON.stringify(renewData, null, 2)}</pre> */}
                </div>
            </div>
            <ToastContainer />
        </>
    );
}

export default ExpirationDateModify;