import React, { useEffect, useState } from 'react';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { getRequestOptions } from "../components/GetToken";

function CostCenterAssign(props) {
    const [checked, setChecked] = useState([]);
    const [costCenterName, setCostCenterName] = useState("");
    const [tempChecked, setTempChecked] = useState([]);
    const [expanded, setExpanded] = useState([]);
    const [filterText, setFilterText] = useState('');
    const [nodesFiltered, setNodesFiltered] = useState([]);
    const [costCenters, setCostCenters] = useState([]);
    const [expandedData, setExpandedData] = useState([]);

    useEffect(() => {

        const getProjectCostCenter = () => {
            fetch(process.env.REACT_APP_API_URL + "costCenters/" + props.projectId, getRequestOptions())
                .then((resp) => {
                    return resp.json()
                })
                .then((resp) => {
                    setCostCenters(resp.data);
                    setNodesFiltered(resp.data);
                    setExpandedData(resp.expandData);
                })
                .catch((error) => {
                    console.log(error, "catch the hoop")
                });
        }
        getProjectCostCenter();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.projectId])

    useEffect(() => {
        if (props.idCostCenter.length) {
            setChecked(props.idCostCenter);
            setTempChecked(props.idCostCenter);
            setCostCenterName(props.costCenterName);
            setExpanded(props.expanded);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.idCostCenter]);

    const onCheck = (checked, targetNode) => {
        if (checked.length > 0) {
            if (checked.length === 1) {
                setChecked(checked);
                setTempChecked(checked);
                setCostCenterName(targetNode.label);
                props.onAssignCostCenterConfirm(checked, targetNode.label, expanded);

            }
            else {
                var tempArr = tempChecked;
                var difference = checked.filter(x => tempChecked.indexOf(x) === -1);
                tempArr = tempArr.concat(difference);

                var last_element = tempArr[tempArr.length - 1];
                const newChecked = tempArr.filter((item) => item === last_element);

                setChecked(newChecked);
                setTempChecked(newChecked);
                setCostCenterName(targetNode.label);
                props.onAssignCostCenterConfirm(newChecked, targetNode.label, expanded);

            }
        }
        else {
            setChecked([]);
            setTempChecked([]);
            setCostCenterName('');
            props.onAssignCostCenterConfirm([], '', expanded);
        }
    }

    const onFilterChange = evt => {
        setFilterText(evt.target.value);
        setExpanded(expandedData);
    }

    useEffect(() => {
        filterTree();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterText])

    const filterTree = () => {
        // Reset nodes back to unfiltered state
        // if (!filterText || filterText === "" || filterText.length === 0) {
        //     return;
        // }

        setNodesFiltered(costCenters);

        const nodesFiltered = costCenters => {
            return costCenters.reduce(filterNodes, [])
        };

        setNodesFiltered(nodesFiltered);

    }

    const filterNodes = (filtered, node) => {
        const children = (node.children || []).reduce(filterNodes, []);
        if (
            // Node's label matches the search string
            node.label.toLocaleLowerCase().indexOf(filterText.toLocaleLowerCase()) > -1 ||
            // Or a children has a matching node
            children.length
        ) {
            filtered.push({ ...node, ...children.length && { children } });

        }
        return filtered;
    }

    return (
        <>
            <div className="card card-custom">
                <div className="card-body">
                    <div className="filter-container">
                        <input
                            className="filter-text form-control form-control-sm"
                            placeholder="Search Here..."
                            type="text"
                            value={filterText}
                            onChange={onFilterChange}
                        />
                        <div className='mt-5'>
                            <div className='row'>
                                <div className='col-md-1'></div>
                                <div className='col-md-10' style={{ height: "500px", overflow: "auto" }}>
                                    <CheckboxTree
                                        checked={checked}
                                        expanded={expanded}
                                        iconsClass="fa5"
                                        noCascade
                                        showExpandAll
                                        nodes={nodesFiltered}
                                        onCheck={(checked, targetNode) => onCheck(checked, targetNode)}
                                        onExpand={expanded => setExpanded(expanded)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group row mt-5">
                        <label htmlFor="costCenterName" className="col-lg-2 col-form-label" style={{ "textAlign": "right" }} >Cost Center</label>
                        <div className="col-lg-8">
                            <input type="text" name="costCenterName" id="costCenter" value={costCenterName} readOnly placeholder="Cost Center" className="form-control form-control-sm" />
                        </div>
                    </div>
                </div>
            </div>
            {/* <pre>{JSON.stringify(expanded, null, 2)}</pre> */}
        </>
    );

}
export default CostCenterAssign