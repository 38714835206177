/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import AsyncSelect from 'react-select/async';
import 'react-toastify/dist/ReactToastify.css';
import { KTSVG } from '../../_metronic/helpers';
import Swal from 'sweetalert2';
import { useHistory } from "react-router-dom";
import { getRequestOptions } from "../components/GetToken";
import { GetPagePermission } from "../components/UserPagePermission";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import userPermissionContext from '../../context/userPermissionContext';

const customStylesSelect = {
    control: (provided) => ({
        ...provided,
        height: 'calc(1.35em + 1.1rem + 2px)',
        minHeight: '35px'
    }),
    valueContainer: (provided) => ({
        ...provided
    })
};


function VmoApprove() {
    const { jwt } = useContext(userPermissionContext);
    const initialState = () => ({
        idVmo: "",
        vmoNo: "",
        businessUnit: "",
        vmoDate: "",
        receivingBu: "",
        remarks: "",
        vmoDetails: [{ vehicle: "", receiveDate: "", ratePolicy: "", monthlyRent: "", remarks: "" }]
    })
    const { id } = useParams();
    const [vmo, setVmo] = useState(initialState());
    const hasPermission = GetPagePermission("/vmo-create");

    let history = useHistory();

    useEffect(() => {
        const getVmoInfoById = () => {
            fetch(process.env.REACT_APP_API_URL + "vmoInfoById/" + id, getRequestOptions())
                .then((resp) => {
                    return resp.json()
                })
                .then((resp) => {
                    setVmo({
                        ...vmo,
                        idVmo: resp.data.id_vmo,
                        vmoNo: resp.data.vmoNo,
                        businessUnit: resp.data.business_unit,
                        vmoDate: resp.data.vmoDate,
                        receivingBu: resp.data.receiving_businessunit,
                        remarks: resp.data.remarks,
                        vmoDetails: resp.data.vmo_details
                    });
                })
                .catch((error) => {
                    console.log(error, "catch the hoop")
                });
        }
        getVmoInfoById();
    }, [id]);

    const getAllProject = (inputValue, callback) => {

        if (!inputValue) {
            callback([]);

        }
        else {
            var url = process.env.REACT_APP_API_URL + "getBusinessUnits?q=" + inputValue;

            setTimeout(() => {
                fetch(url,
                    getRequestOptions())

                    .then((resp) => {
                        return resp.json()
                    })
                    .then((resp) => {
                        const tempArray = resp.data.map(function (element) {
                            element.label = element.projectName;
                            element.value = element.projectId;
                            return element;
                        });
                        callback(tempArray);
                    })
                    .catch((error) => {
                        console.log(error, "catch the hoop")
                    });
            });
        }
    }

    const getAllVehicle = (inputValue, callback) => {

        if (!inputValue) {
            callback([]);

        }
        else {
            var url = process.env.REACT_APP_API_URL + "vmo/vehicleSearch?q=" + inputValue;

            setTimeout(() => {
                fetch(url,
                    getRequestOptions())

                    .then((resp) => {
                        return resp.json()
                    })
                    .then((resp) => {
                        const tempArray = resp.data.map(function (element) {
                            element.label = element.vehicleNumber;
                            element.value = element.vehicleInfoId;
                            return element;
                        });
                        callback(tempArray);
                    })
                    .catch((error) => {
                        console.log(error, "catch the hoop")
                    });
            });
        }
    }


    const handleBusinessUnitChange = businessUnit => {
        setVmo({ ...vmo, businessUnit: businessUnit });
    }

    const handleReceivingBuChange = businessUnit => {
        setVmo({ ...vmo, receivingBu: businessUnit });
    }

    const handleInputChange = (event) => {
        setVmo({ ...vmo, [event.target.name]: event.target.value });
    }

    const handleVehicleChange = index => vehicle => {
        const newItems = vmo.vmoDetails.map(function (row, rindex) {
            if (index === rindex) {
                row.vehicle = vehicle;
            }
            return row;
        })
        setVmo({ ...vmo, vmoDetails: newItems });
    }

    const handleReceiveDateChange = index => evt => {
        const newItems = vmo.vmoDetails.map(function (row, rindex) {
            if (index === rindex) {
                row.receiveDate = evt.target.value;
            }
            return row;
        })
        setVmo({ ...vmo, vmoDetails: newItems });
    }

    const handleRatePolicyChange = index => evt => {
        const newItems = vmo.vmoDetails.map(function (row, rindex) {
            if (index === rindex) {
                row.ratePolicy = evt.target.value;
            }
            return row;
        })
        setVmo({ ...vmo, vmoDetails: newItems });
    }

    const handleMonthlyRentChange = index => evt => {
        const newItems = vmo.vmoDetails.map(function (row, rindex) {
            if (index === rindex) {
                row.monthlyRent = evt.target.value;
            }
            return row;
        })
        setVmo({ ...vmo, vmoDetails: newItems });
    }

    const handleRemarksChange = index => evt => {
        const newItems = vmo.vmoDetails.map(function (row, rindex) {
            if (index === rindex) {
                row.remarks = evt.target.value;
            }
            return row;
        })
        setVmo({ ...vmo, vmoDetails: newItems });
    }

    const handleSubmit = evt => {
        evt.preventDefault();
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to approve this vehicle movement order!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {
                const formData = { ...vmo };
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt.token },
                    body: JSON.stringify(formData)
                };
                var apiEnd = "vmoApprove/" + vmo.idVmo;
                fetch(process.env.REACT_APP_API_URL + apiEnd, requestOptions)
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((resp) => {
                        if (resp.success === true) {
                            Swal.fire({
                                icon: 'success',
                                title: 'Success',
                                text: resp.successMessage,
                            })
                            setVmo(initialState());
                            history.push('/vmo-details/' + resp.data.id);
                        }
                        else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Error',
                                html: resp.errorMessage
                            })
                        }
                    })
                    .catch((error) => {
                        console.log(error, "catch the hoop")
                    });
            }
        })
    }

    const ratePolicy = [
        { label: 'Select Rate Policy', value: '' },
        { label: 'Dry', value: 'dry' },
        { label: 'Wet', value: 'wet' }
    ]

    return (
        <>
            {hasPermission === 1 &&
                <div className="card card-custom">
                    <div className="card-header">
                        <div className="card-title">
                            <span>
                                <KTSVG
                                    path='/media/svg/icons/Design/Edit.svg'
                                    className='svg-icon-1 svg-icon-primary'
                                />
                            </span>
                            <span className="text fw-bolder" style={{ marginLeft: "10px" }}>
                                VMO APPROVE
                            </span>
                        </div>
                    </div>

                    <div className="ml-12">
                        <form onSubmit={handleSubmit}>
                            <div className="card-body">
                                <div className="form-group row">
                                    <label htmlFor="vmoNo" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }} > Vmo No <span className="required text-danger"></span></label>
                                    <div className="col-lg-5">
                                        <input type="vmoNo" name="vmoNo" id="vmoNo" value={vmo.vmoNo} onChange={handleInputChange} className="form-control form-control-sm" readOnly />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="example-text-input" style={{ "textAlign": "right" }} className="col-lg-3 col-form-label" >Business Unit<span className="required text-danger"></span></label>
                                    <div className="col-lg-5">
                                        <AsyncSelect
                                            value={vmo.businessUnit}
                                            defaultOptions
                                            loadOptions={getAllProject}
                                            placeholder="Select Project"
                                            onChange={handleBusinessUnitChange}
                                            styles={customStylesSelect}
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="date" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }} > VMO Date <span className="required text-danger"></span></label>
                                    <div className="col-lg-5">
                                        <input type="date" name="vmoDate" id="date" value={vmo.vmoDate} onChange={handleInputChange} className="form-control form-control-sm" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="example-text-input" style={{ "textAlign": "right" }} className="col-lg-3 col-form-label" >Receiving BU<span className="required text-danger"></span></label>
                                    <div className="col-lg-5">
                                        <AsyncSelect
                                            value={vmo.receivingBu}
                                            defaultOptions
                                            loadOptions={getAllProject}
                                            placeholder="Select Receiving Project"
                                            onChange={handleReceivingBuChange}
                                            styles={customStylesSelect}
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="remarks" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }} > Remarks </label>
                                    <div className="col-lg-5">
                                        <textarea name="remarks" id="remarks" value={vmo.remarks} onChange={handleInputChange} className="form-control form-control-sm" />
                                    </div>
                                </div>
                                <div className="mt-5">
                                    <div className="table" style={{ margin: "5px" }}>
                                        <table className="table table-bordered table-hover" style={{ minWidth: "max-content" }}>
                                            <thead>
                                                <tr>
                                                    <th>Sl</th>
                                                    <th>Vehicle Number</th>
                                                    <th>Receive Date</th>
                                                    <th>Rent Policy</th>
                                                    <th>Monthly Rent</th>
                                                    <th>Remarks</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    vmo.vmoDetails.map((item, index) =>
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td> <AsyncSelect
                                                                value={item.vehicle}
                                                                defaultOptions
                                                                loadOptions={getAllVehicle}
                                                                placeholder="Select Vehicle"
                                                                onChange={handleVehicleChange(index)}
                                                                styles={customStylesSelect}
                                                                menuPosition="fixed"
                                                                isDisabled={true}
                                                            /></td>
                                                            <td><input type="date" name="receiveDate" value={item.receiveDate} onChange={handleReceiveDateChange(index)} className="form-control form-control-sm" /></td>
                                                            <td> <select className="form-select form-select-sm" id="ratePolicy" name="ratePolicy" value={item.ratePolicy} onChange={handleRatePolicyChange(index)} disabled >
                                                                {ratePolicy.map(function (item, id) {
                                                                    return <option key={id} value={item.value}>{item.label}</option>
                                                                })
                                                                }
                                                            </select></td>
                                                            <td><input type="number" name="monthlyRent" value={item.monthlyRent} onChange={handleMonthlyRentChange(index)} className="form-control form-control-sm" /></td>
                                                            <td><textarea name="remarks" value={item.remarks} onChange={handleRemarksChange(index)} className="form-control form-control-sm" /></td>
                                                        </tr>
                                                    )
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className="row mt-2">
                                    <div className="col-5">
                                    </div>
                                    <div className="col-6">
                                        <button type="submit" className="btn btn-sm btn-success me-2">Approve</button>
                                        <button type="reset" className="btn btn-sm btn-secondary" onClick={() => history.goBack()}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                            {/* <pre>
                                {JSON.stringify(vmo, null, 2)}
                            </pre> */}
                        </form>
                    </div>
                    <ToastContainer />
                </div>
            }
            {hasPermission === 0 &&
                <div className='card card-custom' style={{ padding: "15px" }} >
                    <SkeletonTheme>
                        <p>
                            <Skeleton
                                containerClassName="avatar-skeleton"
                                count={40} ></Skeleton>
                        </p>
                    </SkeletonTheme>
                </div>
            }

        </>
    )
}

export default VmoApprove