import { useContext } from 'react';
import BackendTable from 'react-backend-table';
import { KTSVG } from '../../_metronic/helpers';
import { GetPagePermission } from "../components/UserPagePermission";
// import Error500 from '../components/Error500';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import userPermissionContext from '../../context/userPermissionContext';
import { Link } from "react-router-dom";

function InvoiceList() {
	const { jwt } = useContext(userPermissionContext);
	const hasPermission = GetPagePermission("/expenses-invoiceList");
	let columns = [
		{ title: 'Id', field: 'idPurchaseBillInvoice', sortable: true, searchable: true, thStyle: { textAlign: "center" }, tdStyle: { textAlign: "center" } },
		{
			title: 'Vehicle Number', field: 'vehicleNumber', sortable: true, searchable: true,
			hasComponent: true,
			componentValue: ((rowValue) => {
				return (
					<Link to={`/vehicleDetails/${rowValue.idVmsVehicleInfo}`} target="_blank" rel="noopener noreferrer">
						{rowValue.vehicleNumber}
					</Link>
				)

			})
		},
		{ title: 'Project Name', field: 'projectName', sortable: true, searchable: true },
		{ title: 'Vendor Name', field: 'vendorName', searchable: true, sortable: true },
		{ title: 'Email', field: 'vendorEmail', searchable: true, sortable: true },
		{ title: 'Contact No', field: 'vendorContact', searchable: true, sortable: true },
		{ title: 'Invoice No', field: 'invoiceNo', sortable: true, searchable: true },
		{ title: 'Amount', field: 'invoiceAmount', sortable: true, searchable: true },
		{ title: 'Invoice Date', field: 'invoiceDate', sortable: true, searchable: true },
		{ title: 'Receiving Date', field: 'receivingDate', sortable: true, searchable: true },
		{ title: 'Actual Receiving Date', field: 'actualReceivingDate', sortable: true, searchable: true },
		{ title: 'Remarks', field: 'remarks', sortable: true, searchable: true },
		{ title: 'Bill No', field: 'billNo', sortable: true, searchable: true }
	]

	let options = {
		url: process.env.REACT_APP_API_URL + 'invoiceList',
		authorization: 'Bearer ' + jwt.token,
		perPage: [50, 100, 500, 1000, 'All'],
		orderBy: 'idPurchaseBillInvoice',
		orderType: 'desc',
		columnSearch: true,
	}

	return (
		<>
			{hasPermission === 1 &&
				<div className='card card-custom'>
					<div className='card-header'>
						<div className='card-title'>
							<span>
								<KTSVG
									path='/media/svg/icons/Text/Bullet-list.svg'
									className='svg-icon-1 svg-icon-primary'
								/>
							</span>
							<span className='text fw-bolder' style={{ marginLeft: '10px' }}>
								INVOICE LIST
							</span>
						</div>
					</div>

					<div className='ml-12'>
						<BackendTable columns={columns} options={options} />
					</div>
				</div>
			}
			{hasPermission === 0 &&
				<div className='card card-custom' style={{ padding: "15px" }} >
					<SkeletonTheme>
						<p>
							<Skeleton
								containerClassName="avatar-skeleton"
								count={40} ></Skeleton>
						</p>
					</SkeletonTheme>
				</div>
			}

		</>
	)
}

export default InvoiceList;