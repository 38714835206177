import BackendTable from 'react-backend-table';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { KTSVG } from '../../_metronic/helpers';
import { GetPagePermission } from "../components/UserPagePermission";
import userPermissionContext from '../../context/userPermissionContext';
import { useContext } from 'react';
import Swal from 'sweetalert2';
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

function RentalBillList() {
    const { jwt } = useContext(userPermissionContext);
    const hasPermission = GetPagePermission("/rental-billList");
    const [reload, setReload] = useState(false);

    useEffect(() => {
        if (reload) {
            options.reloadMyTable();
            setReload(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload])


    let columns = [
        { title: 'Id', field: 'idVmsRentalBill', sortable: true, searchable: true, thStyle: { textAlign: "center" }, tdStyle: { textAlign: "center" } },
        { title: 'Bill No', field: 'billNo', sortable: true, searchable: true },
        { title: 'From BU', field: 'fromBusinessUnit', sortable: true, searchable: true },
        { title: 'To BU', field: 'toBusinessUnit', sortable: true, searchable: true },
        { title: 'Bill Date', field: 'billDate', searchable: true },
        { title: 'Regular Bill Amount', field: 'regularBillAmount', searchable: true },
        { title: 'Deduction Amount', field: 'deductionAmount', searchable: true },
        { title: 'Bill Amount', field: 'billAmount', sortable: true, searchable: true },
        {
            title: 'Status',
            field: 'status',
            sortable: true,
            searchable: true,
            hasComponent: true,
            componentValue: ((rowValue) => {
                return (rowValue.status === "created" ? <span className="badge badge-light-primary fw-bolder">Created</span> : <span className="badge badge-light-success fw-bolder">Approved</span>)
            })
        },
        {
            title: 'Actions',
            field: 'actions',
            thStyle: { textAlign: "center" },
            tdStyle: { whiteSpace: 'nowrap' },
            hasComponent: true,
            componentValue: ((rowValue) => {
                return (
                    <>
                        <Link to={`/rental-billDetails/${rowValue.idVmsRentalBill}`}>
                            <button className="btn btn-light-info btn-sm">
                                <KTSVG
                                    path='/media/svg/icons/Text/Bullet-list.svg'
                                    className='svg-icon-5 svg-icon'
                                />		Details
                            </button>
                            <br />
                        </Link>
                        {rowValue.status !== 'approved' &&
                            <button className="btn btn-light-info btn-sm" onClick={() => rentalBillApprove(rowValue.idVmsRentalBill)}>
                                <KTSVG
                                    path='/media/svg/icons/Text/Bullet-list.svg'
                                    className='svg-icon-5 svg-icon'
                                />		Approve
                            </button>
                        }

                    </>
                )
            })
        }
    ]

    const rentalBillApprove = idVmsRentalBill => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to approve this rental bill",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {
                const formData = { idVmsRentalBill };
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt.token },
                    body: JSON.stringify(formData)
                };
                var apiEnd = "rentalBillApprove";
                fetch(process.env.REACT_APP_API_URL + apiEnd, requestOptions)
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((resp) => {
                        if (resp.success === true) {
                            setReload(true);
                            Swal.fire({
                                icon: 'success',
                                title: 'Success',
                                text: resp.successMessage,
                            })
                        }
                        else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Error',
                                html: resp.errorMessage
                            })
                        }
                    })
                    .catch((error) => {
                        console.log(error, "catch the hoop")
                    });
            }
        })
    }


    let options = {
        url: process.env.REACT_APP_API_URL + 'rentalBillList',
        authorization: 'Bearer ' + jwt.token,
        perPage: [50, 100, 500, 1000, 'All'],
        orderBy: 'idVmsRentalBill',
        orderType: 'desc',
        columnSearch: true,
    }

    return (
        <>
            {hasPermission === 1 &&
                <div className='card card-custom'>
                    <div className='card-header'>
                        <div className='card-title'>
                            <span>
                                <KTSVG
                                    path='/media/svg/icons/Text/Bullet-list.svg'
                                    className='svg-icon-1 svg-icon-primary'
                                />
                            </span>
                            <span className='text fw-bolder' style={{ marginLeft: '10px' }}>
                                RENTAL BILL LIST
                            </span>
                        </div>
                    </div>

                    <div className='ml-12'>
                        <BackendTable columns={columns} options={options} />
                    </div>
                </div>
            }
            {hasPermission === 0 &&
                <div className='card card-custom' style={{ padding: "15px" }} >
                    <SkeletonTheme>
                        <p>
                            <Skeleton
                                containerClassName="avatar-skeleton"
                                count={40} ></Skeleton>
                        </p>
                    </SkeletonTheme>
                </div>
            }
        </>
    )
}

export default RentalBillList
