import React, { useState, useEffect, useImperativeHandle } from "react";
import { getRequestOptions } from "../components/GetToken";
import AsyncSelect from 'react-select/async';

const customStylesSelect = {
    control: (provided) => ({
        ...provided,
        height: 'calc(1.35em + 1.1rem + 2px)',
        minHeight: '35px'
    }),
    valueContainer: (provided) => ({
        ...provided,
    })
};


const VehicleAssign = React.forwardRef((props, ref) => {

    const initialState = () => ({
        project: "",
        driver: "",
        payrollUser: "yes",
        consultantOrAdvisorName: "",
        assignedUser: ""
    })
    const [vehicleAssign, setVehicleAssign] = useState(initialState());
    const [fieldStatus, setFieldStatus] = useState();

    useImperativeHandle(ref, () => ({
        setInitialState() {
            setVehicleAssign(initialState());
        }
    }));

    useEffect(() => {
        if (props.vehicleAssignEditData) {
            setVehicleAssign(props.vehicleAssignEditData);
            setFieldStatus(true);
        }
    }, [props.vehicleAssignEditData]);

    useEffect(() => {
        setFieldStatus(false);
    }, [])

    useEffect(() => {
        props.onVehicleAssignChange(vehicleAssign);

    }, [props, vehicleAssign]);

    const getAllProject = (inputValue, callback) => {

        if (!inputValue) {
            callback([]);

        }
        else {
            var url = process.env.REACT_APP_API_URL + "getBusinessUnits?q=" + inputValue;

            setTimeout(() => {
                fetch(url,
                    getRequestOptions())

                    .then((resp) => {
                        return resp.json()
                    })
                    .then((resp) => {
                        const tempArray = resp.data.map(function (element) {
                            element.label = element.projectName;
                            element.value = element.projectId;
                            return element;
                        });
                        callback(tempArray);
                    })
                    .catch((error) => {
                        console.log(error, "catch the hoop")
                    });
            });
        }
    }

    const getAllDriver = (inputValue, callback) => {

        if (!inputValue) {
            callback([]);

        }
        else {
            var url = process.env.REACT_APP_API_URL + "getAllDriver?q=" + inputValue;

            setTimeout(() => {
                fetch(url,
                    getRequestOptions())

                    .then((resp) => {
                        return resp.json()
                    })
                    .then((resp) => {
                        const tempArray = resp.data.map(function (element) {
                            element.label = `${element.fullName} (${element.mobileNumber})`;
                            element.value = element.idVmsDrivers;
                            return element;
                        });
                        callback(tempArray);
                    })
                    .catch((error) => {
                        console.log(error, "catch the hoop")
                    });
            });
        }
    }

    const getUserForAssign = (inputValue, callback) => {

        if (!inputValue) {
            callback([]);

        }
        else {
            var url = process.env.REACT_APP_API_URL + "users/getUserForAssign?q=" + inputValue;

            setTimeout(() => {
                fetch(url,
                    getRequestOptions())

                    .then((resp) => {
                        return resp.json()
                    })
                    .then((resp) => {
                        const tempArray = resp.data.map(function (element) {
                            element.label = `${element.fullName} (${element.designation})`;
                            element.value = element.employeeId;
                            return element;
                        });
                        callback(tempArray);
                    })
                    .catch((error) => {
                        console.log(error, "catch the hoop")
                    });
            });
        }
    }
    const handlePayrollUserChange = evt => {
        setVehicleAssign({ ...vehicleAssign, payrollUser: evt.target.value });
    }

    const handleProjectChange = businessUnit => {
        setVehicleAssign({ ...vehicleAssign, project: businessUnit });
    }

    const handleDriverChange = driver => {
        setVehicleAssign({ ...vehicleAssign, driver: driver });
    }

    const handleAssignedUserChange = user => {
        setVehicleAssign({ ...vehicleAssign, assignedUser: user });
    }

    return (
        <>
            <div className="form-group row">
                <label htmlFor="example-text-input" style={{ "textAlign": "right" }} className="col-lg-3 col-form-label" >Project Name<span className="required text-danger"></span></label>
                <div className="col-lg-5">
                    <AsyncSelect isDisabled={fieldStatus}
                        value={vehicleAssign.project || ""}
                        loadOptions={getAllProject}
                        placeholder="Select Project"
                        onChange={handleProjectChange}
                        styles={customStylesSelect}
                    />
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="payrollUser" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }} >Payroll User<span className="required text-danger"></span></label>
                <div className="col-lg-5">
                    <select className="form-select form-select-sm" id="payrollUser" name="payrollUser" value={vehicleAssign.payrollUser} onChange={handlePayrollUserChange} >
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                    </select>
                </div>
            </div>
            {vehicleAssign.payrollUser === "yes" ?
                <div className="form-group row">
                    <label htmlFor="example-text-input" style={{ "textAlign": "right" }} className="col-lg-3 col-form-label" >Assign To User{props.vehicleInfo?.vehicleType?.idVehicleType === 14 && <span className="required text-danger"></span>}</label>
                    <div className="col-lg-5">
                        <AsyncSelect isDisabled={fieldStatus}
                            value={vehicleAssign.assignedUser || ""}
                            loadOptions={getUserForAssign}
                            placeholder="Select User"
                            onChange={handleAssignedUserChange}
                            styles={customStylesSelect}
                        />
                    </div>
                </div>
                :
                <div className="form-group row">
                    <label htmlFor="consultantOrAdvisorName" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }} >Consultant / Advisor's Name</label>
                    <div className="col-lg-5">
                        <input type="text" name="consultantOrAdvisorName" id="consultantOrAdvisorName" value={vehicleAssign.consultantOrAdvisorName} onChange={(e) => { setVehicleAssign({ ...vehicleAssign, consultantOrAdvisorName: e.target.value }) }} className="form-control form-control-sm" />
                    </div>
                </div>
            }

            <div className="form-group row">
                <label htmlFor="example-text-input" style={{ "textAlign": "right" }} className="col-lg-3 col-form-label" >Driver Name{props.vehicleInfo?.vehicleType?.idVehicleType !== 14 && <span className="required text-danger"></span>}</label>
                <div className="col-lg-5">
                    <AsyncSelect isDisabled={fieldStatus}
                        value={vehicleAssign.driver || ""}
                        loadOptions={getAllDriver}
                        placeholder="Select Driver"
                        onChange={handleDriverChange}
                        styles={customStylesSelect}
                    />
                </div>
            </div>
            {/* <pre>{props.vehicleInfo?.vehicleType?.idVehicleType}</pre> */}
        </>
    )

})

export default React.memo(VehicleAssign);