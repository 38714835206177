/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react';
import { GoogleMap, Marker, LoadScript, InfoWindow, TrafficLayer } from '@react-google-maps/api';
import userPermissionContext from '../../../context/userPermissionContext';

function VehicleLiveLocation() {

    const { jwt } = useContext(userPermissionContext);
    const [liveLocationData, setLiveLocationData] = useState([]);
    const [center, setCenter] = useState({ lat: 23.6943, lng: 90.3444 });
    const [selectedMarker, setSelectedMarker] = useState(null);
    const [locationName, setLocationName] = useState('');

    useEffect(() => {
        getLiveLocation();
        const interval = setInterval(() => {
            getLiveLocation();
        }, 10000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    const getLiveLocation = () => {
        var body = {
            tracker_token: localStorage.getItem('TrackerToken')
        }
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt.token },
            body: JSON.stringify(body)
        };
        var apiEnd = "liveTracData";
        fetch(process.env.REACT_APP_API_URL + apiEnd, requestOptions)
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                if (resp.success === true) {
                    setLiveLocationData(resp.data);
                }
                else {
                    console.log(resp.errorMessage);
                }
            })
            .catch((error) => {
                console.log(error, "catch the hoop")
            });
    }

    const handleMarkerClick = (marker) => {
        setCenter({ lat: marker.latitude, lng: marker.longitude });
        setSelectedMarker(marker);
        getLocationName(marker.latitude, marker.longitude);
    };

    const handleInfoWindowClose = () => {
        setSelectedMarker(null);
        setLocationName('');
    };

    const handleLableClick = (marker) => {
        setCenter({ lat: marker.latitude, lng: marker.longitude });
        setSelectedMarker(marker);
        getLocationName(marker.latitude, marker.longitude);
    }

    const getLocationName = (lat, lng) => {
        const geocoder = new window.google.maps.Geocoder();
        const latLng = new window.google.maps.LatLng(lat, lng);

        geocoder.geocode({ location: latLng }, (results, status) => {
            if (status === 'OK') {
                if (results[0]) {
                    setLocationName(results[0].formatted_address);
                }
            } else {
                console.log('Geocoder failed due to: ' + status);
            }
        });
    };


    return (
        <LoadScript googleMapsApiKey={process.env.REACT_APP_MAP_API_KEY}>
            <GoogleMap
                zoom={8}
                mapContainerStyle={{ height: '900px', width: '100%' }}
                center={center}
            >
                <TrafficLayer />
                {liveLocationData.map((marker, index) => (
                    <div key={index} onClick={() => handleLableClick(marker)}>
                        <Marker
                            position={{ lat: marker.latitude, lng: marker.longitude }}
                            label={{
                                text: `${marker.name} (${marker.speed} km/h)`,
                                className: 'marker-label'
                            }}
                            onClick={() => handleMarkerClick(marker)}
                            icon={{ url: '/media/logos/car_marker.png', scaledSize: new window.google.maps.Size(60, 40) }}
                            animation={window.google.maps.Animation.DROP}
                        />
                    </div>
                ))}
                {selectedMarker && (
                    <InfoWindow
                        position={{ lat: selectedMarker.latitude, lng: selectedMarker.longitude }}
                        onCloseClick={handleInfoWindowClose}
                    >
                        <div>
                            <h3>{selectedMarker.name}</h3>
                            <p> Location : {locationName}</p>
                            <p> Speed : {selectedMarker.speed} km/h</p>
                            <p> Time : {selectedMarker.time}</p>
                            <p> Stop Duration: {selectedMarker.stop_duration}</p>
                            <p> Engine Status: {selectedMarker.sensors.find(sensor => sensor.name === 'Engine')?.value || null}</p>
                        </div>
                    </InfoWindow>
                )}
            </GoogleMap>
        </LoadScript>
    );
}
export default VehicleLiveLocation;
