/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useRef, useContext } from "react";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { KTSVG } from '../../_metronic/helpers';
import Swal from 'sweetalert2';
import { useHistory } from "react-router-dom";
import VehicleInfo from "../components/VehicleInfo";
import VehicleDetails from "../components/VehicleDetails";
import VehicleInsurance from "../components/VehicleInsurance";
import VehicleAssign from "../components/VehicleAssign";
import { Tab, Tabs } from "react-bootstrap-v5";
import { GetPagePermission } from "../components/UserPagePermission";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import userPermissionContext from '../../context/userPermissionContext';

function AddVehicle() {
	const { jwt } = useContext(userPermissionContext);

	const vehicleInfoRef = useRef();
	const vehicleInsuranceRef = useRef();
	const vehicleDetailsRef = useRef();
	const vehicleAssignRef = useRef();

	let history = useHistory();
	const [vehicleInfo, setVehicleInfo] = useState({});
	const [vehicleDetails, setVehicleDetails] = useState({});
	const [vehicleInsurance, setVehicleInsurance] = useState({});
	const [vehicleAssign, setVehicleAssign] = useState({});

	const onVehicleInfoChange = useCallback((vehicleInfo) => {
		setVehicleInfo(vehicleInfo);
	}, [])

	const onVehicleDetailsChange = useCallback((vehicleDetails) => {
		setVehicleDetails(vehicleDetails);
	}, [])

	const onVehicleInsuranceChange = useCallback((vehicleInsurance) => {
		setVehicleInsurance(vehicleInsurance);
	}, [])

	const onVehicleAssignChange = useCallback((vehicleAssign) => {
		setVehicleAssign(vehicleAssign);
	}, [])

	const hasPermission = GetPagePermission("/vehicleAdd");

	const handleSubmit = evt => {
		evt.preventDefault();
		Swal.fire({
			title: 'Are you sure?',
			text: 'You want to add this Vehicle!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes',
			cancelButtonText: 'No'
		}).then((result) => {
			if (result.value) {
				const formData = {
					vehicleInfo: vehicleInfo,
					vehicleDetails: vehicleDetails,
					vehicleInsurance: vehicleInsurance,
					vehicleAssign: vehicleAssign
				};
				const requestOptions = {
					method: 'POST',
					headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt.token },
					body: JSON.stringify(formData)
				};

				fetch(process.env.REACT_APP_API_URL + "vehicleAdd", requestOptions)
					.then((resp) => {
						return resp.json()
					})
					.then((resp) => {

						if (resp.success === true) {

							var successMsg = [`Vehicle Number #${resp.data.vehicleNumber}`];
							Swal.fire({
								icon: 'success',
								title: 'Success',
								text: successMsg,
							})
							vehicleInfoRef.current.setInitialState();
							vehicleInsuranceRef.current.setInitialState();
							vehicleDetailsRef.current.setInitialState();
							vehicleAssignRef.current.setInitialState();
							history.push("/vehicleDetails/" + resp.data.id);
						}
						else {
							Swal.fire({
								icon: 'error',
								title: 'Error',
								html: resp.errorMessage
							})
						}
					})
					.catch((error) => {
						console.log(error, "catch the hoop")
					});

			}
		})
	}


	return (
		<>
			{hasPermission === 1 &&

				<div className="card card-custom">
					<div className="card-header">
						<div className="card-title">
							<KTSVG
								path='/media/svg/icons/Devices/CPU1.svg'
								className='svg-icon-1 svg-icon-primary'
							/>
							<span className="text fw-bolder" style={{ marginLeft: "10px" }}>
								Add VEHICLE INFORMATION
							</span>
						</div>
					</div>

					<div className="ml-12">
						<form onSubmit={handleSubmit}>
							<div className="card-body">
								<Tabs defaultActiveKey="vehicleInfo" id="uncontrolled-tab-example" className="mb-3">
									<Tab eventKey="vehicleInfo" title="Vehicle Info">
										<VehicleInfo onVehicleInfoChange={onVehicleInfoChange} ref={vehicleInfoRef} />
									</Tab>
									<Tab eventKey="vehicleDetails" title="Vehicle Details">
										<VehicleDetails onVehicleDetailsChange={onVehicleDetailsChange} ref={vehicleDetailsRef} />
									</Tab>
									<Tab eventKey="vehicleInsurance" title="Vehicle Insurance" >
										<VehicleInsurance onVehicleInsuranceChange={onVehicleInsuranceChange} ref={vehicleInsuranceRef} />
									</Tab>
									<Tab eventKey="vehicleAssign" title="Vehicle Assign" >
										<VehicleAssign onVehicleAssignChange={onVehicleAssignChange} ref={vehicleAssignRef} vehicleInfo={vehicleInfo} />
									</Tab>
								</Tabs>
								<div className="row mt-5">
									<div className="col-5">
									</div>
									<div className="col-6">
										<input type="submit" className="btn btn-sm btn-success me-2" value={'Save'} />
										<button type="reset" className="btn btn-sm btn-secondary" onClick={() => history.goBack()}>Cancel</button>
									</div>
								</div>
							</div>
							{/* <pre>
                                {JSON.stringify(vehicleInfo, null, 2)}
                                {JSON.stringify(vehicleDetails, null, 2)}
                                {JSON.stringify(vehicleInsurance, null, 2)}
                                {JSON.stringify(vehicleAssign, null, 2)}
                            </pre> */}
							{/* <pre> */}
							{/* {JSON.stringify(idVmsVehicleInfo, null, 2)} */}
							{/* {JSON.stringify(details, null, 2)} */}
							{/* {JSON.stringify(vehicleInsuranceEditData, null,2)}
                                {JSON.stringify(vehicleDetailsEditData, null,2)}
                                {JSON.stringify(vehicleAssignEditData, null,2)} */}
							{/* {JSON.stringify(vehicleInfoEditData, null,2)} */}
							{/* </pre> */}
						</form>
					</div>
					<ToastContainer />
				</div>
			}
			{hasPermission === 0 &&
				<div className='card card-custom' style={{ padding: "15px" }} >
					<SkeletonTheme>
						<p>
							<Skeleton
								containerClassName="avatar-skeleton"
								count={40} ></Skeleton>
						</p>
					</SkeletonTheme>
				</div>
			}
		</>
	)
}

export default AddVehicle
