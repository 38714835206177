import { FC } from 'react'

const Error404: FC = () => {
  return (
    <>
      <h1 className='fw-bolder fs-4x text-gray-700 mb-10'>Page Not Found</h1>
    </>
  )
}

export { Error404 }
