/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { getRequestOptions } from "../components/GetToken";
import { KTSVG } from '../../_metronic/helpers';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import AsyncSelect from 'react-select/async';
import ReactLoading from 'react-loading';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import userPermissionContext from '../../context/userPermissionContext';

function InvoiceBillDetails() {
	const { jwt } = useContext(userPermissionContext);
	const customStylesSelect = {
		menu: (provided) => ({
			...provided,
			whiteSpace: 'nowrap',
			width: 'max-content'
		}),
	};

	const initialState = () => ({
		modeOfTransaction: "",
		chequeNo: "",
		totalBillAmount: 0,
		totalPaidAmount: 0,
		alreadyPaidAmount: 0,
		payableAmount: 0,
		paymentAmount: 0,
		remainingAmount: 0,
		projectAccount: "",
		transactionDate: "",
		remarks: ""
	});

	const { id } = useParams();
	const [idVmsVehicleInfo, setIdVmsVehicleInfo] = useState("");
	const [details, setDetails] = useState([]);
	const [files, setFiles] = useState([]);
	const [tab, setTab] = useState("details");
	const [paymentData, setPaymentData] = useState(initialState());
	const [idProjects, setIdProjects] = useState("");
	const [resetPaymentData, setResetPaymentData] = useState(false);
	const [paymentHistory, setPaymentHistory] = useState([]);
	const [paymentPermission, setPaymentPermission] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [attachedUploadFile, setAttachedUploadFile] = useState([]);
	const [reload, setReload] = useState(false);

	useEffect(() => {
		const getRegularExpensesDetails = () => {
			fetch(process.env.REACT_APP_API_URL + "invoiceBillDetails/" + id, getRequestOptions())
				.then((resp) => {
					return resp.json()
				})
				.then((resp) => {
					setDetails(resp.data);
					setIdVmsVehicleInfo(resp.data.idVmsVehicleInfo);
					setIdProjects(resp.data.idProjects);
				})
				.catch((error) => {
					console.log(error, "catch the hoop")
				});
		}
		getRegularExpensesDetails();

	}, [id]);

	useEffect(() => {
		const getAllFiles = () => {
			const refType = "Bill_Expense";
			const refId = id;

			fetch(process.env.REACT_APP_API_URL + "getAllFiles/" + refType + "/" + refId, getRequestOptions())
				.then((resp) => {
					return resp.json()
				})
				.then((resp) => {
					setFiles(resp.data);
				})
				.catch((error) => {
					console.log(error, "catch the hoop")
				});
		}
		if (idVmsVehicleInfo !== "" || reload === true) {
			getAllFiles();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [idVmsVehicleInfo, reload]);

	useEffect(() => {
		const getPaymentInfo = () => {
			fetch(process.env.REACT_APP_API_URL + "invoiceBillPaymentInfo/" + id, getRequestOptions())
				.then((resp) => {
					return resp.json()
				})
				.then((resp) => {
					setPaymentData({ ...paymentData, alreadyPaidAmount: resp.data.alreadyPaidAmount, payableAmount: resp.data.payableAmount, remainingAmount: resp.data.remainingAmount, totalBillAmount: resp.data.totalBillAmount, totalPaidAmount: resp.data.totalPaidAmount });
					setPaymentHistory(resp.data.vms_expense_payment);
				})
				.catch((error) => {
					console.log(error, "catch the hoop")
				});
		}
		getPaymentInfo();
		setResetPaymentData(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [resetPaymentData]);

	useEffect(() => {
		calcTotal();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paymentData.paymentAmount])

	useEffect(() => {
		const invoiceBillPaymentPermission = () => {
			fetch(process.env.REACT_APP_API_URL + "invoiceBillPaymentPermission", getRequestOptions())
				.then((resp) => {
					return resp.json()
				})
				.then((resp) => {
					setPaymentPermission(resp.data.idPages);
				})
				.catch((error) => {
					console.log(error, "catch the hoop")
				});

		}

		invoiceBillPaymentPermission();

	}, [])

	const getProjectBankAccounts = (inputValue, callback) => {

		if (!inputValue) {
			callback([]);

		}
		else {
			var url = process.env.REACT_APP_API_URL + "getProjectBankAccounts/" + idProjects + "?q=" + inputValue;

			setTimeout(() => {
				fetch(url,
					getRequestOptions())

					.then((resp) => {
						return resp.json()
					})
					.then((resp) => {
						const tempArray = resp.data.map(function (element) {
							element.label = element.account;
							element.value = element.idProjectAccounts;
							return element;
						});
						callback(tempArray);
					})
					.catch((error) => {
						console.log(error, "catch the hoop")
					});
			});
		}
	}

	const handleInputOnChange = (evt) => {
		if (evt.target.name === "transactionDate") {
			const date = new Date().toISOString().slice(0, 10);
			if (date < evt.target.value) {
				toast.error("Transaction date cannot be greater than current date");
			}
			else {
				setPaymentData({ ...paymentData, [evt.target.name]: evt.target.value });
			}
		}
		else if (evt.target.name === "paymentAmount") {
			if (paymentData.modeOfTransaction === "Adjust") {
				if (Number(evt.target.value) > Number(paymentData.advanceBill.unAdjustedAmount)) {
					toast.error("Payment amount cannot be greater than unadjusted amount");
				}
			}
			setPaymentData({ ...paymentData, [evt.target.name]: evt.target.value });
		}
		else {
			setPaymentData({ ...paymentData, [evt.target.name]: evt.target.value });
		}
	}

	const handleProjectBankAccountChange = (projectAccount) => {
		setPaymentData({ ...paymentData, projectAccount: projectAccount });
	}
	const calcTotal = () => {
		setPaymentData({ ...paymentData, remainingAmount: Number(paymentData.payableAmount) - Number(paymentData.paymentAmount) });
	}
	const handleReset = () => {
		setPaymentData(initialState());
		setResetPaymentData(true);
	}

	const onFileChange = event => {
		setAttachedUploadFile({ ...attachedUploadFile, fileAttachment: { file: event.target.files[0], lastModified: event.target.files[0].lastModified, name: event.target.files[0].name, size: event.target.files[0].size, type: event.target.files[0].type } });
	}

	const resetAttachedUploadFile = () => {
		setAttachedUploadFile([]);
	}

	const handleSubmit = evt => {
		evt.preventDefault();
		Swal.fire({
			title: 'Are you sure?',
			text: "You want to pay this amount!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes',
			cancelButtonText: 'No'
		}).then((result) => {
			if (result.value) {
				const formData = { ...paymentData, idVmsExpense: id };
				const requestOptions = {
					method: 'POST',
					headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt.token },
					body: JSON.stringify(formData)
				};

				var apiEnd = "invoiceBillPayment";
				setIsLoading(true);
				fetch(process.env.REACT_APP_API_URL + apiEnd, requestOptions)
					.then((resp) => {
						return resp.json()
					})
					.then((resp) => {
						if (resp.success === true) {
							let successMsg = [`Payment done successfully`];

							Swal.fire({
								icon: 'success',
								title: 'Success',
								text: successMsg,
							})
							setPaymentData(initialState());
							setResetPaymentData(true);
							setIsLoading(false);

						}
						else {
							Swal.fire({
								icon: 'error',
								title: 'Error',
								html: resp.errorMessage
							})
							setIsLoading(false);
						}
					})
					.catch((error) => {
						console.log(error, "catch the hoop")
						setIsLoading(false);
					});

			}
		})
	}

	const handleAttachedFileSubmit = evt => {
		evt.preventDefault();
		Swal.fire({
			title: 'Are you sure?',
			text: "You want to submit this attached file!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes',
			cancelButtonText: 'No'
		}).then((result) => {
			if (result.value) {
				if (attachedUploadFile.fileAttachment) {
					const formData = new FormData();
					formData.append('attachedFile', attachedUploadFile.fileAttachment.file);
					formData.append("idVmsExpense", id);
					formData.append("idVmsVehicleInfo", idVmsVehicleInfo);
					setIsLoading(true);
					axios.post(process.env.REACT_APP_API_URL + "invoiceBillAttachedFileUpload", formData, {
						headers: { 'Content-Type': 'multipart/form-data', 'Authorization': 'Bearer ' + jwt.token },
					})
						.then(response => {
							if (response.data.success === true) {
								Swal.fire({
									icon: 'success',
									title: 'Success',
									text: response.data.successMessage,
								})
								resetAttachedUploadFile();
								setIsLoading(false);
								setReload(true);
							}
							else {
								Swal.fire({
									icon: 'error',
									title: 'Error',
									html: response.data.errorMessage
								})
								setIsLoading(false);
							}
						})
						.catch((error) => {
							Swal.fire({
								icon: 'error',
								title: 'Error',
								html: error.response.data.errorMessage
							})
							setIsLoading(false);
						})
				}
				else {
					Swal.fire({
						icon: 'error',
						title: 'Error',
						html: 'Please attach a file before submit'
					})
					setIsLoading(false);
				}
			}
		})
	}

	const tabChange = tab => {
		setTab(tab);
	}

	const filehost = process.env.REACT_APP_AWS_URL + 'vms/';
	const filePath = idVmsVehicleInfo + '/expenses';
	const billPrintEndPoint = process.env.REACT_APP_API_URL.split("api/")[0] + "invoice_bill_print";
	const token = jwt;
	const voucherPrint = process.env.REACT_APP_ACCOUNTING + 'voucher_print';

	return (

		<div className="card card-custom">
			<div className="card-header">
				<div className="card-title">
					<KTSVG
						path='/media/svg/icons/Text/Article.svg'
						className='svg-icon-1 svg-icon-primary'
					/>
					<span className="text fw-bolder" style={{ marginLeft: "10px" }}>
						INVOICE BILL
					</span>
				</div>
				<a href={`${billPrintEndPoint}/${id}?token=${token.token}`} target="_blank" rel="noopener noreferrer" className="btn btn-success btn-sm float-center">Bill Print</a>
			</div>
			<div className="card-body">
				<ul className="nav nav-tabs nav-tabs-line">
					<li className="nav-item" onClick={() => tabChange("details")}>
						<a className={`nav-link ${tab === "details" && "active"}`} data-toggle="tab" role="tab" aria-selected={(tab === "details").toString()}>Details</a>
					</li>
					<li className="nav-item" onClick={() => tabChange("files")}>
						<a className={`nav-link ${tab === "files" && "active"}`} data-toggle="tab" role="tab" aria-selected={(tab === "files").toString()}>Files</a>
					</li>
					{paymentPermission !== "" &&
						<li className="nav-item" onClick={() => tabChange("payments")}>
							<a className={`nav-link ${tab === "payments" && "active"}`} data-toggle="tab" role="tab" aria-selected={(tab === "payments").toString()}>Payments</a>
						</li>
					}
				</ul>

				{tab === "details" &&
					<>
						<div className="row">
							<div className="col-md-6 mt-5">
								<table className="table table-sm table-hover table-bordered">
									<tbody>
										<tr>
											<th>Vehicle Number</th>
											<td>{details.vehicleNumber}</td>
										</tr>
										<tr>
											<th>Driver</th>
											<td>{details.driver}</td>
										</tr>
										<tr>
											<th>Project</th>
											<td>{details.projectName}</td>
										</tr>
										<tr>
											<th>Vendor</th>
											<td>{details.vendorName}</td>
										</tr>
										<tr>
											<th>Bill No</th>
											<td>{details.billNo}</td>
										</tr>
										<tr>
											<th>Bill Date</th>
											<td>{details.billDate}</td>
										</tr>
										<tr>
											<th>Bill Amount</th>
											<td>{details.amount}/-</td>
										</tr>
										<tr>
											<th>Bill Amount In Words</th>
											<td>{details.amountInWords} Tk. Only</td>
										</tr>
										<tr>
											<th>Creator Name</th>
											<td>{details.createdBy}</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div className="col-md-6 mt-5">
								<table className="table table-sm table-hover table-bordered">
									<tbody>
										<tr>
											<th>Invoice No</th>
											<td>{details.invoiceNo}</td>
										</tr>
										<tr>
											<th>Approver</th>
											<td>{details.approverName}</td>
										</tr>
										<tr>
											<th>Ap</th>
											<td>{details.apName}</td>
										</tr>
										<tr>
											<th>User</th>
											<td>{details.userName}</td>
										</tr>
										<tr>
											<th>Project Authority Date</th>
											<td>{details.projectAuthorityDate}</td>
										</tr>
										<tr>
											<th>Comments</th>
											<td>{details.comments}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>

						<div className="row mt-5">
							<div className="tableFixHead">
								<div className="table-responsive" style={{ maxHeight: "400px", overflow: "auto" }}>
									<table className="table table-striped table-bordered">
										<thead>
											<tr className="text-primary">
												<th>SL</th>
												<th>Expense Type</th>
												<th>Ledger Name</th>
												<th>Cost Center</th>
												<th>Expense Date</th>
												<th>Expense Amount</th>
												<th>Date From</th>
												<th>Date To</th>
												<th>Hours</th>
												<th>Quantity</th>
												<th>Rate</th>
												<th>Current Meter Reading</th>
												<th>Previous Meter Reading</th>
												<th>Distance</th>
												<th>Item Name</th>
												<th>Insurance Type</th>
												<th>Purpose</th>
												<th>Place</th>
												<th>Last Date Of Changes</th>
												<th>Vehicle Condition</th>
												<th>Purchase From</th>
												<th>Remarks</th>
											</tr>
										</thead>
										<tbody>
											{
												details.vms_expense_details && details.vms_expense_details.map((expense, index) =>
													<tr key={index}>
														<td>{index + 1}</td>
														<td> {expense.expenseType}</td>
														<td> {expense.ledgerName}</td>
														<td> {expense.costCenterName}</td>
														<td> {expense.expenseDate}</td>
														<td> {expense.expenseAmount}/-</td>
														<td> {expense.expenseDateFrom}</td>
														<td> {expense.expenseDateTo}</td>
														<td> {expense.hours}</td>
														<td> {expense.quantity}</td>
														<td> {expense.rate}</td>
														<td> {expense.currentMeterReading}</td>
														<td> {expense.previousMeterReading}</td>
														<td> {expense.distance}</td>
														<td> {expense.itemName}</td>
														<td> {expense.insuranceType}</td>
														<td> {expense.purpose}</td>
														<td> {expense.place}</td>
														<td> {expense.lastDateOfChanges}</td>
														<td> {expense.vehicleCondition}</td>
														<td> {expense.purchaseFrom}</td>
														<td> {expense.remarks}</td>
													</tr>
												)
											}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</>
				}
				{tab === 'files' &&
					<div className="row">
						<div className="col-md-8">
							<div className="card-header">
								<div className="card-title">
									<KTSVG
										path='/media/svg/icons/Files/File-done.svg'
										className='svg-icon-1 svg-icon-primary'
									/>
									<span className="text-primary">
										Files
									</span>
								</div>
							</div>
							<div className="card-body">
								<form onSubmit={handleAttachedFileSubmit}>
									<div className="form-group row">
										<label htmlFor="fileAttachment" className="col-lg-2 col-form-label mt-2" style={{ "textAlign": "right" }}>Attach File </label>
										<div className="col-lg-8  col-form-label">
											<Form.Control type="file" size="sm" name="fileAttachment" id="fileAttachment" onChange={onFileChange} onClick={e => (e.target.value = null)} />
										</div>
									</div>
									<div className="form-group row">
										<div className="col-lg-3"></div>
										<div className="col-lg-6">
											<button type="submit" disabled={isLoading === true} className="btn btn-success btn-sm mb-5">Submit</button>
											<button type="reset" className="btn btn-danger btn-sm mb-5" onClick={() => resetAttachedUploadFile()}>Reset</button>
											{isLoading === true &&
												<ReactLoading type={"bubbles"} color={"#4739ea3d"} height={'15%'} width={'15%'} />
											}
										</div>
									</div>
								</form>
								<div className="table-responsive" style={{ maxHeight: "400px", overflow: "auto" }}>
									<table className="table table-bordered">
										<thead>
											<tr className="text-primary">
												<th>SL</th>
												<th>Name</th>
												<th>Size</th>
												<th style={{ "textAlign": "center" }}>Action</th>
											</tr>
										</thead>
										{files.length > 0 ?
											<tbody>
												{files.map((item, index) =>
													<tr key={index}>
														<td>{index + 1}</td>
														<td>{item.originalFileName}</td>
														<td>{item.fileSize}</td>
														<td style={{ "textAlign": "center" }}>
															<a href={`${filehost}uploads/${filePath}/${item.fileName}`} target="_blank" rel="noopener noreferrer" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"><span className="svg-icon svg-icon-3"><KTSVG path='/media/svg/icons/Files/DownloadedFile.svg' /></span></a>
														</td>
													</tr>
												)}
											</tbody>
											:
											<tbody>
												<tr>
													<td colSpan="4"><p style={{ "textAlign": "center" }}><strong>No File Found</strong></p></td>
												</tr>
											</tbody>
										}
									</table>
								</div>
							</div>
						</div>
					</div>
				}
				{tab === "payments" &&
					<form onSubmit={handleSubmit}>
						<div className="row">
							<div className="col-md-6 mt-5">
								<div className="form-group row">
									<label htmlFor="totalBillAmount" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }} > Total Bill Amount </label>
									<div className="col-lg-7">
										<input type="number" name="totalBillAmount" id="totalBillAmount" value={paymentData.totalBillAmount} readOnly className="form-control form-control-sm" />
									</div>
								</div>
								<div className="form-group row">
									<label htmlFor="alreadyPaidAmount" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }} > Already Paid Amount </label>
									<div className="col-lg-7">
										<input type="number" name="alreadyPaidAmount" id="alreadyPaidAmount" value={paymentData.alreadyPaidAmount} readOnly className="form-control form-control-sm" />
									</div>
								</div>
								<div className="form-group row">
									<label htmlFor="payableAmount" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }} > Payable Amount </label>
									<div className="col-lg-7">
										<input type="number" name="payableAmount" id="payableAmount" value={paymentData.payableAmount} readOnly className="form-control form-control-sm" />
									</div>
								</div>
								<div className="form-group row">
									<label htmlFor="modeOfTransaction" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }}>Mode of Transaction <span className="required text-danger"></span>
									</label>
									<div className="col-lg-7">
										<select className="form-select form-select-sm" id="modeOfTransaction" name="modeOfTransaction" value={paymentData.modeOfTransaction} onChange={handleInputOnChange}>
											<option value="">Select Mode Of Transaction</option>
											<option value="Cheque">Cheque</option>
											{/* <option value="Adjust">Adjust</option> */}
										</select>
									</div>
								</div>
								{paymentData.modeOfTransaction === "Cheque" &&
									<>
										<div className="form-group row">
											<label htmlFor="example-text-input" style={{ "textAlign": "right" }} className="col-lg-4 col-form-label">Bank Name & A/C Number<span className="required text-danger"></span></label>
											<div className="col-lg-7">
												<AsyncSelect
													value={paymentData.projectAccount}
													defaultOptions
													loadOptions={getProjectBankAccounts}
													onChange={handleProjectBankAccountChange}
													styles={customStylesSelect}
													getOptionLabel={(option) => (
														<>
															<KTSVG
																path='/media/svg/icons/Files/File-cloud.svg'
																className='svg-icon-2 svg-icon-primary'
															/>
															<span> {option.account} </span><br></br>
															<KTSVG
																path='media/svg/icons/Home/Home.svg'
																className='svg-icon-2 svg-icon-warning'
															/>
															<span> {option.project_name} </span><br></br>
															<KTSVG
																path='media/svg/icons/Shopping/Box1.svg'
																className='svg-icon-2 svg-icon-danger'
															/>
															<span> {option.bank_name} </span>

														</>
													)}
												/>
											</div>
										</div>

										<div className="form-group row mt-5">
											<label htmlFor="chequeNo" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }} > Cheque No <span className="required text-danger"></span></label>
											<div className="col-lg-7">
												<input type="number" name="chequeNo" id="chequeNo" value={paymentData.chequeNo} className="form-control form-control-sm" onChange={handleInputOnChange} />
											</div>
										</div>
									</>
								}
								{/* {paymentData.modeOfTransaction === "Adjust" &&
									<div className="form-group row">
										<label htmlFor="example-text-input" style={{ "textAlign": "right" }} className="col-lg-4 col-form-label">Advance Bill <span className="required text-danger"></span></label>
										<div className="col-lg-7">
											<AsyncSelect
												value={paymentData.advanceBill}
												defaultOptions
												loadOptions={getDriverWiseAdvanceBill}
												onChange={handleAdvanceBillChange}
												isDisabled={idProjects === "" || idVmsDrivers === ""}
												getOptionLabel={(option) => (
													<>
														<KTSVG
															path='/media/svg/icons/Files/File-cloud.svg'
															className='svg-icon-2 svg-icon-primary'
														/>
														<span>Bill No : {option.label} </span><br></br>
														<KTSVG
															path='media/svg/icons/Shopping/Calculator.svg'
															className='svg-icon-2 svg-icon-warning'
														/>
														<span>Unadjusted Amount : {option.unAdjustedAmount} </span>
													</>
												)}
											/>
										</div>
									</div>

								} */}
								<div className="form-group row mt-3">
									<label htmlFor="paymentAmount" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }} > Payment Amount <span className="required text-danger"></span></label>
									<div className="col-lg-7">
										<input type="number" name="paymentAmount" id="paymentAmount" value={paymentData.paymentAmount} className="form-control form-control-sm" onChange={handleInputOnChange} />
										<span className="text-danger fw-bold d-block" style={{ textAlign: "right" }}>Maximum amount: {paymentData.payableAmount}</span>
									</div>
								</div>
								<div className="form-group row mt-2">
									<label htmlFor="remainingAmount" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }} > Remaining Amount </label>
									<div className="col-lg-7">
										<input type="number" name="remainingAmount" id="remainingAmount" value={paymentData.remainingAmount} readOnly className="form-control form-control-sm" />
									</div>
								</div>
								<div className="form-group row">
									<label htmlFor="transactionDate" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }} >Transaction Date<span className="required text-danger"></span></label>
									<div className="col-lg-7">
										<input type="date" name="transactionDate" id="transactionDate" value={paymentData.transactionDate} onChange={handleInputOnChange} className="form-control form-control-sm" />
									</div>
								</div>
								<div className="form-group row">
									<label htmlFor="remarks" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }} > Remarks </label>
									<div className="col-lg-7">
										<textarea name="remarks" id="remarks" value={paymentData.remarks} className="form-control form-control-sm" onChange={handleInputOnChange} />
									</div>
								</div>
								<div className="row mt-3">
									<div className="col-6">
									</div>
									<div className="col-6">
										<button type="submit" disabled={isLoading === true} className="btn btn-success btn-sm">Payment</button>
										<button type="reset" className="btn btn-danger btn-sm" onClick={handleReset}>Reset</button>
										{isLoading === true &&
											<ReactLoading type={"bars"} color={"#4739ea3d"} height={'20%'} width={'20%'} />
										}
									</div>
								</div>
							</div>
							<div className="col-md-6">
								<div className="card-header">
									<div className="card-title">
										<KTSVG
											path='/media/svg/icons/Files/File-done.svg'
											className='svg-icon-1 svg-icon-primary'
										/>
										<span className="text-primary">
											History
										</span>
									</div>
								</div>
								<div className="card-body">
									<div className="table-responsive" style={{ maxHeight: "400px", overflow: "auto" }}>
										<table className="table table-bordered">
											<thead>
												<tr className="text-primary">
													<th>SL</th>
													<th>Transaction Date</th>
													<th>Mode of Transaction</th>
													<th>Cash ledger</th>
													<th>Account Number</th>
													<th>Cheque No</th>
													<th>Advance Bill No</th>
													<th>Payment Amount</th>
													<th>Voucher No</th>
													<th>Remarks</th>
												</tr>
											</thead>
											{paymentHistory.length > 0 ?
												<>
													<tbody>
														{paymentHistory.map((item, index) =>
															<tr key={index}>
																<td>{index + 1}</td>
																{item.idCheckRequest === null ? <td>{item.transactionDate}</td> : <td>{item.modeOfTransaction === 'Beftn' ? item.beftnTransactionDate : item.checkTransactionDate}</td>}
																<td><span>{item.modeOfTransaction}</span>{item.idCheckRequest !== null ? <span>(Payment through Bank Module)</span> : null}</td>
																{item.modeOfTransaction === "Cash" ?
																	<td><span>{item.ledgerName}</span><span className="text-muted">({item.ledgerpath})</span></td>
																	:
																	<td></td>
																}

																{item.modeOfTransaction === "Cheque" &&
																	<td>{item.idCheckRequest === null ? `${item.bankName}(${item.accountNumber})` : `${item.bankModuleBankName}(${item.bankModuleAccountNumber})`}</td>

																}
																{item.modeOfTransaction === "Beftn" &&
																	<td>{item.bankModuleBankName}({item.bankModuleAccountNumber})</td>
																}
																{item.modeOfTransaction === "Adjust" && <td></td>}
																<td>{item.idCheckRequest === null ? item.chequeNo : item.leafNumber}</td>
																<td>
																	{item.modeOfTransaction === "Adjust" ?
																		<span>{item.advanceBillNo}</span>
																		:
																		""
																	}
																</td>
																<td>{item.paymentAmount}</td>
																<td><a href={`${voucherPrint}/${item.idVoucher}?token=${token.token}`} target="_blank" rel="noopener noreferrer" ><span>{item.voucherNo}</span><span className="text-muted">({item.idVoucher})</span></a></td>
																<td>{item.remarks}</td>
															</tr>
														)}
													</tbody>
													<tfoot>
														<tr>
															<td colSpan="7" style={{ textAlign: "right" }}>Total</td>
															<td>{paymentData.totalPaidAmount}</td>
															<td colSpan={2}></td>
														</tr>
													</tfoot>
												</>
												:
												<tbody>
													<tr>
														<td colSpan="10"><p style={{ "textAlign": "center" }}><strong>No payment found</strong></p></td>
													</tr>
												</tbody>
											}
										</table>
									</div>
								</div>
							</div>
						</div>
						{/* <pre>
							{JSON.stringify(paymentData, null, 2)}
						</pre> */}
					</form>
				}
			</div>
			<ToastContainer />
		</div>
	)
}

export default InvoiceBillDetails;