import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getRequestOptions } from "../components/GetToken";
import { KTSVG } from '../../_metronic/helpers';
import Badge from 'react-bootstrap/Badge';

function VmoDetails() {
    const { id } = useParams();
    const [details, setDetails] = useState([]);

    useEffect(() => {
        const getVmoDetails = () => {
            fetch(process.env.REACT_APP_API_URL + "vmoDetails/" + id, getRequestOptions())
                .then((resp) => {
                    return resp.json()
                })
                .then((resp) => {
                    setDetails(resp.data);
                })
                .catch((error) => {
                    console.log(error, "catch the hoop")
                });
        }
        getVmoDetails();
    }, [id]);

    return (
        <>
            <div className="card card-custom">
                <div className="card-header">
                    <div className="card-title">
                        <KTSVG
                            path='/media/icons/duotune/general/gen005.svg'
                            className='svg-icon-1 svg-icon-primary'
                        />
                        <span className="text fw-bolder">
                            VMO DETAILS
                        </span>
                    </div>
                </div>
                <div className="card-body">
                    <div className="col-md-8 mt-5">
                        <table className="table table-sm table-hover table-bordered">
                            <tbody>
                                <tr>
                                    <th>VMO No</th>
                                    <td>{details.vmoNo}</td>
                                </tr>
                                <tr>
                                    <th>VMO Date</th>
                                    <td>{details.vmoDate}</td>
                                </tr>
                                {details.business_unit && <tr>
                                    <th>Businessunit</th>
                                    <td>{details.business_unit.projectName}</td>
                                </tr>}
                                {details.receiving_businessunit &&
                                    <tr>
                                        <th>Receiving Businessunit</th>
                                        <td>{details.receiving_businessunit.projectName}</td>
                                    </tr>
                                }
                                <tr>
                                    <th>Status</th>
                                    <td>{details.status === "created" && <Badge bg="primary">Created</Badge>}
                                        {details.status === "approved" && <Badge bg="warning">Approved</Badge>}
                                        {details.status === "partially_released" && <Badge bg="info">Partially Released</Badge>}
                                        {details.status === "fully_released" && <Badge bg="success">Fully Released</Badge>}</td>
                                </tr>
                                <tr>
                                    <th>Remarks</th>
                                    <td>{details.remarks}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="row mt-5">
                        <div className="table-responsive" style={{ maxHeight: "400px", overflow: "auto" }}>
                            <table className="table table-striped table-bordered">
                                <thead>
                                    <tr>
                                        <th>SL</th>
                                        <th>Vehicle Number</th>
                                        <th>Receive Date</th>
                                        <th>Rate Policy</th>
                                        <th>Monthly Rent</th>
                                        <th>Remarks</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        details.vmo_details && details.vmo_details.map((item, index) =>
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td> {item.vehicle.vehicleNumber}</td>
                                                <td> {item.receiveDate}</td>
                                                <td> {item.ratePolicy === "dry" ? "Dry" : "Wet"}</td>
                                                <td> {item.monthlyRent}</td>
                                                <td> {item.remarks}</td>
                                                <td>{item.status === "created" && <span className="badge badge-light-primary"> Created</span>}
                                                    {item.status === "approved" && <span className="badge badge-light-success"> Approved</span>}
                                                    {item.status === "released" && <span className="badge badge-light-warning"> Created</span>}
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VmoDetails;