/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useContext } from "react";
import { ToastContainer, toast } from 'react-toastify';
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';
import 'react-toastify/dist/ReactToastify.css';
import { KTSVG } from '../../_metronic/helpers';
import Swal from 'sweetalert2';
import { useHistory } from "react-router-dom";
import { getRequestOptions } from "../components/GetToken";
import { GetPagePermission } from "../components/UserPagePermission";
// import Error500 from "../components/Error500";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import userPermissionContext from '../../context/userPermissionContext';

const customStylesSelect = {
	control: (provided) => ({
		...provided,
		height: 'calc(1.35em + 1.1rem + 2px)',
		minHeight: '35px'
	}),
	valueContainer: (provided) => ({
		...provided,
	})
};

const VehicleMultiValue = ({ children, ...props }) => (
	<components.MultiValue {...props}>
		<span>{props.data.vehicleNumber}</span>
	</components.MultiValue>
);

const UserSingleValue = ({ children, ...props }) => (
	<components.SingleValue {...props}>
		<span>{props.data.fullName}</span>
	</components.SingleValue>
)

function TransferVehicle() {
	const { jwt } = useContext(userPermissionContext);
	const initialState = () => ({
		vehicleInfo: [],
		transferProject: "",
		transferDate: "",
		transferUser: ""
	})
	const [transferVehicle, setTransferVehicle] = useState(initialState());
	const hasPermission = GetPagePermission("/vehicleTransfer");

	let history = useHistory();

	const searchVehicles = (inputValue, callback) => {

		if (!inputValue) {
			callback([]);

		}
		else {
			var url = process.env.REACT_APP_API_URL + "vehicleSearch?q=" + inputValue;

			setTimeout(() => {
				fetch(url,
					getRequestOptions())

					.then((resp) => {
						return resp.json()
					})
					.then((resp) => {
						const tempArray = resp.data.map(function (element) {
							element.label = element.vehicleNumber;
							element.value = element.vehicleInfoId;
							return element;
						});
						callback(tempArray);
					})
					.catch((error) => {
						console.log(error, "catch the hoop")
					});
			});
		}
	}
	const getAllProject = (inputValue, callback) => {

		if (!inputValue) {
			callback([]);

		}
		else {
			var url = process.env.REACT_APP_API_URL + "getBusinessUnits?q=" + inputValue;

			setTimeout(() => {
				fetch(url,
					getRequestOptions())

					.then((resp) => {
						return resp.json()
					})
					.then((resp) => {
						const tempArray = resp.data.map(function (element) {
							element.label = element.projectName;
							element.value = element.projectId;
							return element;
						});
						callback(tempArray);
					})
					.catch((error) => {
						console.log(error, "catch the hoop")
					});
			});
		}
	}

	const getUserForAssign = (inputValue, callback) => {

		if (!inputValue) {
			callback([]);

		}
		else {
			var url = process.env.REACT_APP_API_URL + "users/getUserForAssign?q=" + inputValue;

			setTimeout(() => {
				fetch(url,
					getRequestOptions())

					.then((resp) => {
						return resp.json()
					})
					.then((resp) => {
						const tempArray = resp.data.map(function (element) {
							element.label = `${element.fullName} (${element.designation})`;
							element.value = element.employeeId;
							return element;
						});
						callback(tempArray);
					})
					.catch((error) => {
						console.log(error, "catch the hoop")
					});
			});
		}
	}


	const handleTransferProjectChange = businessUnit => {
		setTransferVehicle({ ...transferVehicle, transferProject: businessUnit });
	}

	const handleVehicleChange = vehicleDetails => {
		setTransferVehicle({ ...transferVehicle, vehicleInfo: vehicleDetails });
	}

	const handleInputChange = evt => {
		if (evt.target.name === "transferDate") {
			const date = new Date().toISOString().slice(0, 10);
			if (date < evt.target.value) {
				toast.error("Transfer date cannot be greater than current date");
			}
			else {
				setTransferVehicle({ ...transferVehicle, [evt.target.name]: evt.target.value })
			}
		}
		else {
			setTransferVehicle({ ...transferVehicle, [evt.target.name]: evt.target.value })
		}
	}

	const handleTransferUserChange = user => {
		setTransferVehicle({ ...transferVehicle, transferUser: user });
	}

	const handleSubmit = evt => {
		evt.preventDefault();
		Swal.fire({
			title: 'Are you sure?',
			text: "You want to transfer this vehicles!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes',
			cancelButtonText: 'No'
		}).then((result) => {
			if (result.value) {
				const formData = {
					transferVehicle: transferVehicle
				};
				const requestOptions = {
					method: 'POST',
					headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt.token },
					body: JSON.stringify(formData)
				};
				var apiEnd = "vehicleTransfer";
				fetch(process.env.REACT_APP_API_URL + apiEnd, requestOptions)
					.then((resp) => {
						return resp.json()
					})
					.then((resp) => {
						if (resp.success === true) {
							Swal.fire({
								icon: 'success',
								title: 'Success',
								text: resp.successMessage,
							})
							setTransferVehicle(initialState());
						}
						else {
							Swal.fire({
								icon: 'error',
								title: 'Error',
								html: resp.errorMessage
							})
						}
					})
					.catch((error) => {
						console.log(error, "catch the hoop")
					});
			}
		})
	}

	return (
		<>
			{hasPermission === 1 &&
				<div className="card card-custom">
					<div className="card-header">
						<div className="card-title">
							<span>
								<KTSVG
									path='/media/svg/icons/Map/Direction2.svg'
									className='svg-icon-1 svg-icon-primary'
								/>
							</span>
							<span className="text fw-bolder" style={{ marginLeft: "10px" }}>
								TRANSFER VEHICLE
							</span>
						</div>
					</div>

					<div className="ml-12">
						<form onSubmit={handleSubmit}>
							<div className="card-body">
								<div className="form-group row">
									<label htmlFor="example-text-input" style={{ "textAlign": "right" }} className="col-lg-3 col-form-label">Search Vehicle<span className="required text-danger"></span></label>
									<div className="col-lg-5">
										<AsyncSelect
											value={transferVehicle.vehicleInfo}
											defaultOptions
											isMulti
											loadOptions={searchVehicles}
											onChange={handleVehicleChange}
											components={{ MultiValue: VehicleMultiValue }}
											getOptionLabel={(option) => (
												<>
													<KTSVG
														path='/media/icons/duotune/ecommerce/ecm006.svg'
														className='svg-icon-2 svg-icon-primary'
													/>
													<span> {option.label} </span><br></br>
													<KTSVG
														path='media/svg/icons/Map/Marker1.svg'
														className='svg-icon-2 svg-icon-warning'
													/>
													<span> {option.currentProjectName} </span><br></br>
													{option.currentUserName !== null &&
														<>
															<KTSVG
																path='/media/icons/duotune/communication/com014.svg'
																className='svg-icon-2 svg-icon-success'
															/>
															<span> {option.currentUserName} </span>
														</>
													}
												</>
											)}
										/>
									</div>
								</div>
								<div className="form-group row mt-2">
									<label htmlFor="example-text-input" style={{ "textAlign": "right" }} className="col-lg-3 col-form-label" >Transfer To Project<span className="required text-danger"></span></label>
									<div className="col-lg-5">
										<AsyncSelect
											value={transferVehicle.transferProject}
											defaultOptions
											loadOptions={getAllProject}
											placeholder="Select Project"
											onChange={handleTransferProjectChange}
											styles={customStylesSelect}
										/>
									</div>
								</div>
								<div className="form-group row">
									<label htmlFor="transferDate" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }} >Transfer Date <span className="required text-danger"></span></label>
									<div className="col-lg-5">
										<input type="date" name="transferDate" id="transferDate" value={transferVehicle.transferDate || ""} onChange={handleInputChange} className="form-control form-control-sm" />
									</div>
								</div>


								<div className="form-group row">
									<label htmlFor="example-text-input" style={{ "textAlign": "right" }} className="col-lg-3 col-form-label" >Transfer To User</label>
									<div className="col-lg-5">
										<AsyncSelect
											value={transferVehicle.transferUser}
											defaultOptions
											loadOptions={getUserForAssign}
											placeholder="Select User"
											onChange={handleTransferUserChange}
											components={{ SingleValue: UserSingleValue }}
											getOptionLabel={(option) => (
												<>
													<KTSVG
														path='/media/svg/icons/General/User.svg'
														className='svg-icon-2 svg-icon-primary'
													/>
													<span> {option.fullName} </span><br></br>
													<KTSVG
														path='/media/svg/icons/Design/Stamp.svg'
														className='svg-icon-2 svg-icon-warning'
													/>
													<span> {option.designation} </span><br></br>
													<KTSVG
														path='/media/icons/duotune/communication/com014.svg'
														className='svg-icon-2 svg-icon-success'
													/>
													<span> {option.employeeCustomId} </span>
												</>
											)}
										/>
									</div>
								</div>
								<div className="row mt-2">
									<div className="col-5">
									</div>
									<div className="col-6">
										<button type="submit" className="btn btn-sm btn-success me-2">Submit</button>
										<button type="reset" className="btn btn-sm btn-secondary" onClick={() => history.goBack()}>Cancel</button>
									</div>
								</div>
							</div>
							{/* <pre>   
                        {JSON.stringify(transferVehicle, null, 2)}
                    </pre> */}
						</form>
					</div>
					<ToastContainer />
				</div>
			}
			{hasPermission === 0 &&
				<div className='card card-custom' style={{ padding: "15px" }} >
					<SkeletonTheme>
						<p>
							<Skeleton
								containerClassName="avatar-skeleton"
								count={40} ></Skeleton>
						</p>
					</SkeletonTheme>
				</div>
			}

		</>
	)
}

export default TransferVehicle