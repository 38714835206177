/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import VehicleList from '../../components/Dashboard/VehicleList';
import VehicleLiveLocation from '../../components/Dashboard/VehicleLiveLocation';


const DashboardPage: FC = () => (
	<></>
)

const DashboardWrapper: FC = () => {

	const intl = useIntl()

	const [accordion, setAccordion] = useState('map');

	const handleAccordion = (ac: any) => {
		if (ac === accordion) {
			setAccordion('');
		}
		else {
			setAccordion(ac);
		}
	}
	return (
		<>
			<PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>

			{/* <div className='row'>
				<div className='col-md-6 sol-sm-12'>

				</div>
				<div className='col-md-6 sol-sm-12'>

				</div>
			</div> */}
			<div className='card'>
				<div className='rounded border p-1'>
					<div className='accordion' id='kt_accordion_1'>
						<div className='accordion-item'>
							<h2 className='accordion-header' id='kt_accordion_1_header_2' onClick={() => handleAccordion('map')}>
								<button
									className={`accordion-button fs-4 fw-bold ${accordion === 'map' ? '' : 'collapsed'}`}
									type='button'
									data-bs-toggle='collapse'
									data-bs-target='#kt_accordion_1_body_2'
									aria-expanded={accordion === 'map' ? 'true' : 'false'}
									aria-controls='kt_accordion_1_body_2'
								>
									Vehicle Live Location
								</button>
							</h2>
							<div
								id='kt_accordion_1_body_2'
								className={`accordion-collapse collapse ${accordion === 'map' ? 'show' : ''}`}
								aria-labelledby='kt_accordion_1_header_2'
								data-bs-parent='#kt_accordion_1'
							>
								<div className='accordion-body'>
									<div className='row'>
										<div className='col-md-1'></div>
										<div className='col-md-10'>
											<VehicleLiveLocation />
										</div>
										<div className='col-md-1'></div>
									</div>
								</div>
							</div>
						</div>
						<div className='accordion-item'>
							<h2 className='accordion-header' id='kt_accordion_1_header_1' onClick={() => handleAccordion('vehicleList')}>
								<button
									className={`accordion-button fs-4 fw-bold ${accordion === 'vehicleList' ? '' : 'collapsed'}`}
									type='button'
									data-bs-toggle='collapse'
									data-bs-target='#kt_accordion_1_body_1'
									aria-expanded={accordion === 'vehicleList' ? 'true' : 'false'}
									aria-controls='kt_accordion_1_body_1'
								>
									Vehicle List
								</button>
							</h2>
							<div
								id='kt_accordion_1_body_1'
								className={`accordion-collapse collapse ${accordion === 'vehicleList' ? 'show' : ''}`}
								aria-labelledby='kt_accordion_1_header_1'
								data-bs-parent='#kt_accordion_1'
							>
								<div className='accordion-body'>
									<VehicleList />

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<DashboardPage />
		</>
	)
}

export { DashboardWrapper }
