import { useContext } from 'react';
import BackendTable from 'react-backend-table';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { KTSVG } from '../../_metronic/helpers';
import { GetPagePermission } from "../components/UserPagePermission";
import userPermissionContext from '../../context/userPermissionContext';

function AdvanceBillList() {
	const { jwt } = useContext(userPermissionContext);
	const hasPermission = GetPagePermission("/expenses-advanceBillList");
	let columns = [
		{ title: 'Id', field: 'idVmsAdvanceBill', sortable: true, searchable: true, thStyle: { textAlign: "center" }, tdStyle: { textAlign: "center" } },
		{ title: 'Driver', field: 'driverName', sortable: true, searchable: true },
		{ title: 'Advance Bill No', field: 'advanceBillNo', sortable: true, searchable: true },
		{ title: 'Business Unit', field: 'projectName', sortable: true, searchable: true },
		{ title: 'Cash Ledger', field: 'ledgerName', sortable: true, searchable: true },
		{ title: 'Advance Amount', field: 'amount', searchable: true },
		{ title: 'Adjusted Amount', field: 'totalAdjustAmount', searchable: true },
		{ title: 'Unadjusted Amount', field: 'unAdjustedAmount', searchable: true },
		{ title: 'Date', field: 'date', searchable: true },
		{ title: 'Purpose', field: 'purpose', searchable: true },
		{ title: 'Bill Adjust', field: 'billAdjust', searchable: true },
		{ title: 'Created By', field: 'createdBy', sortable: true, searchable: true }
	]

	let options = {
		url: process.env.REACT_APP_API_URL + 'advanceBillList',
		authorization: 'Bearer ' + jwt.token,
		perPage: [10, 20, 50, 100, 'All'],
		orderBy: 'idVmsAdvanceBill',
		orderType: 'desc',
		columnSearch: true,
	}

	return (
		<>
			{hasPermission === 1 &&
				<div className='card card-custom'>
					<div className='card-header'>
						<div className='card-title'>
							<span>
								<KTSVG
									path='/media/svg/icons/Text/Bullet-list.svg'
									className='svg-icon-1 svg-icon-primary'
								/>
							</span>
							<span className='text fw-bolder' style={{ marginLeft: '10px' }}>
								ADVANCE BILL LIST
							</span>
						</div>
					</div>

					<div className='ml-12'>
						<BackendTable columns={columns} options={options} />
					</div>
				</div>
			}
			{hasPermission === 0 &&
				<div className='card card-custom' style={{ padding: "15px" }} >
					<SkeletonTheme>
						<p>
							<Skeleton
								containerClassName="avatar-skeleton"
								count={40} ></Skeleton>
						</p>
					</SkeletonTheme>
				</div>
			}
		</>
	)
}

export default AdvanceBillList