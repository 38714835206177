import { useState, useContext } from "react";
import { ToastContainer } from 'react-toastify';
import AsyncSelect from 'react-select/async';
import 'react-toastify/dist/ReactToastify.css';
import { KTSVG } from '../../_metronic/helpers';
import Swal from 'sweetalert2';
import { useHistory } from "react-router-dom";
import { getRequestOptions } from "../components/GetToken";
import { GetPagePermission } from "../components/UserPagePermission";
// import Error500 from "../components/Error500";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import ReactLoading from 'react-loading';
import userPermissionContext from '../../context/userPermissionContext';

function AdvanceBillCreate() {
	const { jwt } = useContext(userPermissionContext);
	const customStylesSelect = {
		menu: (provided) => ({
			...provided,
			whiteSpace: 'nowrap',
			width: 'max-content'
		}),
	};

	const initialState = () => ({
		advanceTo: "",
		amount: "",
		date: "",
		cashLedger: "",
		businessUnit: "",
		purpose: ""

	})

	const [advanceBillInfo, setAdvanceBillInfo] = useState(initialState());
	const hasPermission = GetPagePermission("/expenses-advanceBillCreate");
	const [isLoading, setIsLoading] = useState(false);

	const getAllDriver = (inputValue, callback) => {

		if (!inputValue) {
			callback([]);

		}
		else {
			var url = process.env.REACT_APP_API_URL + "getAllDriver?q=" + inputValue;

			setTimeout(() => {
				fetch(url,
					getRequestOptions())

					.then((resp) => {
						return resp.json()
					})
					.then((resp) => {
						const tempArray = resp.data.map(function (element) {
							element.label = `${element.fullName} (${element.mobileNumber})`;
							element.value = element.idVmsDrivers;
							return element;
						})
						callback(tempArray);
					})
					.catch((error) => {
						console.log(error, "catch the hoop")
					});
			});
		}
	}

	const getAllProject = (inputValue, callback) => {

		if (!inputValue) {
			callback([]);

		}
		else {
			var url = process.env.REACT_APP_API_URL + "getBusinessUnits?q=" + inputValue;

			setTimeout(() => {
				fetch(url,
					getRequestOptions())

					.then((resp) => {
						return resp.json()
					})
					.then((resp) => {
						const tempArray = resp.data.map(function (element) {
							element.label = element.projectName;
							element.value = element.projectId;
							return element;
						});
						callback(tempArray);
					})
					.catch((error) => {
						console.log(error, "catch the hoop")
					});
			});
		}
	}


	const getCashLedger = (inputValue, callback) => {

		if (!inputValue) {
			callback([]);

		}
		else {
			var url = process.env.REACT_APP_API_URL + "getCashLedger/" + advanceBillInfo.businessUnit.projectId + "?q=" + inputValue;

			setTimeout(() => {
				fetch(url,
					getRequestOptions())

					.then((resp) => {
						return resp.json()
					})
					.then((resp) => {
						const tempArray = resp.data.map(function (element) {
							element.label = element.ledgerName;
							element.value = element.ledgerId;
							return element;
						});
						callback(tempArray);
					})
					.catch((error) => {
						console.log(error, "catch the hoop")
					});
			});
		}
	}

	const handleAdvanceToChange = driver => {
		setAdvanceBillInfo({ ...advanceBillInfo, advanceTo: driver });
	}

	const handleBuChange = businessUnit => {
		setAdvanceBillInfo({ ...advanceBillInfo, businessUnit: businessUnit, cashLedger: "" });
	}

	const handleCashLedgerChange = ledger => {
		setAdvanceBillInfo({ ...advanceBillInfo, cashLedger: ledger });
	}

	const handleInputChange = (event) => {
		setAdvanceBillInfo({ ...advanceBillInfo, [event.target.name]: event.target.value });
	}

	let history = useHistory();
	const handleSubmit = evt => {
		evt.preventDefault();
		Swal.fire({
			title: 'Are you sure?',
			text: "You want to create this advance bill!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes',
			cancelButtonText: 'No'
		}).then((result) => {
			if (result.value) {
				const formData = { ...advanceBillInfo };
				const requestOptions = {
					method: 'POST',
					headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt.token },
					body: JSON.stringify(formData)
				};
				var apiEnd = "advanceBillCreate";
				setIsLoading(true);
				fetch(process.env.REACT_APP_API_URL + apiEnd, requestOptions)
					.then((resp) => {
						return resp.json()
					})
					.then((resp) => {
						if (resp.success === true) {
							Swal.fire({
								icon: 'success',
								title: 'Success',
								text: resp.successMessage,
							})
							setAdvanceBillInfo(initialState());
							setIsLoading(false);
							history.push("/expenses-advanceBillList");
						}
						else {
							Swal.fire({
								icon: 'error',
								title: 'Error',
								html: resp.errorMessage
							})
							setIsLoading(false);
						}
					})
					.catch((error) => {
						console.log(error, "catch the hoop")
						setIsLoading(false);
					});
			}
		})
	}

	return (
		<>
			{hasPermission === 1 &&

				<div className="card card-custom">
					<div className="card-header">
						<div className="card-title">
							<span>
								<KTSVG
									path='/media/svg/icons/Files/Compiled-File.svg'
									className='svg-icon-1 svg-icon-primary'
								/>
							</span>
							<span className="text fw-bolder" style={{ marginLeft: "10px" }}>ADVANCE BILL</span>
						</div>
					</div>

					<div className="ml-12">
						<form onSubmit={handleSubmit}>
							<div className="card-body">

								<div className="form-group row">
									<label htmlFor="example-text-input" style={{ "textAlign": "right" }} className="col-lg-3 col-form-label">Advance To <span className="required text-danger"></span></label>
									<div className="col-lg-5">
										<AsyncSelect
											value={advanceBillInfo.advanceTo}
											defaultOptions
											loadOptions={getAllDriver}
											placeholder="Select Driver"
											onChange={handleAdvanceToChange}
										/>
									</div>
								</div>

								<div className="form-group row">
									<label htmlFor="amount" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}> Amount <span className="required text-danger"></span></label>
									<div className="col-lg-5">
										<input type="number" name="amount" id="amount" value={advanceBillInfo.amount} className="form-control form-control-sm" onChange={handleInputChange} />
									</div>
								</div>

								<div className="form-group row">
									<label htmlFor="example-text-input" style={{ "textAlign": "right" }} className="col-lg-3 col-form-label">Business Unit<span className="required text-danger"></span></label>
									<div className="col-lg-5">
										<AsyncSelect
											value={advanceBillInfo.businessUnit}
											loadOptions={getAllProject}
											placeholder="Select Project"
											onChange={handleBuChange}
											styles={customStylesSelect}
										/>
									</div>
								</div>

								<div className="form-group row">
									<label htmlFor="example-text-input" style={{ "textAlign": "right" }} className="col-lg-3 col-form-label">Cash Ledger <span className="required text-danger"></span></label>
									<div className="col-lg-5">
										<AsyncSelect
											value={advanceBillInfo.cashLedger}
											defaultOptions
											loadOptions={getCashLedger}
											onChange={handleCashLedgerChange}
											styles={customStylesSelect}
											isDisabled={advanceBillInfo.businessUnit === ""}
											getOptionLabel={(option) => (
												<>
													<KTSVG
														path='/media/svg/icons/Files/File-cloud.svg'
														className='svg-icon-2 svg-icon-primary'
													/>
													<span> {option.label} </span><br></br>
													<KTSVG
														path='media/svg/icons/Map/Marker1.svg'
														className='svg-icon-2 svg-icon-warning'
													/>
													<span> {option.ledgerPath} </span>
												</>
											)}
										/>
									</div>
								</div>

								<div className="form-group row mt-5">
									<label htmlFor="date" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }} > Date <span className="required text-danger"></span></label>
									<div className="col-lg-5">
										<input type="date" name="date" id="date" value={advanceBillInfo.date} onChange={handleInputChange} className="form-control form-control-sm" />
									</div>
								</div>
								<div className="form-group row">
									<label htmlFor="purpose" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Purpose <span className="required text-danger"></span></label>
									<div className="col-lg-5">
										<textarea name="purpose" id="purpose" value={advanceBillInfo.purpose} onChange={handleInputChange} className="form-control form-control-sm" />
									</div>
								</div>

								<div className="row mt-5">
									<div className="col-5">
									</div>
									<div className="col-6">
										<button type="submit" disabled={isLoading === true} className="btn btn-sm btn-success me-2">Submit</button>
										<button type="reset" className="btn btn-sm btn-secondary" onClick={() => history.goBack()}>Cancel</button>
										{isLoading === true &&
											<ReactLoading type={"bars"} color={"#4739ea3d"} height={'20%'} width={'20%'} />
										}
									</div>
								</div>
							</div>
							{/* <pre>{JSON.stringify(advanceBillInfo, null, 2)}</pre> */}
						</form>
					</div>
					<ToastContainer />
				</div>
			}
			{hasPermission === 0 &&
				<div className='card card-custom' style={{ padding: "15px" }} >
					<SkeletonTheme>
						<p>
							<Skeleton
								containerClassName="avatar-skeleton"
								count={40} ></Skeleton>
						</p>
					</SkeletonTheme>
				</div>
			}
		</>

	)
}

export default AdvanceBillCreate
