/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { KTSVG } from '../../_metronic/helpers';
import TaxTokenList from '../components/VehicleTaxTokenList';
import RoutePermitList from '../components/VehicleRoutePermitList';
import FitnessList from '../components/VehicleFitnessList';
import InsuranceList from '../components/VehicleInsuranceList';

function ToDoList() {
    const [vehicleType, setVehicleType] = useState('light');
    const [expirationType, setExpirationType] = useState('taxToken');
    const [range, setRange] = useState('');
    const expirationTypeChange = (type) => {
        setExpirationType(type);
    }
    const rangeChange = (range) => {
        setRange(range);
    }

    const typeList = [
        { value: '', label: 'All' },
        { value: 'light', label: 'Light Vehicle' },
        { value: 'motor-cycle', label: 'Motor Cycle' },
    ];

    const handleInputOnChange = (e) => {
        setVehicleType(e.target.value);
        setExpirationType('taxToken');
    }

    return (

        <div className='card card-custom'>
            <div className='card-header'>
                <div className='card-title'>
                    <span>
                        <KTSVG
                            path='/media/svg/icons/Files/Protected-file.svg'
                            className='svg-icon-1 svg-icon-primary'
                        />
                    </span>
                    <span className='text fw-bolder' style={{ marginLeft: '10px' }}>
                        UPCOMING EXPIRATION
                    </span>
                </div>
            </div>
            <div className='row mt-5'>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <label htmlFor="vehicleType" className="col-form-label col-lg-1" >Vehicle Type <span className="required text-danger"></span></label>
                    <div className="col-lg-2">
                        <select className="form-select form-select-sm" id="vehicleType" name="vehicleType" value={vehicleType} onChange={handleInputOnChange} >
                            {typeList.map(function (item, id) {
                                return <option key={id} value={item.value}>{item.label}</option>
                            })
                            }
                        </select>
                    </div>
                </div>
            </div>

            <div className='mt-4'>
                <div className="row mb-2" data-kt-buttons="true" style={{ marginLeft: "2px", marginRight: "2px" }}>
                    <div className="col" onClick={() => expirationTypeChange('taxToken')}>
                        <input name="taxToken" type="radio" className="btn-check" id="kt_account_team_size_select_1" value="Tax Token" checked={expirationType === 'taxToken'} readOnly />
                        <label className="btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4" >
                            <span className="fw-bolder fs-3">Tax Token</span>
                        </label>
                    </div>
                    {vehicleType !== 'motor-cycle' &&
                        <div className="col" onClick={() => expirationTypeChange('fitness')}>
                            <input name="fitness" type="radio" className="btn-check" id="kt_account_team_size_select_2" value="Fitness" checked={expirationType === 'fitness'} readOnly />
                            <label className="btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4" >
                                <span className="fw-bolder fs-3">Fitness</span>
                            </label>
                        </div>
                    }
                    <div className="col" onClick={() => expirationTypeChange('routePermit')}>
                        <input name="routePermit" type="radio" className="btn-check" id="kt_account_team_size_select_3" value="Route Permit" checked={expirationType === 'routePermit'} readOnly />
                        <label className="btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4">
                            <span className="fw-bolder fs-3">Route Permit</span>
                        </label>
                    </div>
                    <div className="col" onClick={() => expirationTypeChange('insurance')}>
                        <input name="insurance" type="radio" className="btn-check" id="kt_account_team_size_select_4" value="Insurance" checked={expirationType === 'insurance'} readOnly />
                        <label className="btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4" >
                            <span className="fw-bolder fs-3">Insurance</span>
                        </label>
                    </div>
                </div>
                <div className='border border-gray-300 border-dashed rounded' style={{ marginLeft: "20px", marginRight: "20px" }}>
                    <ul className="nav d-flex justify-content-between mx-9" role="tablist">
                        <li className="nav-item" role="presentation">
                            <a className={`nav-link btn btn-flex flex-center btn-active-danger btn-color-gray-600 btn-active-color-white rounded-2 h-35px ${range === '' ? 'active' : ''}`} data-bs-toggle="tab" id="kt_charts_widget_35_tab_1" role="tab" tabIndex={-1} onClick={() => rangeChange('')}>All</a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a className={`nav-link btn btn-flex flex-center btn-active-danger btn-color-gray-600 btn-active-color-white rounded-2 h-35px ${range === '7' ? 'active' : ''}`} data-bs-toggle="tab" id="kt_charts_widget_35_tab_2" role="tab" tabIndex={-1} onClick={() => rangeChange('7')}>7 day</a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a className={`nav-link btn btn-flex flex-center btn-active-danger btn-color-gray-600 btn-active-color-white rounded-2 h-35px ${range === '30' ? 'active' : ''}`} data-bs-toggle="tab" id="kt_charts_widget_35_tab_3" role="tab" tabIndex={-1} onClick={() => rangeChange('30')}>1 month</a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a className={`nav-link btn btn-flex flex-center btn-active-danger btn-color-gray-600 btn-active-color-white rounded-2 h-35px ${range === '180' ? 'active' : ''}`} data-bs-toggle="tab" id="kt_charts_widget_35_tab_3" role="tab" tabIndex={-1} onClick={() => rangeChange('180')}>6 month</a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a className={`nav-link btn btn-flex flex-center btn-active-danger btn-color-gray-600 btn-active-color-white rounded-2 h-35px ${range === '365' ? 'active' : ''}`} data-bs-toggle="tab" id="kt_charts_widget_35_tab_3" role="tab" tabIndex={-1} onClick={() => rangeChange('365')}>1 year</a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a className={`nav-link btn btn-flex flex-center btn-active-danger btn-color-gray-600 btn-active-color-white rounded-2 h-35px ${range === 'expired' ? 'active' : ''}`} data-bs-toggle="tab" id="kt_charts_widget_35_tab_1" role="tab" tabIndex={-1} onClick={() => rangeChange('expired')}>Expired</a>
                        </li>
                    </ul>
                </div>
            </div>
            {expirationType === 'taxToken' && <TaxTokenList range={range} vehicleType={vehicleType} />}
            {expirationType === 'fitness' && <FitnessList range={range} vehicleType={vehicleType} />}
            {expirationType === 'routePermit' && <RoutePermitList range={range} vehicleType={vehicleType} />}
            {expirationType === 'insurance' && <InsuranceList range={range} vehicleType={vehicleType} />}
        </div>
    )
}

export default ToDoList;