import React, { useState, useEffect, useImperativeHandle } from "react";

const VehicleInsurance = React.forwardRef((props, ref) => {

    const initialState = () => ({
        insuranceType: "",
        insuranceCompany: "",
        insurancePolicyNumber: "",
        insuranceExpirationDate: "",
        yearlyPremium: "",
        source: ""
    })
    const [vehicleInsurance, setVehicleInsurance] = useState(initialState());

    useImperativeHandle(ref, () => ({
        setInitialState() {
            setVehicleInsurance(initialState());
        }
    }));

    useEffect(() => {
        if (props.vehicleInsuranceEditData) {
            setVehicleInsurance(props.vehicleInsuranceEditData)
        }
    }, [props.vehicleInsuranceEditData]);

    useEffect(() => {
        props.onVehicleInsuranceChange(vehicleInsurance);
    }, [props, vehicleInsurance]);

    const handleInputVehicleInsuranceChange = evt => {
        setVehicleInsurance({ ...vehicleInsurance, [evt.target.name]: evt.target.value });
    }

    const insuranceType = [
        { label: '1st Party', value: '1st Party' },
        { label: '3rd Party', value: '3rd Party' },
    ]

    return (

        <>
            <div className="form-group row">
                <label htmlFor="insuranceType" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }} >Insurance Type</label>
                <div className="col-lg-5">
                    <select className="form-select form-select-sm" id="insuranceType" name="insuranceType" value={vehicleInsurance.insuranceType} onChange={handleInputVehicleInsuranceChange} >
                        <option value="">Select Insurance Type</option>
                        {insuranceType.map(function (item, id) {
                            return <option key={id} value={item.value}>{item.label}</option>
                        })
                        }
                    </select>
                </div>
            </div>

            <div className="form-group row">
                <label htmlFor="insuranceCompany" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Insurance Company</label>
                <div className="col-lg-5">
                    <input type="text" name="insuranceCompany" id="insuranceCompany" value={vehicleInsurance.insuranceCompany} onChange={handleInputVehicleInsuranceChange} className="form-control form-control-sm" />
                </div>
            </div>

            <div className="form-group row">
                <label htmlFor="insurancePolicyNumber" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Insurance Policy Number</label>
                <div className="col-lg-5">
                    <input type="text" name="insurancePolicyNumber" id="insurancePolicyNumber" value={vehicleInsurance.insurancePolicyNumber} onChange={handleInputVehicleInsuranceChange} className="form-control form-control-sm" />
                </div>
            </div>

            <div className="form-group row">
                <label htmlFor="insuranceExpirationDate" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Insurance Expiration Date</label>
                <div className="col-lg-5">
                    <input type="date" name="insuranceExpirationDate" id="insuranceExpirationDate" value={vehicleInsurance.insuranceExpirationDate} onChange={handleInputVehicleInsuranceChange} className="form-control form-control-sm" />
                </div>
            </div>

            <div className="form-group row">
                <label htmlFor="yearlyPremium" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Yearly Premium</label>
                <div className="col-lg-5">
                    <input type="number" name="yearlyPremium" id="yearlyPremium" value={vehicleInsurance.yearlyPremium} onChange={handleInputVehicleInsuranceChange} className="form-control form-control-sm" />
                </div>
            </div>

            <div className="form-group row">
                <label htmlFor="source" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Source</label>
                <div className="col-lg-5">
                    <input type="text" name="source" id="source" value={vehicleInsurance.source} onChange={handleInputVehicleInsuranceChange} className="form-control form-control-sm" />
                </div>
            </div>
        </>
    )

})

export default React.memo(VehicleInsurance);