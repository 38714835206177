import React, { useState, useEffect, useImperativeHandle } from 'react';
import Radio from '@mui/material/Radio';

const VehicleDetails = React.forwardRef((props, ref) => {

    const initialState = () => ({
        noOfTyres: "",
        seatCapacity: "",
        spareTyres: "",
        fuelCapacity: "",
        cylinderCapacity: "",
        engineNumber: "",
        ladenWeight: "",
        engineCcDisplacement: ""
    })
    const [vehicleDetails, setVehicleDetails] = useState(initialState());

    useImperativeHandle(ref, () => ({
        setInitialState() {
            setVehicleDetails(initialState());
        }
    }));

    useEffect(() => {
        if (props.vehicleDetailsEditData) {
            setVehicleDetails(props.vehicleDetailsEditData)
        }
    }, [props.vehicleDetailsEditData]);

    useEffect(() => {
        props.onVehicleDetailsChange(vehicleDetails);
    }, [props, vehicleDetails]);

    const handleInputVehicleDetailsChange = evt => {
        setVehicleDetails({ ...vehicleDetails, [evt.target.name]: evt.target.value });
    }

    const handleInputSpareTyresChange = (value) => {
        // if (vehicleDetails.spareTyres === "yes") {
        //     var spareTyres = "no";
        // }
        // else {
        //     spareTyres = "yes";
        // }
        setVehicleDetails({ ...vehicleDetails, spareTyres: value });
    }
    const tyres = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42];
    const seatCapacity = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40];

    return (
        <>
            <div className="form-group row">
                <label htmlFor="noOfTyres" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }} >No of Tyres<span className="required text-danger"></span></label>
                <div className="col-lg-5">
                    <select className="form-select form-select-sm" id="noOfTyres" name="noOfTyres" value={vehicleDetails.noOfTyres} onChange={handleInputVehicleDetailsChange} >
                        <option value="">Select No of Tyres</option>
                        {tyres.map(function (item, id) {
                            return <option key={id} value={item}>{item}</option>
                        })
                        }
                    </select>
                </div>
            </div>

            <div className="form-group row">
                <label htmlFor="seatCapacity" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }} >Seat Capacity<span className="required text-danger"></span></label>
                <div className="col-lg-5">
                    <select className="form-select form-select-sm" id="seatCapacity" name="seatCapacity" value={vehicleDetails.seatCapacity} onChange={handleInputVehicleDetailsChange} >
                        <option value="">Select Seat Capacity</option>
                        {seatCapacity.map(function (item, id) {
                            return <option key={id} value={item}>{item}</option>
                        })
                        }
                    </select>
                </div>
            </div>

            <div className="form-group row">
                <label htmlFor="spareTyres" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }} >Spare Tyres</label>
                <div className="col-lg-5 ">
                    <Radio
                        checked={vehicleDetails.spareTyres === "yes"}
                        onClick={() => handleInputSpareTyresChange('yes')}
                        value="yes"
                        name="radio-buttons"
                    />
                    Yes
                    <Radio
                        checked={vehicleDetails.spareTyres === "no"}
                        onClick={() => handleInputSpareTyresChange('no')}
                        value="no"
                        name="radio-buttons"
                    />
                    No
                </div>
            </div>

            <div className="form-group row">
                <label htmlFor="fuelCapacity" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Fuel Capacity<span className="required text-danger"></span></label>
                <div className="col-lg-5">
                    <input type="number" name="fuelCapacity" id="fuelCapacity" value={vehicleDetails.fuelCapacity} onChange={handleInputVehicleDetailsChange} className="form-control form-control-sm" />
                </div>
            </div>

            <div className="form-group row">
                <label htmlFor="cylinderCapacity" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Cylinder Capacity<span className="required text-danger"></span></label>
                <div className="col-lg-5">
                    <input type="number" name="cylinderCapacity" id="cylinderCapacity" value={vehicleDetails.cylinderCapacity} onChange={handleInputVehicleDetailsChange} className="form-control form-control-sm" />
                </div>
            </div>

            <div className="form-group row">
                <label htmlFor="engineNumber" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Engine Number<span className="required text-danger"></span></label>
                <div className="col-lg-5">
                    <input type="text" name="engineNumber" id="engineNumber" value={vehicleDetails.engineNumber} onChange={handleInputVehicleDetailsChange} className="form-control form-control-sm" />
                </div>
            </div>

            <div className="form-group row">
                <label htmlFor="ladenWeight" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Laden Weight (kg)<span className="required text-danger"></span></label>
                <div className="col-lg-5">
                    <input type="number" name="ladenWeight" id="ladenWeight" value={vehicleDetails.ladenWeight} onChange={handleInputVehicleDetailsChange} className="form-control form-control-sm" />
                </div>
            </div>

            <div className="form-group row">
                <label htmlFor="engineCcDisplacement" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Engine (CC) Displacement<span className="required text-danger"></span></label>
                <div className="col-lg-5">
                    <input type="number" name="engineCcDisplacement" id="engineCcDisplacement" value={vehicleDetails.engineCcDisplacement} onChange={handleInputVehicleDetailsChange} className="form-control form-control-sm" />
                </div>
            </div>
        </>
    )
})

export default React.memo(VehicleDetails);