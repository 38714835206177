import React, { Suspense } from 'react'
import { HashRouter as BrowserRouter } from 'react-router-dom'
import { I18nProvider } from '../_metronic/i18n/i18nProvider'
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core'
import { Routes } from './routing/Routes'
import UserPagePermission from '../context/UserPagePermission'

type Props = {
	basename: string
}

const App: React.FC<Props> = ({ basename }) => {
	return (
		<Suspense fallback={<LayoutSplashScreen />}>
			<BrowserRouter basename={basename}>
				<I18nProvider>
					<UserPagePermission>
						<LayoutProvider>
							<Routes />
						</LayoutProvider>
					</UserPagePermission>
				</I18nProvider>
			</BrowserRouter>
		</Suspense>
	)
}

export { App }
