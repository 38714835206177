import { useContext } from 'react';
import BackendTable from 'react-backend-table';
// import { KTSVG } from '../../../_metronic/helpers';
import userPermissionContext from '../../../context/userPermissionContext';
import { Link } from "react-router-dom";

function VehicleList() {
    const { jwt } = useContext(userPermissionContext);
    const filehost = process.env.REACT_APP_AWS_URL + 'vms/uploads/';
    let columns = [
        { title: 'Id', field: 'idVmsVehicleInfo', sortable: true, searchable: true },
        {
            title: 'Thumbnail', field: 'thumbnailName', sortable: false, searchable: false,
            hasComponent: true,
            componentValue: ((rowValue) => {
                return (
                    <a href={`${filehost}${rowValue.idVmsVehicleInfo}/images/${rowValue.fileName}`} target="_blank" rel="noopener noreferrer">
                        {rowValue.thumbnailName !== null ?
                            <img src={`${filehost}${rowValue.idVmsVehicleInfo}/images/thumb/${rowValue.thumbnailName}`} style={{ width: "140px", height: "80px" }} alt="" />
                            : ""}
                    </a>
                )

            })
        },
        {
            title: 'Vehicle Number', field: 'vehicleNumber', sortable: true, searchable: true,
            hasComponent: true,
            componentValue: ((rowValue) => {
                return (
                    <Link to={`/vehicleDetails/${rowValue.idVmsVehicleInfo}`} target="_blank" rel="noopener noreferrer">
                        {rowValue.vehicleNumber}
                    </Link>
                )

            })
        },
        { title: 'Manufacturer', field: 'manufacturer', sortable: true, searchable: true },
        { title: 'Details', field: 'vehicleDetails', sortable: true, searchable: true },
        { title: 'Current Project', field: 'assignedProject', sortable: true, searchable: true },
        {
            title: 'Assigned Personnel', field: 'assignedUser', sortable: true, searchable: true,
            hasComponent: true,
            componentValue: ((rowValue) => {
                return (
                    <>
                        {rowValue.assignedUser !== null &&
                            <p>{rowValue.assignedUser} ({rowValue.assignedUserDesignation})</p>
                        }
                    </>
                )

            })
        },
        {
            title: 'Status',
            field: 'vehicleStatus',
            sortable: true,
            searchable: true,
            tdClass: "text-center",
            hasComponent: true,
            componentValue: ((rowValue) => {
                return (rowValue.vehicleStatus === "Active" ? <span className="badge badge-light-success fw-bolder">Active</span> : <span className="badge badge-light-danger fw-bolder">Inactive</span>)
            })
        }
        // {
        //     title: "Action",
        //     field: "action",
        //     thStyle: { textAlign: "center" },
        //     tdStyle: { whiteSpace: 'nowrap' },
        //     sortable: false,
        //     searchable: false,
        //     hasComponent: true,
        //     componentValue: ((rowValue) => {
        //         return (
        //             <>
        //                 <Link to={`/vehicleDetails/${rowValue.idVmsVehicleInfo}`}>
        //                     <button className="btn btn-light-primary btn-sm">
        //                         <KTSVG
        //                             path='/media/svg/icons/Text/Bullet-list.svg'
        //                             className='svg-icon-5 svg-icon'
        //                         />		Details
        //                     </button>
        //                 </Link>
        //             </>
        //         )
        //     })
        // }
    ]

    let options = {
        url: process.env.REACT_APP_API_URL + 'dashboard/vehicleList',
        authorization: 'Bearer ' + jwt.token,
        perPage: [10, 20, 50, 100, 'All'],
        orderBy: 'idVmsVehicleInfo',
        orderType: 'desc',
        columnSearch: true,
    }

    return (
        <BackendTable columns={columns} options={options} />
    )
}

export default VehicleList