import React, { useState, useEffect, useContext } from 'react';
import { getRequestOptions } from "../components/GetToken";
import Swal from 'sweetalert2';
import { KTSVG } from '../../_metronic/helpers';
import userPermissionContext from '../../context/userPermissionContext';

function PageAssign() {
    const { jwt } = useContext(userPermissionContext);
    const [idRoles, setIdRoles] = useState('');
    const [idPages, setIdPages] = useState('');
    const [roleOptions, setRoleOptions] = useState([]);
    const [pageList, setPageList] = useState([]);

    useEffect(() => {
        const getRoleList = () => {
            fetch(process.env.REACT_APP_API_URL + "getRoleList",
                getRequestOptions())
                .then(response => response.json())
                .then(resp => {
                    setRoleOptions(resp.data)
                })
                .catch(error => {
                    console.log(error);
                });
        };
        getRoleList();

    }, [])

    useEffect(() => {
        const getRolePages = (idRoles) => {
            fetch(process.env.REACT_APP_API_URL + "getRolePages/" + idRoles,
                getRequestOptions())
                .then(response => response.json())
                .then(resp => {
                    const menu = resp.data.map(function (row) {
                        return { ...row, totalSubMenu: row.subMenu.length }
                    });
                    setPageList(menu);
                })
                .catch(error => {
                    console.log(error);
                });
        };
        if (idRoles !== '') {
            getRolePages(idRoles);
        }
    }, [idRoles])

    useEffect(() => {
        if (idPages !== '') {
            assignRolePage();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idPages])

    const handleInputOnChange = (evt) => {
        setIdRoles(evt.target.value);
    }

    const onPermissionChange = (i, j, pages) => {
        const newItems = pageList.map(function (row, rindex) {
            if (i === rindex) {
                const subMenu = Object.values(row.subMenu);
                subMenu.map((sv, index) => {
                    if (j === index) {
                        if (sv.isPermission === 0) {
                            sv.isPermission = 1;
                        }
                        else {
                            sv.isPermission = 0;
                        }
                    }
                    return sv;
                })
            }
            return row;
        })
        setPageList(newItems);
        setIdPages(pages);
    }

    const assignRolePage = () => {
        const formData = {
            idRoles: idRoles,
            idPages: idPages
        };
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt.token },
            body: JSON.stringify(formData)
        };
        fetch(process.env.REACT_APP_API_URL + "setRolePageAssign", requestOptions)
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {

                if (resp.success === true) {
                    Swal.fire({
                        position: "top-right",
                        icon: "success",
                        title: "Permission Changed",
                        showConfirmButton: false,
                        timer: 1500
                    });
                    setIdPages('');
                }
                else {
                    var errorsMessage = [];

                    if (resp.errorMessage !== undefined && typeof resp.errorMessage === 'object') {
                        var errorsObj = resp.errorMessage;
                        Object.keys(errorsObj).forEach(function (value) {
                            errorsObj[value].forEach(function (v) {
                                errorsMessage.push(v)
                            });

                        });

                    } else if (resp.errorMessage !== undefined && (typeof resp.errorMessage === 'string' || resp.errorMessage instanceof String)) {
                        errorsMessage.push(resp.errorMessage);
                    } else {

                        errorsMessage.push("Something went wrong");
                    }
                    Swal.fire({
                        icon: 'error',
                        title: resp.heading,
                        text: errorsMessage,
                    })
                }


            })
            .catch((error) => {
                console.log(error, "catch the hoop")
            });
    }

    const tableBody = () => {
        const tbodies = pageList.map((value, index) => {
            const subMenuValues = Object.values(value.subMenu);
            const subMenuRows = subMenuValues.map((sv, i) => {
                const menuValue =
                    i === 0 ? <td style={{ "padding": "0.75rem", "textAlign": "center" }} rowSpan={subMenuValues.length}>{value.pagesTitle}</td> : null;

                return (
                    <tr key={i}>
                        {menuValue}
                        <td style={{ "padding": "0.75rem", "textAlign": "center" }}>{sv.pagesTitle}</td>
                        <td style={{ "padding": "0.75rem", "textAlign": "center" }} onClick={() => onPermissionChange(index, i, sv.idPages)}>
                            {sv.isPermission === 1 &&
                                <KTSVG
                                    path='/media/icons/duotune/arrows/arr012.svg'
                                    className='svg-icon-lg svg-icon-success'
                                />
                            }
                            {sv.isPermission === 0 &&
                                <KTSVG
                                    path='/media/icons/duotune/arrows/arr011.svg'
                                    className='svg-icon-lg svg-icon-danger'
                                />
                            }
                        </td>
                    </tr>
                );
            });
            return (
                <>
                    {subMenuRows}
                </>
            );
        });

        return tbodies;
    }

    return (
        <>
            <div className='card-body'>
                <div className="form-group row" style={{ margin: "0px" }}>
                    <select className="form-control form-control-sm" id="idRoles" name="idRoles" value={idRoles} onChange={handleInputOnChange}>
                        <option value="">Select A Role</option>
                        {roleOptions.map(item =>
                            <option key={item.idRoles} value={item.idRoles}>{item.roleName}</option>
                        )}
                    </select>
                </div>
                <div className="mt-5">
                    <table className="table table-striped table-bordered">
                        <thead>
                            <tr className="text-primary">
                                <th style={{ "textAlign": "center" }}>Menu</th>
                                <th style={{ "textAlign": "center" }}>Sub Menu</th>
                                <th style={{ "textAlign": "center" }}>Permission</th>
                            </tr>

                        </thead>
                        <tbody>
                            {tableBody()}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default PageAssign;