import React, { useState, useEffect, useContext } from 'react';
import BackendTable from 'react-backend-table';
import { Link } from "react-router-dom";
import { KTSVG } from '../../_metronic/helpers';
import { GetPagePermission } from "../components/UserPagePermission";
import Swal from 'sweetalert2';
import { getRequestOptions } from "../components/GetToken";
// import Error500 from '../components/Error500';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import userPermissionContext from '../../context/userPermissionContext';

function InvoiceBillList() {
	const { jwt } = useContext(userPermissionContext);
	const [reloadMyTable, setReloadMyTable] = useState(false);
	const hasPermission = GetPagePermission("/expenses-invoiceBillList");
	const [deletePermission, setDeletePermission] = useState("");
	let columns = [
		{ title: 'Id', field: 'idVmsExpenseInfo', sortable: true, searchable: true, thStyle: { textAlign: "center" }, tdStyle: { textAlign: "center" } },
		{
			title: 'Vehicle Number', field: 'vehicleNumber', sortable: true, searchable: true,
			hasComponent: true,
			componentValue: ((rowValue) => {
				return (
					<Link to={`/vehicleDetails/${rowValue.idVmsVehicleInfo}`} target="_blank" rel="noopener noreferrer">
						{rowValue.vehicleNumber}
					</Link>
				)

			})
		},
		{ title: 'Driver', field: 'driver', sortable: true, searchable: true },
		{ title: 'Project', field: 'projectName', searchable: true, sortable: true },
		{ title: 'Vendor', field: 'vendorName', searchable: true, sortable: true },
		{ title: 'Invoice No', field: 'invoiceNo', searchable: true, sortable: true },
		{ title: 'Comments', field: 'comments', sortable: true, searchable: true },
		{ title: 'Bill No', field: 'billNo', sortable: true, searchable: true },
		{ title: 'Bill Date', field: 'billDate', sortable: true, searchable: true },
		{ title: 'Bill Amount', field: 'amount', sortable: true, searchable: true },
		{ title: 'Paid Amount', field: 'totalPaidAmount', sortable: true, searchable: true },
		{ title: 'Remaining Amount', field: 'totalRemainingAmount', sortable: true, searchable: true },
		{ title: 'Approver', field: 'approverName', sortable: true, searchable: true },
		{ title: 'Ap', field: 'apName', sortable: true, searchable: true },
		{ title: 'Project Authority Date', field: 'projectAuthorityDate', sortable: true, searchable: true },
		{ title: 'Creator Name', field: 'createdBy', sortable: true, searchable: true },
		{ title: 'Module', field: 'moduleFrom', sortable: true, searchable: true },
		{
			title: "Action",
			field: "action",
			thStyle: { textAlign: "center" },
			tdStyle: { whiteSpace: 'nowrap' },
			sortable: false,
			searchable: false,
			hasComponent: true,
			componentValue: ((rowValue) => {
				return (
					<>
						<Link to={`/expenses-invoiceBillDetails/${rowValue.idVmsExpenseInfo}`}>
							<button className="btn btn-light-info btn-sm">
								<KTSVG
									path='/media/svg/icons/Text/Bullet-list.svg'
									className='svg-icon-5 svg-icon'
								/>		Details
							</button>
							<br />
						</Link>
						{/* && rowValue.totalPaidAmount <= 0 */}
						{(deletePermission !== "") &&
							<button className="btn btn-light-danger btn-sm" onClick={() => deletePage(rowValue.idVmsExpenseInfo)}>
								<KTSVG
									path='/media/svg/icons/General/Trash.svg'
									className='svg-icon-5 svg-icon'
								/>		Delete
							</button>
						}
					</>
				)
			})
		}
	]

	useEffect(() => {
		const regularExpensesDeletePermission = () => {
			fetch(process.env.REACT_APP_API_URL + "invoiceBillDeletePermission", getRequestOptions())
				.then((resp) => {
					return resp.json()
				})
				.then((resp) => {
					setDeletePermission(resp.data.idPages);
				})
				.catch((error) => {
					console.log(error, "catch the hoop")
				});

		}

		regularExpensesDeletePermission();

	}, [])

	const deletePage = (idVmsExpenseInfo) => {
		Swal.fire({
			title: 'Are You Sure?',
			text: "You Want To Delete This Invoice Bill Expense!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes',
			cancelButtonText: 'No'
		}).then((result) => {
			if (result.value) {
				const requestOptions = {
					method: 'DELETE',
					headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt.token },
				};
				var apiEnd = "invoiceBillExpenseDelete/" + idVmsExpenseInfo;
				fetch(process.env.REACT_APP_API_URL + apiEnd, requestOptions)
					.then((resp) => {
						return resp.json()
					})
					.then((resp) => {
						if (resp.success === true) {
							Swal.fire({
								icon: 'success',
								title: 'Success',
								text: "Invoice Bill Expense Deleted Successfully",
							})
							setReloadMyTable(true);
						}
						else {
							Swal.fire({
								icon: 'error',
								title: 'Error',
								html: resp.errorMessage
							})
						}
					})
					.catch((error) => {
						console.log(error, "catch the hoop")
					});
			}
		})
	}

	let options = {
		url: process.env.REACT_APP_API_URL + 'invoiceBillList',
		authorization: 'Bearer ' + jwt.token,
		perPage: [20, 100, 1000, 10000, 'All'],
		orderBy: 'idVmsExpenseInfo',
		orderType: 'desc',
		columnSearch: true,
	}

	useEffect(() => {
		if (reloadMyTable) {
			options.reloadMyTable();
			setReloadMyTable(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reloadMyTable])

	return (
		<>
			{hasPermission === 1 &&
				<div className='card card-custom'>
					<div className='card-header'>
						<div className='card-title'>
							<span>
								<KTSVG
									path='/media/svg/icons/Text/Bullet-list.svg'
									className='svg-icon-1 svg-icon-primary'
								/>
							</span>
							<span className='text fw-bolder' style={{ marginLeft: '10px' }}>
								INVOICE BILL LIST
							</span>
						</div>
					</div>

					<div className='ml-12'>
						<BackendTable columns={columns} options={options} />
					</div>
				</div>
			}
			{hasPermission === 0 &&
				<div className='card card-custom' style={{ padding: "15px" }} >
					<SkeletonTheme>
						<p>
							<Skeleton
								containerClassName="avatar-skeleton"
								count={40} ></Skeleton>
						</p>
					</SkeletonTheme>
				</div>
			}

		</>
	)
}

export default InvoiceBillList;