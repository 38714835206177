/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext, useRef } from "react";
import Select, { components } from 'react-select';
import 'react-toastify/dist/ReactToastify.css';
import { KTSVG } from '../../../_metronic/helpers';
import { getRequestOptions } from "../../components/GetToken";
import { GetPagePermission } from "../../components/UserPagePermission";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import userPermissionContext from '../../../context/userPermissionContext';
import BackendTable from 'react-backend-table';
import { Link } from "react-router-dom";

// const CustomValueContainer = ({ children, ...props }) => {
// 	const selectedCount = props.selectProps.value ? props.selectProps.value.length : 0;

// 	return (
// 		<components.ValueContainer {...props}>
// 			<span>{selectedCount} selected</span>
// 		</components.ValueContainer>
// 	);
// };

function VehicleExpenseReport() {
	const initialState = () => ({
		expenseTypesData: [],
		expenseDateFrom: "",
		expenseDateTo: "",
	});
	const { jwt } = useContext(userPermissionContext);
	const [reloadMyTable, setReloadMyTable] = useState(false);
	const [expenseTypeInfo, setExpenseTypeInfo] = useState(initialState());
	const [expenseTypes, setExpenseTypes] = useState([]);
	const hasPermission = GetPagePermission('/report-vehicleExpenseReport');
	const selectRef = useRef();

	let columns = [
		{
			title: 'Vehicle Number', field: 'vehicleNumber', sortable: true, searchable: true,
			hasComponent: true,
			componentValue: ((rowValue) => {
				return (
					<Link to={`/vehicleDetails/${rowValue.idVmsVehicleInfo}`} target="_blank" rel="noopener noreferrer">
						{rowValue.vehicleNumber}
					</Link>
				)

			})
		},
		{ title: 'Vehicle Type', field: 'vehicleType', sortable: true, searchable: true },
		{ title: 'Manufacturer', field: 'manufacturer', searchable: true, sortable: true },
		{ title: 'Details', field: 'vehicleDetails', searchable: true, sortable: true },
		{ title: 'Color', field: 'colorName', searchable: true, sortable: true },
		{ title: 'Project', field: 'assignedProject', searchable: true, sortable: true },
		{ title: 'Expense Name', field: 'expenseName', searchable: true, sortable: true },
		{ title: 'Total Amount', field: 'totalExpenseAmount', searchable: true, sortable: true }
	]

	let options = {
		url: process.env.REACT_APP_API_URL + 'generateExpenseDetailsReport',
		authorization: 'Bearer ' + jwt.token,
		perPage: [50, 100, 500, 1000, 'All'],
		orderBy: 'vehicleNumber',
		orderType: 'desc',
		columnSearch: true,
		extraData: expenseTypeInfo
	}

	useEffect(() => {
		if (reloadMyTable) {
			options.reloadMyTable();
			setReloadMyTable(false);
		}
	}, [reloadMyTable])

	useEffect(() => {
		const getExpenseTypes = () => {
			fetch(process.env.REACT_APP_API_URL + "getExpenseTypes", getRequestOptions())
				.then((resp) => {
					return resp.json()
				})
				.then((resp) => {
					resp.data.unshift({ value: "all", label: "All" }, { value: "deselect", label: "Deselect All" });
					setExpenseTypes(resp.data);
				})
				.catch((error) => {
					console.log(error, "catch the hoop")
				});
		}

		getExpenseTypes();
	}, [])

	const handleExpenseTypeChange = expenseType => {

		if (expenseType.some((option) => option.value === "all")) {
			setExpenseTypeInfo({
				...expenseTypeInfo,
				expenseTypesData: expenseTypes.filter(
					(option) => option.value !== "all" && option.value !== "deselect"
				),
			});
			selectRef.current.blur();
		}
		else if (expenseType.some((option) => option.value === "deselect")) {
			setExpenseTypeInfo({
				...expenseTypeInfo,
				expenseTypesData: [],
			});
			selectRef.current.blur();
		}
		else {
			setExpenseTypeInfo({
				...expenseTypeInfo,
				expenseTypesData: expenseType,
			});
		}
	}

	const handleDataChange = evt => {
		setExpenseTypeInfo({ ...expenseTypeInfo, [evt.target.name]: evt.target.value });
	}

	const InputOption = ({
		getStyles,
		Icon,
		isDisabled,
		isFocused,
		isSelected,
		children,
		innerProps,
		...rest
	}) => {

		const style = {
			alignItems: "center",
			backgroundColor: isFocused ? "#eee" : "transparent",
			color: "inherit",
			display: "flex ",
		};

		// prop assignment
		const props = {
			...innerProps,
			style
		};

		if (children === "All") {
			return (
				<components.Option
					{...rest}
					isDisabled={isDisabled}
					isFocused={isFocused}
					isSelected={isSelected}
					getStyles={getStyles}
					innerProps={props}
				>
					<input
						type="checkbox"
						className="form-check-input"
						checked={isSelected}
						readOnly
						style={{ marginRight: "5px" }}
					/>
					{children}
				</components.Option>
			);
		}

		else if (children === "Deselect All") {
			return (
				<components.Option
					{...rest}
					isDisabled={isDisabled}
					isFocused={isFocused}
					isSelected={isSelected}
					getStyles={getStyles}
					innerProps={props}
				>
					<input
						type="checkbox"
						className="form-check-input"
						checked={isSelected}
						readOnly
						style={{ marginRight: "5px" }}
					/>
					{children}
				</components.Option>
			);
		}
		return (
			<components.Option
				{...rest}
				isDisabled={isDisabled}
				isFocused={isFocused}
				isSelected={isSelected}
				getStyles={getStyles}
				innerProps={props}
			>
				<input
					type="checkbox"
					className="form-check-input"
					checked={isSelected}
					readOnly
					style={{ marginRight: "5px" }}
				/>
				{children}
			</components.Option>
		);
	};

	const handleReset = () => {
		setExpenseTypeInfo(initialState());
		setReloadMyTable(true);
	}

	return (
		<>
			{hasPermission === 1 &&
				<div className="card card-custom">
					<div className="card-header">
						<div className="card-title">
							<KTSVG
								path='/media/svg/icons/Shopping/Money.svg'
								className='svg-icon-1 svg-icon-primary'
							/>
							<span className="text fw-bolder" style={{ marginLeft: "10px" }}>
								EXPENSES INFORMATION
							</span>
						</div>
					</div>

					<div className="ml-12">
						<div className="card-body">
							<div className="ml-12">
								<div className="form-group row mt-5">
									<label htmlFor="expenseType" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }} >Expense Type<span className="required text-danger"></span></label>
									<div className="col-lg-4">
										<Select
											ref={selectRef}
											defaultValue={[]}
											closeMenuOnSelect={false}
											hideSelectedOptions={false}
											value={expenseTypeInfo.expenseTypesData}
											options={expenseTypes}
											isMulti
											onChange={handleExpenseTypeChange}
											components={{ Option: InputOption }}
										/>
									</div>
								</div>
								<div className="form-group row">
									<label htmlFor="expenseDateFrom" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }} >Expense From Date<span className="required text-danger"></span></label>
									<div className="col-lg-4">
										<input type="date" name="expenseDateFrom" id="expenseDateFrom" value={expenseTypeInfo.expenseDateFrom} onChange={handleDataChange} placeholder="Select Date From" className="form-control form-control-sm" />
									</div>
								</div>
								<div className="form-group row">
									<label htmlFor="expenseDateTo" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }} >Expense To Date<span className="required text-danger"></span></label>
									<div className="col-lg-4">
										<input type="date" name="expenseDateTo" id="expenseDateTo" value={expenseTypeInfo.expenseDateTo} onChange={handleDataChange} placeholder="Select Date To" className="form-control form-control-sm" />
									</div>
								</div>
							</div>
							<div className="row mt-2">
								<div className="col-5">
								</div>
								<div className="col-6">
									<button type="submit" className="btn btn-sm btn-success me-2" onClick={() => setReloadMyTable(true)}>Submit</button>
									<button type="reset" className="btn btn-sm btn-secondary" onClick={() => handleReset()}>Reset</button>
								</div>
							</div>
						</div>

						<div className='ml-12 mt-5'>
							<BackendTable columns={columns} options={options} />
						</div>
					</div>
				</div>
			}
			{
				hasPermission === 0 &&
				<div className='card card-custom' style={{ padding: "15px" }} >
					<SkeletonTheme>
						<p>
							<Skeleton
								containerClassName="avatar-skeleton"
								count={40} ></Skeleton>
						</p>
					</SkeletonTheme>
				</div>
			}

		</>
	)

}

export default VehicleExpenseReport