import React, { useEffect, useState, useContext } from 'react';
import { getRequestOptions } from "../components/GetToken";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { KTSVG } from "../../_metronic/helpers";
import Swal from 'sweetalert2';
import userPermissionContext from '../../context/userPermissionContext';

const RateChange = (props) => {

    const initialState = () => ({
        idVmsVehicleInfo: props.idVmsVehicleInfo,
        rate: '',
        vehicleNumber: ''
    })
    const [rateData, setRateData] = useState(initialState);
    const { jwt } = useContext(userPermissionContext);

    useEffect(() => {
        const getVehicleRate = () => {
            fetch(process.env.REACT_APP_API_URL + "vehicleRate/" + props.idVmsVehicleInfo, getRequestOptions())
                .then((resp) => {
                    return resp.json()
                })
                .then((resp) => {
                    setRateData(resp.data)
                })
                .catch((error) => {
                    console.log(error, "catch the hoop")
                });
        }
        getVehicleRate();
    }, [props.idVmsVehicleInfo])

    const handleInputOnChange = (e) => {
        setRateData({ ...rateData, [e.target.name]: e.target.value })
    }

    const handleSubmit = evt => {
        evt.preventDefault();
        Swal.fire({
            title: 'Are you sure?',
            text: 'You want to update this Rate!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {
                const formData = { ...rateData };
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt.token },
                    body: JSON.stringify(formData)
                };
                fetch(process.env.REACT_APP_API_URL + 'vehicleRateUpdate', requestOptions)
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((resp) => {
                        if (resp.success === true) {
                            Swal.fire({
                                icon: 'success',
                                title: 'Success',
                                text: resp.successMsg,
                            })
                            setRateData(initialState());
                            props.onRateConfirm();
                        }
                        else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Error',
                                html: resp.errorMessage
                            })
                        }
                    })
                    .catch((error) => {
                        console.log(error, "catch the hoop")
                    });

            }
        })
    };

    return (
        <>
            <div className="card card-custom">
                <div className="card-header">
                    <div className="card-title">
                        <KTSVG
                            path='/media/svg/icons/Communication/Clipboard-list.svg'
                            className='svg-icon-1 svg-icon-primary'
                        />
                        <span className="text fw-bolder" style={{ marginLeft: "10px" }}>
                            RATE UPDATE
                        </span>
                    </div>
                </div>
                <div className="ml-12 mt-2">
                    <form onSubmit={handleSubmit}>
                        <div className="form-group row">
                            <label htmlFor="vehicleNumber" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }}>Vehicle Number <span className="required text-danger"></span></label>
                            <div className="col-lg-5">
                                <input type="text" name="vehicleNumber" id="vehicleNumber" value={rateData.vehicleNumber} className="form-control form-control-sm" readOnly />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="rate" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }}>Rate <span className="required text-danger"></span></label>
                            <div className="col-lg-5">
                                <input type="number" name="rate" id="rate" value={rateData.rate} className="form-control form-control-sm" onChange={handleInputOnChange} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                            </div>
                            <div className="col-6">
                                <input type="submit" className="btn btn-sm btn-success me-2" value='Submit' />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <ToastContainer />
        </>
    );
}

export default RateChange;