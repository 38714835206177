/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useState, useEffect, useContext } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import AsyncSelect from 'react-select/async'
import 'react-toastify/dist/ReactToastify.css'
import Swal from 'sweetalert2'
import { getRequestOptions } from '../components/GetToken'
import { KTSVG } from '../../_metronic/helpers'
import { useHistory } from 'react-router-dom'
import { GetPagePermission } from "../components/UserPagePermission";
// import Error500 from "../components/Error500";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import userPermissionContext from '../../context/userPermissionContext';

function ExpenseInvoiceCreate() {
	const { jwt } = useContext(userPermissionContext);
	const customStylesSelect = {
		control: (provided) => ({
			...provided,
			height: 'calc(1.35em + 1.1rem + 2px)',
			minHeight: '35px',
		}),
		valueContainer: (provided) => ({
			...provided,
		}),
	}

	let history = useHistory()
	const hasPermission = GetPagePermission("/expenses-invoiceCreate");
	const receivingDate = new Date();
	const formatedReceivingDate =
		receivingDate.getFullYear() + '-' + (receivingDate.getMonth() + 1) + '-' + receivingDate.getDate()

	const initialState = () => ({
		vehicleInfo: '',
		vehicleInfoId: '',
		vendor: [],
		vendorId: '',
		vendorEmail: '',
		vendorContactNo: '',
		project: [],
		projectId: '',
		invoiceAmount: '',
		invoiceNo: '',
		invoiceDate: '',
		receivingDate: formatedReceivingDate,
		actualReceivingDate: '',
		remarks: '',
	})

	const [invoiceInfo, setInvoiceInfo] = useState(initialState())
	const handleVehicleChange = (vehicleDetails) => {
		setInvoiceInfo({
			...invoiceInfo,
			vehicleInfoId: vehicleDetails.vehicleInfoId,
			projectId: vehicleDetails.currentProject,
			vendorId: vehicleDetails.vendorId,
			vehicleInfo: { label: vehicleDetails.vehicleNumber, value: vehicleDetails.vehicleInfoId },
			project: vehicleDetails.currentProjectName,
			vendor: !!vehicleDetails.vendorName ? vehicleDetails.vendorName : '',
			vendorEmail: !!vehicleDetails.vendorName ? vehicleDetails.vendorEmail : '',
			vendorContactNo: !!vehicleDetails.vendorName ? vehicleDetails.vendorContactNo : '',
		})
	}

	const handleExpenseCommonInfoChange = (evt) => {
		if (evt.target.name === 'invoiceDate') {
			const date = new Date().toISOString().slice(0, 10)
			if (date < evt.target.value) {
				toast.error('Invoice date cannot be greater than current date')
			} else {
				setInvoiceInfo({ ...invoiceInfo, [evt.target.name]: evt.target.value })
			}
		} else {
			setInvoiceInfo({ ...invoiceInfo, [evt.target.name]: evt.target.value })
		}
	}

	const searchVehicles = (inputValue, callback) => {
		if (!inputValue) {
			callback([])
		} else {
			var url = process.env.REACT_APP_API_URL + 'vehicleSearchThirdParty?q=' + inputValue

			setTimeout(() => {
				fetch(url, getRequestOptions())
					.then((resp) => {
						return resp.json()
					})
					.then((resp) => {
						const tempArray = resp.data.map(function (element) {
							element.label = element.vehicleNumber
							element.value = element.vehicleInfoId
							return element
						})
						callback(tempArray)
					})
					.catch((error) => {
						console.log(error, 'catch the hoop')
					})
			})
		}
	}

	const handleSubmit = (evt) => {
		evt.preventDefault()
		Swal.fire({
			title: 'Are you sure?',
			text: 'You want to create this Invoice!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes',
			cancelButtonText: 'No',
		}).then((result) => {
			if (result.value) {
				const formData = { ...invoiceInfo }
				const requestOptions = {
					method: 'POST',
					headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + jwt.token },
					body: JSON.stringify(formData),
				}
				var apiEnd = 'invoiceCreate'
				fetch(process.env.REACT_APP_API_URL + apiEnd, requestOptions)
					.then((resp) => {
						return resp.json()
					})
					.then((resp) => {
						if (resp.success === true) {
							Swal.fire({
								icon: 'success',
								title: 'Success',
								text: resp.successMessage,
							})
							setInvoiceInfo(initialState())
							history.push('/expenses-invoiceList')
						} else {
							Swal.fire({
								icon: 'error',
								title: 'Error',
								html: resp.errorMessage,
							})
						}
					})
					.catch((error) => {
						console.log(error, 'catch the hoop')
					})
			}
		})
	}

	return (
		<>
			{hasPermission === 1 &&

				<div className='card card-custom'>
					<div className='card-header'>
						<div className='card-title'>
							<KTSVG
								path='/media/svg/icons/Communication/Clipboard-check.svg'
								className='svg-icon-1 svg-icon-primary'
							/>
							<span className='text fw-bolder' style={{ marginLeft: '10px' }}>
								CREATE INVOICE
							</span>
						</div>
					</div>
					<div className='ml-12'>
						<form onSubmit={handleSubmit}>
							<div className='card-body'>
								<div className='form-group row'>
									<label htmlFor='example-text-input' style={{ textAlign: 'right' }} className='col-lg-3 col-form-label'>
										Search Vehicle<span className='required text-danger'></span>
									</label>
									<div className='col-lg-5'>
										<AsyncSelect value={invoiceInfo.vehicleInfo} defaultOptions loadOptions={searchVehicles} placeholder='Select Vehicle' onChange={handleVehicleChange} styles={customStylesSelect} />
									</div>
								</div>
								<div className='form-group row'>
									<label htmlFor='project' className='col-lg-3 col-form-label' style={{ textAlign: 'right' }}>
										Project<span className='required text-danger'></span>
									</label>
									<div className='col-lg-5'>
										<input type='text' name='project' id='project' value={invoiceInfo.project} readOnly placeholder='Project' className='form-control form-control-sm' />
									</div>
								</div>
								<div className='form-group row'>
									<label htmlFor='vendor' className='col-lg-3 col-form-label' style={{ textAlign: 'right' }}>
										Vendor<span className='required text-danger'></span>
									</label>
									<div className='col-lg-5'>
										<input type='text' name='vendor' id='vendor' value={invoiceInfo.vendor} readOnly placeholder='Vendor' className='form-control form-control-sm' />
									</div>
								</div>
								<div className='form-group row'>
									<label htmlFor='vendorEmail' className='col-lg-3 col-form-label' style={{ textAlign: 'right' }}>
										Vendor Email
									</label>
									<div className='col-lg-5'>
										<input type='text' name='vendorEmail' id='vendorEmail' value={invoiceInfo.vendorEmail} readOnly placeholder='Vendor Email' className='form-control form-control-sm' />
									</div>
								</div>
								<div className='form-group row'>
									<label htmlFor='vendorContactNo' className='col-lg-3 col-form-label' style={{ textAlign: 'right' }}>
										Vendor Contact No
									</label>
									<div className='col-lg-5'>
										<input type='text' name='vendorContactNo' id='vendorContactNo' value={invoiceInfo.vendorContactNo} readOnly placeholder='Vendor Contact No' className='form-control form-control-sm' />
									</div>
								</div>
								<div className='form-group row'>
									<label htmlFor='invoiceNo' className='col-lg-3 col-form-label' style={{ textAlign: 'right' }}>
										Invoice No <span className='required text-danger'></span>
									</label>
									<div className='col-lg-5'>
										<input type='text' name='invoiceNo' id='invoiceNo' value={invoiceInfo.invoiceNo} onChange={handleExpenseCommonInfoChange} placeholder='Enter Invoice No' className='form-control form-control-sm' />
									</div>
								</div>
								<div className='form-group row'>
									<label htmlFor='invoiceAmount' className='col-lg-3 col-form-label' style={{ textAlign: 'right' }}>
										Invoice Amount <span className='required text-danger'></span>
									</label>
									<div className='col-lg-5'>
										<input type='text' name='invoiceAmount' id='invoiceAmount' value={invoiceInfo.invoiceAmount} onChange={handleExpenseCommonInfoChange} placeholder='Invoice Amount' className='form-control form-control-sm' />
									</div>
								</div>
								<div className='form-group row'>
									<label htmlFor='invoiceDate' className='col-lg-3 col-form-label' style={{ textAlign: 'right' }}>
										Invoice Date<span className='required text-danger'></span>
									</label>
									<div className='col-lg-5'>
										<input type='date' name='invoiceDate' id='invoiceDate' value={invoiceInfo.invoiceDate} onChange={handleExpenseCommonInfoChange} placeholder='Select Invoive Date' className='form-control form-control-sm' />
									</div>
								</div>
								<div className='form-group row'>
									<label htmlFor='receivingDate' className='col-lg-3 col-form-label' style={{ textAlign: 'right' }}>
										Receiving Date<span className='required text-danger'></span>
									</label>
									<div className='col-lg-5'>
										<input type='text' name='receivingDate' id='receivingDate' value={invoiceInfo.receivingDate} readOnly placeholder='Select Receiving Date' className='form-control form-control-sm' />
									</div>
								</div>
								<div className='form-group row'>
									<label htmlFor='actualReceivingDate' className='col-lg-3 col-form-label' style={{ textAlign: 'right' }}>
										Actual Receiving Date<span className='required text-danger'></span>
									</label>
									<div className='col-lg-5'>
										<input type='date' name='actualReceivingDate' id='actualReceivingDate' value={invoiceInfo.actualReceivingDate} onChange={handleExpenseCommonInfoChange} placeholder='Select Actual Receiving Date' className='form-control form-control-sm' />
									</div>
								</div>
								<div className='form-group row'>
									<label htmlFor='remarks' className='col-lg-3 col-form-label' style={{ textAlign: 'right' }}>
										Remarks
									</label>
									<div className='col-lg-5'>
										<textarea name='remarks' id='remarks' value={invoiceInfo.remarks} onChange={handleExpenseCommonInfoChange} placeholder='Enter Remarks' className='form-control form-control-sm' />
									</div>
								</div>
							</div>
							<div className='row'>
								<div className='col-5'></div>
								<div className='col-6'>
									<button type='submit' className='btn btn-success btn-sm mb-5'>
										Submit
									</button>
									<button type='reset' className='btn btn-danger btn-sm mb-5' onClick={() => history.goBack()}>
										Cancel
									</button>
								</div>
							</div>
						</form>
					</div>
					{/* <div className='row'>
				<div className='col-4'>
					<pre>{JSON.stringify(invoiceInfo, null, 2)}</pre>
				</div>
			</div> */}
					<ToastContainer />
				</div>
			}
			{hasPermission === 0 &&
				<div className='card card-custom' style={{ padding: "15px" }} >
					<SkeletonTheme>
						<p>
							<Skeleton
								containerClassName="avatar-skeleton"
								count={40} ></Skeleton>
						</p>
					</SkeletonTheme>
				</div>
			}
		</>
	)
}

export default ExpenseInvoiceCreate
